import React from 'react';
import { useSelector } from 'react-redux';
import { userBusinessAccessRightsCreatorSelector } from 'stores/redux/selectors/businessAccessRightsSelectors';
import { DefaultAlert } from 'baseline-ui/alert';
import { Box } from '@mui/material';
import { H6, Small } from 'baseline-ui/typography';
import AccessRightsUserCreatorContainer from './AccessRightsUserCreatorContainer';
import AccessRightsUserCreatorContentContainer from './AccessRightsUserCreatorContentContainer';
import AccessRightsLabelContainer from './AccessRightsLabelContainer';

const AccessRightsUserCreator = () => {
    const creatorUser = useSelector(userBusinessAccessRightsCreatorSelector);

    if (!creatorUser) {
        return null;
    }

    const { email } = creatorUser;

    return (
        <Box
            sx={{
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                mb: 2,
                pt: 2,
                pb: 2,
            }}
        >
            <AccessRightsUserCreatorContainer>
                <AccessRightsUserCreatorContentContainer>
                    <H6>
                        <AccessRightsLabelContainer>{email}</AccessRightsLabelContainer>
                    </H6>
                    <Small>Creator</Small>
                </AccessRightsUserCreatorContentContainer>
            </AccessRightsUserCreatorContainer>
        </Box>
    );
};

AccessRightsUserCreator.propTypes = {};

AccessRightsUserCreator.defaultProps = {};

export default AccessRightsUserCreator;
