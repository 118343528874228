import { postInquire } from 'api';

const { takeLatest } = require('redux-saga/effects');
const { POST_INQUIRE_START, POST_INQUIRE_PROCESS } = require('stores/redux/actions/InquireActions');
const { default: genericSagaHandler } = require('./CommonSaga');

function* postInquireSagas({ payload }) {
    yield genericSagaHandler(POST_INQUIRE_PROCESS, function* () {
        yield postInquire({
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.emailAddress,
            phone: payload.phoneNumber,
            type: payload.inquireTypeOptions,
            businessName: payload.businessName,
            inquireText: payload.inquire,
        });
    });
}

export default function* InquireSagas() {
    yield takeLatest(POST_INQUIRE_START, postInquireSagas);
}
