import { Chip, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import InterestDetailsLayout from 'screens/Interests/_Layout/InterestDetailsLayout';
import { PiBookOpenText } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

const BookDetails = () => {
    const {
        name,
        approval_status,
        description,
        contributor,
        publisher,
        author,
        isbn,
        isbn10,
        isbn13,
        amazon_product_url,
        published_date,
        book_cover,
    } = useSelector(businessFormSelector);

    const { id } = useParams();
    const tags = useSelector(businessCategoriesSelector);

    const detailItems = [
        {
            label: 'Publisher',
            value: publisher,
        },
        {
            label: 'Published Date',
            value: published_date ? new Date(published_date).toLocaleDateString() : 'N/A',
        },
        {
            label: 'ISBN',
            value: isbn || isbn13,
        },
        {
            label: 'ISBN-10',
            value: isbn10,
        },
    ];

    if (amazon_product_url) {
        detailItems.push({
            value: (
                <Link
                    href={amazon_product_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                >
                    <Chip label="View on Amazon" color="primary" clickable />
                </Link>
            ),
        });
    }

    return (
        <InterestDetailsLayout
            title={name}
            approvalStatus={approval_status}
            imageSrc={book_cover}
            subtitle={contributor || author}
            description={description}
            detailItems={detailItems}
            icon={PiBookOpenText}
            tags={tags}
            entityId={id}
        />
    );
};

export default BookDetails;
