import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { H2, H5 } from 'baseline-ui/typography';
import {
    FormInput,
    FieldContainer,
    FormMaskedInput,
    FieldsGrouped,
    FieldGroupedContainer,
} from 'baseline-ui/form';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import {
    AuthFormContainer,
    AuthFormSignError,
    AuthHeadingContainer,
    authPropTypes,
    AuthFootnote,
    AuthFieldHeaderContainer,
} from 'baseline-ui/auth';
import formReferAFriendSchema from 'schema/formReferAFriendSchema';
import { FLEX_DIRECTION, MOBILE_PHONE_NUMBER_MASK } from 'common/consts/consts';
import { isEmpty } from 'lodash';

const FormReferAFriend = ({
    onSubmit,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    footnote,
    appear,
    signUpValuesReferral,
}) => {
    const intl = useIntl();

    const submit = (values) => {
        onSubmit(values);
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        friendFirstName: '',
        friendLastName: '',
        friendPhoneNumber: '',
        friendEmailAddress: '',
    };

    if (
        !isEmpty(signUpValuesReferral?.firstName) &&
        !isEmpty(signUpValuesReferral?.lastName) &&
        !isEmpty(signUpValuesReferral?.phoneNumber)
    ) {
        const { firstName, lastName, phoneNumber } = signUpValuesReferral;
        initialValues.firstName = firstName;
        initialValues.lastName = lastName;
        initialValues.phoneNumber = phoneNumber;
    }

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="formReferAFriend.referAFriend.heading.title" />
            </H2>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formReferAFriendSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        submit(values);
                    }}
                >
                    {({ setFieldValue }) => {
                        return (
                            <Form noValidate>
                                <AuthFieldHeaderContainer>
                                    <H5>
                                        <FormattedMessage id="formReferAFriend.referAFriend.heading.aboutYou.subtitle" />
                                    </H5>
                                </AuthFieldHeaderContainer>
                                <FieldsGrouped direction_xs={FLEX_DIRECTION.ROW}>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formReferAFriend.form.firstName.label',
                                                })}
                                                name="firstName"
                                                id="firstName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formReferAFriend.form.lastName.label',
                                                })}
                                                name="lastName"
                                                id="lastName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>

                                <FieldContainer>
                                    <Field
                                        component={FormMaskedInput}
                                        label={intl.formatMessage({
                                            id: 'formReferAFriend.form.phoneNumber.label',
                                        })}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        disabled={isLoading}
                                        required
                                        mask={MOBILE_PHONE_NUMBER_MASK}
                                        onAccept={(value, mask) => {
                                            mask.updateValue();
                                            setFieldValue('phoneNumber', value);
                                        }}
                                    />
                                </FieldContainer>
                                <AuthFieldHeaderContainer>
                                    <H5>
                                        <FormattedMessage id="formReferAFriend.referAFriend.heading.aboutFriend.subtitle" />
                                    </H5>
                                </AuthFieldHeaderContainer>
                                <FieldsGrouped direction_xs={FLEX_DIRECTION.ROW}>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formReferAFriend.form.friendFirstName.label',
                                                })}
                                                name="friendFirstName"
                                                id="friendFirstName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formReferAFriend.form.friendLastName.label',
                                                })}
                                                name="friendLastName"
                                                id="friendLastName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldContainer>
                                    <Field
                                        component={FormMaskedInput}
                                        label={intl.formatMessage({
                                            id: 'formReferAFriend.form.friendPhoneNumber.label',
                                        })}
                                        name="friendPhoneNumber"
                                        id="friendPhoneNumber"
                                        disabled={isLoading}
                                        required
                                        mask={MOBILE_PHONE_NUMBER_MASK}
                                        onAccept={(value, mask) => {
                                            mask.updateValue();
                                            setFieldValue('friendPhoneNumber', value);
                                        }}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'formReferAFriend.form.friendEmailAddress.label',
                                        })}
                                        name="friendEmailAddress"
                                        id="friendEmailAddress"
                                        type="text"
                                        disabled={isLoading}
                                        required
                                    />
                                </FieldContainer>
                                <Button
                                    skin={buttonSkin}
                                    btnType={buttonBtnType}
                                    block
                                    type="submit"
                                    loading={isLoading}
                                    loadingMessage={intl.formatMessage({
                                        id: 'formReferAFriend.form.submit.loading.label',
                                    })}
                                >
                                    <FormattedMessage id="formReferAFriend.form.submit.label" />
                                </Button>
                                <AuthFormSignError errorMessage={errorMessage} />
                                <AuthFootnote footnote={footnote} />
                            </Form>
                        );
                    }}
                </Formik>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormReferAFriend.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    footnote: PropTypes.node,
    appear: PropTypes.bool,
    signUpValuesReferral: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string,
    }),
};

FormReferAFriend.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    footnote: undefined,
    appear: true,
    signUpValuesReferral: undefined,
};

export default FormReferAFriend;
