import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';

const UseAddProductStepper = (currentStep = -1) => {
    const intl = useIntl();
    const { hasUnsavedChanges } = useSelector(businessFormSelector);

    const stepConfig = [
        {
            id: "INFORMATION",
            labelId: 'addBusiness.modal.editProduct.section.productInformation.title',
            stepIndex: 0,
        },
        {
            id: "PHOTOS",
            labelId: 'addBusiness.modal.editProduct.section.photos.title',
            stepIndex: 1,
        },
        {
            id: "CATEGORIES",
            labelId: 'addBusiness.modal.editProduct.section.categories.title',
            stepIndex: 2,
        },
        {
            id: "SECTIONS",
            labelId: 'addBusiness.modal.editProduct.section.sections.title',
            stepIndex: 3,
        }
    ];

    const steps = stepConfig.map(({ id, labelId, isValid, stepIndex }) => ({
        id,
        label: intl.formatMessage({ id: labelId }),
        completed: isValid && (!hasUnsavedChanges || currentStep !== stepIndex),
    }));

    return { steps };
};

export default UseAddProductStepper;
