import * as yup from 'yup';
import { MOBILE_PHONE_NUMBER_MATCH_REGEX, fieldSchema } from 'baseline-ui/validation';
import { ENTITY_TYPE_PEOPLE } from 'common/consts/consts';

const addBusinessRequiredInformationSchema = (entityType) => {
    if (entityType === ENTITY_TYPE_PEOPLE) {
        return yup.object().shape(
            {
                ownerFirstName: fieldSchema.firstName.required(),
                ownerLastName: fieldSchema.lastName.required(),
                phone: fieldSchema.phoneNumber.required(),
                privatephone: yup
                    .string()
                    .emptyToNull()
                    .matches(MOBILE_PHONE_NUMBER_MATCH_REGEX, {
                        message: `${entityType}.form.validation.validation.privatephone.label`,
                        excludeEmptyString: true,
                    })
                    .required(),
                email: fieldSchema.email.required(),
                selectedCategories: yup.array().when('selectedCategories', {
                    is: (exists) => !!exists,
                    then: yup.array().min(1).required(),
                    otherwise: yup.array(),
                }),
            },
            [['selectedCategories', 'selectedCategories']],
        );
    }
    return yup.object().shape(
        {
            name: yup.string().required(),
            formattedAddress: yup.string().required(),
            isOnlineStore: yup.boolean(),
            ownerFirstName: fieldSchema.firstName.required(),
            ownerLastName: fieldSchema.lastName.required(),
            position: yup.string().nullable(),
            phone: fieldSchema.phoneNumber.required(),
            privatephone: yup
                .string()
                .emptyToNull()
                .matches(MOBILE_PHONE_NUMBER_MATCH_REGEX, {
                    message: `${entityType}.form.validation.validation.privatephone.label`,
                    excludeEmptyString: true,
                }),
            email: fieldSchema.email.required(),
            selectedCategories: yup.array().when('selectedCategories', {
                is: (exists) => !!exists,
                then: yup.array().min(1).required(),
                otherwise: yup.array(),
            }),
        },
        [['selectedCategories', 'selectedCategories']],
    );
};

export default addBusinessRequiredInformationSchema;
