import CommonWrapper from '../../CommonWrapper';
import { ContentRenderer } from 'baseline-ui/helpers';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';
import { LinkButton } from 'baseline-ui/button';
import { HOME_PATH } from 'common/consts/pathnames';
import aboutImage from 'assets/images/about_img.png';
import AboutImageWrapper from './AboutImageWrapper';
import Separator from 'components/Separator';
import ListWrapper from '../../../components/ListWrapper';
import TitleWrapper from './TitleWrapper';
import AboutContentWrapper from './AboutContentWrapper';
import AboutImage from './AboutImage';
import LaunchComponent from 'components/LaunchComponent';
import { Mobile, TabletDesktop } from 'baseline-ui/layout';
import FlexWrapper from 'components/FlexWrapper';
import bulletSquire from 'assets/images/bullet-square.svg';
import bulletRhombus from 'assets/images/bullet-rhombus.svg';
import Background from 'baseline-ui/background';
import { BACKGROUND_MODES } from 'common/consts/consts';
import AboutText from './AboutText';
import Label from 'components/Label';
import { H1 } from 'baseline-ui/typography';

const AboutScreen = () => {
    const intl = useIntl();

    return (
        <CommonWrapper>
            <FlexWrapper
                margin={{
                    top: 54,
                    bottom: 46,
                }}
                padding={{
                    top: 0,
                    bottom: 0,
                }}
            >
                <Mobile>
                    <FormattedMessage
                        id="auth.about.hero.title"
                        values={{
                            h1: (text) => <H1 textAlign="center">{text}</H1>,
                            purple: (text) => (
                                <Label
                                    skin="secondary"
                                    lineHeight={43}
                                    fontSize={36}
                                    fontWeight={800}
                                >
                                    {text}
                                </Label>
                            ),
                            orange: (text) => (
                                <Label
                                    skin="primary"
                                    lineHeight={43}
                                    fontSize={36}
                                    fontWeight={800}
                                >
                                    {text}
                                </Label>
                            ),
                        }}
                    />
                </Mobile>
                <TabletDesktop>
                    <FormattedMessage
                        id="auth.about.hero.title"
                        values={{
                            h1: (text) => <H1 textAlign="center">{text}</H1>,
                            purple: (text) => (
                                <Label
                                    skin="secondary"
                                    lineHeight={58}
                                    fontSize={48}
                                    fontWeight={800}
                                >
                                    {text}
                                </Label>
                            ),
                            orange: (text) => (
                                <Label
                                    skin="primary"
                                    lineHeight={58}
                                    fontSize={48}
                                    fontWeight={800}
                                >
                                    {text}
                                </Label>
                            ),
                        }}
                    />
                </TabletDesktop>
            </FlexWrapper>
            <AboutContentWrapper bgWhite>
                <AboutText />
                <AboutImageWrapper>
                    <AboutImage src={aboutImage} />
                </AboutImageWrapper>
            </AboutContentWrapper>
            <Separator
                borderWidth={2}
                margin={{
                    bottom: 25,
                }}
            />
            <Background mode={BACKGROUND_MODES.HEART_HALF_SIDES_SPACE_BETWEEN}>
                <FlexWrapper
                    padding={{
                        bottom: 0,
                        left: 15,
                        right: 15,
                        top: 0,
                    }}
                    margin={{
                        top: 65,
                        bottom: 50,
                    }}
                >
                    <Mobile>
                        <FormattedMessage
                            id="auth.about.intro.title"
                            values={{
                                wrapper: (text) => (
                                    <Label
                                        display="block"
                                        textAlign="center"
                                        skin="secondary"
                                        lineHeight={38}
                                        fontSize={32}
                                        fontWeight={800}
                                    >
                                        {text}
                                    </Label>
                                ),
                                orange: (text) => <Label skin="primary">{text}</Label>,
                            }}
                        />
                    </Mobile>
                    <TabletDesktop>
                        <FormattedMessage
                            id="auth.about.intro.title"
                            values={{
                                wrapper: (text) => (
                                    <Label
                                        display="block"
                                        textAlign="center"
                                        skin="secondary"
                                        lineHeight={58}
                                        fontSize={48}
                                        fontWeight={800}
                                    >
                                        {text}
                                    </Label>
                                ),
                                orange: (text) => <Label skin="primary">{text}</Label>,
                            }}
                        />
                    </TabletDesktop>
                </FlexWrapper>

                <FlexWrapper
                    maxWidth={860}
                    padding={{
                        bottom: 0,
                        left: 15,
                        right: 15,
                        top: 0,
                    }}
                >
                    <ListWrapper
                        bulletMd={bulletSquire}
                        bullet={bulletRhombus}
                        direction={'column'}
                        justifyContent="center"
                        gapBetweenLists={40}
                        gapBetweenListsMd={40}
                        liGap={40}
                    >
                        <ContentRenderer
                            textAlign="left"
                            color="dark"
                            source={intl.formatMessage({
                                id: 'auth.about.intro.list.first',
                            })}
                        />
                        <ContentRenderer
                            textAlign="left"
                            color="dark"
                            source={intl.formatMessage({
                                id: 'auth.about.intro.list.second',
                            })}
                        />
                    </ListWrapper>
                </FlexWrapper>

                <FlexWrapper
                    mdGap={0}
                    xsGap={0}
                    padding={{
                        bottom: 70,
                    }}
                >
                    <ContentRenderer
                        textAlign="center"
                        color="dark"
                        source={intl.formatMessage({
                            id: 'auth.about.intro.text',
                        })}
                    />
                    <ButtonWrapper marginY={25} width={250}>
                        <LinkButton size="lg" block to={HOME_PATH}>
                            <FormattedMessage id="auth.about.intro.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                    <ContentRenderer
                        textAlign="center"
                        color="dark"
                        source={intl.formatMessage({
                            id: 'auth.about.intro.note',
                        })}
                    />
                </FlexWrapper>
            </Background>

            <FlexWrapper
                margin={{
                    top: -60,
                    bottom: 70,
                }}
                padding={{
                    left: 20,
                    right: 20,
                    bottom: 0,
                }}
            >
                <TitleWrapper>
                    <ContentRenderer
                        textAlign="center"
                        color="secondary"
                        source={intl.formatMessage({
                            id: 'auth.about.ourMission.title',
                        })}
                    />
                </TitleWrapper>

                <ContentRenderer
                    textAlign="center"
                    color="dark"
                    source={intl.formatMessage({
                        id: 'auth.about.ourMission.content',
                    })}
                />
            </FlexWrapper>
            <Separator borderWidth={2} />
            <FlexWrapper maxWidth={1115}>
                <LaunchComponent color="light" borderRadius={24} marginBottom={40} />
            </FlexWrapper>
        </CommonWrapper>
    );
};

export default AboutScreen;
