import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from '@mui/material';
import { useCallback } from 'react';
import useDropdownState from './useDropdownState';
import useEntityFilters from './useEntityFilters';

const EntityTagFilters = ({ entityId, onFilterChange, preselectedTagIds = [] }) => {
    const {
        loading,
        error,
        filterHeaders,
        subFilters,
        selectedFilters,
        handleFilterChange,
        getSelectedFilterItems,
    } = useEntityFilters(entityId, preselectedTagIds, onFilterChange);

    const { open, handleClose, handleOpen } = useDropdownState();

    const handleDeleteChip = useCallback(
        (filterId) => {
            handleFilterChange(filterId, false);
        },
        [handleFilterChange],
    );

    if (loading) {
        return <div>Loading filters...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (filterHeaders.length === 0) {
        return <div>No filters available for this entity type.</div>;
    }

    const menuItems = [];
    filterHeaders.forEach((header, index) => {
        const headerFilters = subFilters[header._id] || [];

        if (headerFilters.length === 0) {
            return;
        }

        if (index > 0) {
            menuItems.push(<Divider key={`divider-${header._id}`} />);
        }

        menuItems.push(
            <Typography
                key={`header-${header._id}`}
                variant="subtitle2"
                sx={{
                    fontWeight: 600,
                    color: '#666',
                    padding: '8px 16px',
                    backgroundColor: '#f5f5f5',
                }}
            >
                {header.name}
            </Typography>,
        );

        headerFilters.forEach((filter) => {
            menuItems.push(
                <MenuItem
                    key={filter._id}
                    value={filter._id}
                    dense
                    onClick={() => {
                        const currentState = selectedFilters[filter._id]?.selected || false;
                        handleFilterChange(filter._id, !currentState);
                    }}
                >
                    <Checkbox
                        checked={selectedFilters[filter._id]?.selected || false}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleFilterChange(filter._id, e.target.checked);
                        }}
                    />
                    <ListItemText primary={filter.name} />
                </MenuItem>,
            );
        });
    });

    const selectedFilterItems = getSelectedFilterItems();

    return (
        <Box sx={{ marginBottom: '16px', width: 'auto', minWidth: '250px' }}>
            <FormControl sx={{ width: '100%' }}>
                <Select
                    multiple
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    input={<OutlinedInput label="Tags" />}
                    value={selectedFilterItems.map((filter) => filter.id)}
                    renderValue={() => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selectedFilterItems.map((filter) => (
                                <Chip
                                    key={filter.id}
                                    label={filter.name}
                                    size="small"
                                    onDelete={() => handleDeleteChip(filter.id)}
                                    onMouseDown={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </Box>
    );
};

export default EntityTagFilters;
