import { Typography, Box, IconButton } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

const MediaGalleryContent = ({ trailers }) => {
    if (!trailers || !trailers.length) return null;

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', mb: 2 }}>
                Trailers & Videos
            </Typography>
            <Box sx={{ width: '100%' }}>
                {trailers.map((trailer, index) => (
                    <Box
                        key={`trailer-compact-${index}`}
                        sx={{
                            mb: 3,
                            width: '100%',
                            borderRadius: 2,
                            overflow: 'hidden',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                cursor: 'pointer',
                            },
                            transition: 'box-shadow 0.3s ease',
                        }}
                        onClick={() => window.open(trailer.url, '_blank')}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 0,
                                paddingTop: '56.25%', // 16:9 aspect ratio
                                backgroundColor: '#000',
                                position: 'relative',
                            }}
                        >
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(255,255,255,0.2)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                    },
                                }}
                            >
                                <PlayArrow sx={{ fontSize: 40, color: 'white' }} />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default MediaGalleryContent; 