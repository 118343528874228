import { CREATE_FAVEPAGE, MY_FAVEPAGE_PATH } from 'common/consts/pathnames';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AuthRedirectRouteWrapper = ({ children }) => {
    const navigate = useNavigate();
    const { ownedBusinesses } = useSelector(({ BusinessesReducer }) => BusinessesReducer);
    const { _id: user, is_admin } = useSelector(
        ({ AuthenticationReducer }) => AuthenticationReducer,
    );
    useEffect(() => {
        if (user && navigate && ownedBusinesses !== undefined) {
            if (!isEmpty(ownedBusinesses) || is_admin) {
                navigate(MY_FAVEPAGE_PATH);
            } else {
                navigate(CREATE_FAVEPAGE);
            }
        }
    }, [user, navigate, ownedBusinesses]);

    return <>{children}</>;
};

export default AuthRedirectRouteWrapper;
