import React, { useState, createContext, useContext, useEffect } from "react";
import { SendLoginUsingCookie } from "api";

export const UserContext = createContext(null);
export const UserProvider = (props) => {
  const [user, setUser] = useState({ loading: true });
  useEffect(() => {
    if (document.cookie.indexOf("jwt_token=") !== -1) {
      SendLoginUsingCookie().then((res) => {
        if (res && !res.errorMessage) {
          setUser({ ...res.payload, loading: false });
        } else {
          setUser({ loading: false });
        }
      });
    } else {
      setUser({ loading: false });
    }
  }, []);
  return (
    <UserContext.Provider value={[user, setUser]}>
      {" "}
      {props.children}{" "}
    </UserContext.Provider>
  );
};
export const useUserStore = () => useContext(UserContext);
