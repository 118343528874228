import React, {
    Children,
    cloneElement,
    useState,
    Fragment,
    useEffect,
    useRef,
    createRef,
    useContext,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import { ThemeContext } from 'styled-components';
import { HorizontalScroll } from 'baseline-ui/horizontal-scroll';
import { TransitionItem, useMeasure } from 'baseline-ui/helpers';
import usePrevious from 'common/hooks/usePrevious';
import Wrapper from './container/Wrapper';
import TabsGroup from './tab/TabsGroup';
import TabsContainer from './tab/TabsContainer';
import Tab from './tab/Tab';
import tabsTheme from '../styles/tabsTheme';
import tabsPropTypes from '../prop-types/tabsPropTypes';
import WrapperInner from './container/WrapperInner';
import WrapperMeasure from './container/WrapperMeasure';

const Tabs = ({
    tabsJustified,
    tabsMinWidth,
    tabsMaxWidth,
    scrollBtnWidth,
    tabsPosition,
    showScrollbar,
    children,
    setActiveTabIndexCallback,
    initialActiveItemId,
    activeItemIdImperatively,
}) => {
    const [showTabs, setShowTabs] = useState(false);
    const theme = useContext(ThemeContext);
    const [activeItemId, setActiveItemId] = useState(initialActiveItemId || 0);
    const [isJustified, setIsJustified] = useState(false);
    const [forceScrollValue, setForceScrollValue] = useState(0);
    const [bind, bounds] = useMeasure();
    const [horizontalScrollContentWidth, setHorizontalScrollContentWidth] = useState();
    const previousChildrenCount = usePrevious(children.length);

    const itemTab = useRef([...Array(children.length).map(() => createRef())]);
    const container = useRef(null);
    const tabsContainer = useRef(null);

    const resizeContainer = () => {
        if (!container.current) {
            return setIsJustified(false);
        }
        const isSmaller =
            tabsMinWidth * children.length >
            container.current.getBoundingClientRect().width - scrollBtnWidth * 2;

        return setIsJustified(!isSmaller);
    };

    useEffect(() => {
        setActiveTabIndexCallback(activeItemId);
    }, [activeItemId]);

    useEffect(() => {
        if (activeItemIdImperatively) {
            setActiveItemId(activeItemIdImperatively);
        }
    }, [activeItemIdImperatively]);

    useEffect(() => {
        resizeContainer();
        window.addEventListener('resize', resizeContainer);
        return () => window.removeEventListener('resize', resizeContainer);
    });

    const toggleItem = (itemId, tabIndex) => {
        if (!isNil(itemId)) {
            setActiveItemId(itemId);
        }

        const containerCurrent = container.current.getBoundingClientRect();
        const tabsContainerCurrent = tabsContainer.current.getBoundingClientRect();
        const itemTabCurrent = itemTab.current[tabIndex].getBoundingClientRect();

        const scrolledLeft = Math.abs(
            tabsContainerCurrent.left - containerCurrent.left - scrollBtnWidth,
        );

        const currRightContainerWidth =
            containerCurrent.width + containerCurrent.left - scrollBtnWidth;

        const checkRightVisible = Math.abs(
            containerCurrent.right - itemTabCurrent.right - scrollBtnWidth,
        );

        const hiddenRightTabAmount = container.current.scrollLeft + checkRightVisible;

        const currLeftContainerWidth = containerCurrent.left + scrollBtnWidth;

        const checkLeftVisible = Math.abs(
            itemTabCurrent.right - containerCurrent.left - scrollBtnWidth,
        );

        const hiddenLeftTabAmount = itemTabCurrent.width - checkLeftVisible;

        if (itemTabCurrent.right > currRightContainerWidth) {
            setForceScrollValue(scrolledLeft + hiddenRightTabAmount);
        } else if (itemTabCurrent.left < currLeftContainerWidth) {
            setForceScrollValue(scrolledLeft - hiddenLeftTabAmount);
        } else {
            setForceScrollValue(scrolledLeft);
        }
    };

    /**
     * Force scroll to last item added
     */
    const forceScrollToLastItem = () => {
        const tabsContainerCurrent = tabsContainer.current.getBoundingClientRect();
        const lastItem = Children.map(children, (child, index) => {
            if (index === children.length - 1) {
                return child.props.itemId;
            }
            return null;
        });

        toggleItem(lastItem[0], children.length - 1);
        setForceScrollValue(tabsContainerCurrent.width);
    };

    useEffect(() => {
        if (Children.count(children) && previousChildrenCount < Children.count(children)) {
            setHorizontalScrollContentWidth(tabsContainer.current.getBoundingClientRect().width);
            forceScrollToLastItem();
            resizeContainer();
        }
    }, [children, previousChildrenCount, tabsContainer]);

    const scrollIconColor = get(
        theme,
        `tabs.scrollButton.color`,
        tabsTheme.tabs.scrollButton.color,
    );

    const scrollButtonColor = get(
        theme,
        `tabs.scrollButton.backgroundColor`,
        tabsTheme.tabs.scrollButton.backgroundColor,
    );

    const renderTabs = useMemo(
        () =>
            Children.map(children, (child, index) => {
                const currentId = child.props.itemId || index;
                const checkOpenByItemId =
                    activeItemIdImperatively === currentId || activeItemId === currentId;

                return (
                    <Tab
                        key={index}
                        title={child.props.title}
                        id={currentId}
                        tabIndex={index}
                        onClick={toggleItem}
                        isOpen={checkOpenByItemId}
                        itemId={child.props.itemId || index.toString()}
                        justified={tabsJustified}
                        minWidth={tabsMinWidth}
                        maxWidth={tabsMaxWidth}
                        ref={(el) => {
                            itemTab.current[index] = el;
                        }}
                    />
                );
            }),
        [children, activeItemId, itemTab, activeItemIdImperatively],
    );

    const renderTabsGroup = (
        <TabsGroup
            ref={container}
            justified={isJustified}
            scrollBtnWidth={scrollBtnWidth}
            tabsPosition={tabsPosition}
        >
            <HorizontalScroll
                scrollButton
                fade={false}
                scrollBtnWidth={scrollBtnWidth}
                forceScrollTo={forceScrollValue}
                scrollButtonColor={scrollButtonColor}
                scrollIconColor={scrollIconColor}
                showScrollbar={showScrollbar}
                currentContentWidth={horizontalScrollContentWidth}
            >
                <TabsContainer justified={isJustified} ref={tabsContainer} role="tablist">
                    {renderTabs}
                </TabsContainer>
            </HorizontalScroll>
        </TabsGroup>
    );

    const renderVerticalTabsGroup = (
        <TabsGroup
            ref={container}
            justified={isJustified}
            scrollBtnWidth={scrollBtnWidth}
            tabsPosition={tabsPosition}
        >
            <TabsContainer
                justified={isJustified}
                ref={tabsContainer}
                tabsPosition={tabsPosition}
                role="tablist"
            >
                {renderTabs}
            </TabsContainer>
        </TabsGroup>
    );

    const renderPanels = useMemo(
        () =>
            Children.map(children, (child, index) => {
                const currentId = child.props.itemId || index;
                const checkOpenByItemId =
                    activeItemIdImperatively === currentId || activeItemId === currentId;

                return (
                    <Fragment key={index}>
                        <>
                            {cloneElement(child, {
                                isOpen: checkOpenByItemId,
                                itemId: currentId.toString(),
                            })}
                        </>
                    </Fragment>
                );
            }),
        [children, activeItemId, activeItemIdImperatively],
    );

    const renderTabsPosition = useMemo(() => {
        if (tabsPosition === 'top') {
            return (
                <>
                    {renderTabsGroup} {renderPanels}
                </>
            );
        }

        if (tabsPosition === 'bottom') {
            return (
                <>
                    {renderPanels} {renderTabsGroup}
                </>
            );
        }

        if (tabsPosition === 'left') {
            return (
                <>
                    {renderVerticalTabsGroup} {renderPanels}
                </>
            );
        }
        return null;
    }, [tabsPosition, children, activeItemId, activeItemIdImperatively, isJustified]);

    useEffect(() => {
        if (bounds.width > 0) {
            setShowTabs(true);
        }
    }, [bounds]);

    return (
        <>
            {showTabs && (
                <TransitionItem inProp appear transitionTimeout={50}>
                    <Wrapper>
                        <WrapperInner style={{ width: bounds.width || 'auto' }}>
                            {renderTabsPosition}
                        </WrapperInner>
                    </Wrapper>
                </TransitionItem>
            )}
            {/*
             * WrapperMeasure
             * Workaround for tabs longer than the document page
             * calculate the available space, then render the tabs and set the correct width
             */}
            <WrapperMeasure {...bind} />
        </>
    );
};

Tabs.propTypes = {
    children: PropTypes.node.isRequired,
    tabsJustified: PropTypes.bool,
    tabsMinWidth: PropTypes.number,
    tabsMaxWidth: PropTypes.number,
    scrollBtnWidth: PropTypes.number,
    tabsPosition: tabsPropTypes.tabsPosition,
    showScrollbar: PropTypes.bool,
    setActiveTabIndexCallback: PropTypes.func,
    activeItemIdImperatively: PropTypes.string,
    initialActiveItemId: PropTypes.string,
};

Tabs.defaultProps = {
    tabsJustified: false,
    tabsMinWidth: 120,
    tabsMaxWidth: 260,
    scrollBtnWidth: 40,
    tabsPosition: 'top',
    showScrollbar: true,
    setActiveTabIndexCallback: () => {},
    activeItemIdImperatively: undefined,
    initialActiveItemId: undefined,
};

export default Tabs;
