// {
//     "referredByFirstName":"Jon",
//     "referredByLastName":"Busa",
//     "referredByPhone":"555-555-5555",
//     "firstName":"Larry",
//     "lastName":"Bird",
//     "email": "jon.busa+33ref2236@gmail.com",
//     "phone":"333-333-3333"

import { postReferFriend } from 'api';
import { takeLatest } from 'redux-saga/effects';
import {
    POST_REFER_FRIEND_PROCESS,
    POST_REFER_FRIEND_START,
} from 'stores/redux/actions/ReferAFriendActions';
import genericSagaHandler from './CommonSaga';

function* postReferFriendSaga({ payload }) {
    yield genericSagaHandler(POST_REFER_FRIEND_PROCESS, function* () {
        yield postReferFriend({
            referredByFirstName: payload.firstName,
            referredByLastName: payload.lastName,
            referredByPhone: payload.phoneNumber,
            firstName: payload.friendFirstName,
            lastName: payload.friendLastName,
            email: payload.friendEmailAddress,
            phone: payload.friendPhoneNumber,
        });
    });
}
export default function* ReferFriendSagas() {
    yield takeLatest(POST_REFER_FRIEND_START, postReferFriendSaga);
}
