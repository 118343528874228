import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { H3, P } from 'baseline-ui/typography';
import { isAdminSelector } from 'stores/redux/selectors/authenticationSelectors';
import { EntityLinkAdmin } from 'components/EntityLink/EntityLinkAdmin';
import { GET_ENTITY_LINKS_TO_ENTITY_CALL } from 'stores/redux/actions/EntityLinkActions';
import {
    ENTITY_TYPE_PEOPLE,
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_DESTINATION,
    ENTITY_LINK_TO_ENTITY_LIST_MODES,
    ENTITY_ALL_TYPES,
} from 'common/consts/consts';
import EntityLinkRequestReceivedList from './EntityLinkRequestReceivedList';
import EntityLinkRequestSentList from './EntityLinkRequestSentList';

const EntityLinksToEntity = ({ entityType, mode } = props) => {
    const entityTypeLowerCase = entityType.toLowerCase();
    const dispatch = useDispatch();
    const { _id: businessId } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer);
    const { people, businesses, destinations, allTypes } = useSelector(
        ({ EntityLinkReducer }) => EntityLinkReducer,
    );
    const isAdmin = useSelector(isAdminSelector);
    const items = useMemo(() => {
        switch (entityType.toUpperCase()) {
            case ENTITY_TYPE_PEOPLE:
                return people;
            case ENTITY_TYPE_BUSSINESS:
                return businesses;
            case ENTITY_TYPE_DESTINATION:
                return destinations;
            case ENTITY_ALL_TYPES:
                return allTypes;
            default:
                return [];
        }
    }, [people, businesses, destinations, allTypes]);


    useEffect(() => {
        if (!businessId || !entityType || !mode) {
            return;
        }

        if (mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS) {
            dispatch({
                type: GET_ENTITY_LINKS_TO_ENTITY_CALL,
                payload: {
                    entityIdRequested: businessId,
                    entityRequestingType: entityType,
                    page: 0,
                    maxCount: 100,
                },
            });
        }

        if (mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.SENT_REQUESTS) {
            dispatch({
                type: GET_ENTITY_LINKS_TO_ENTITY_CALL,
                payload: {
                    requestingEntityId: businessId,
                    entityRequestedType: entityType,
                    page: 0,
                    maxCount: 100,
                },
            });
        }
    }, [businessId, entityType, mode]);

    return (
        <>
            <H3 color="secondary">
                {mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS ? (
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.title`}
                    />
                ) : (
                    <></>
                )}
            </H3>
            <P>
                {mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS ? (
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.requests.subtitle`}
                    />
                ) : (
                    <></>
                )}
            </P>
            {isAdmin && mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS && (
                <EntityLinkAdmin entityType={entityType} />
            )}
            {mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS && (
                <EntityLinkRequestReceivedList
                    businessId={businessId}
                    items={items}
                    entityType={entityType}
                />
            )}

            {mode === ENTITY_LINK_TO_ENTITY_LIST_MODES.SENT_REQUESTS && (
                <EntityLinkRequestSentList items={items} entityType={entityType} />
            )}
        </>
    );
};

EntityLinksToEntity.propTypes = {
    mode: PropTypes.oneOf([
        ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS,
        ENTITY_LINK_TO_ENTITY_LIST_MODES.SENT_REQUESTS,
    ]).isRequired,
    entityType: PropTypes.oneOf([
        ENTITY_TYPE_PEOPLE,
        ENTITY_TYPE_BUSSINESS,
        ENTITY_TYPE_DESTINATION,
    ]).isRequired,
};

export default EntityLinksToEntity;
