import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SiteHeader from 'components/pageLayout/SiteHeader';
import { HeaderProvider } from 'baseline-ui/header';
import useScrollToTop from 'common/hooks/useScrollToTop';
import { useLocation } from 'react-router-dom';
import { LOGO_DARK, LOGO_ONLY_EMPTY} from 'assets/images';
import DefaultFooter from 'baseline-ui/footer/src/components/DefaultFooter';
import { useSearchParams } from 'react-router-dom';

const CommonWrapper = ({ children }) => {
    const { pathname } = useLocation();
    const [isSmallerScreen, setIsSmallerScreen] = useState(window.innerWidth <= 1182);
    const [params] = useSearchParams();
    const isEmbedded = params.get('embedded');

    useScrollToTop(pathname);

    useEffect(() => {
        const handleResize = () => setIsSmallerScreen(window.innerWidth <= 1182);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if(isEmbedded){
        return <>{children}</>
    }
    return (
        <HeaderProvider>
            <SiteHeader logoPath={isSmallerScreen ? LOGO_ONLY_EMPTY : LOGO_DARK} logoPathAlt={isSmallerScreen ? LOGO_ONLY_EMPTY : LOGO_DARK} logoWidth={isSmallerScreen ? 40 : 201}>
                {children}
            </SiteHeader>
            <DefaultFooter logoPath={LOGO_DARK} logoPathAlt={LOGO_DARK} logoWidth={179}/>
        </HeaderProvider>
    );
};

CommonWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CommonWrapper;
