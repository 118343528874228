import * as yup from 'yup';
import { validationMessages } from 'baseline-ui/validation';
import businessLinksUtils from 'common/utils/businessLinksUtils';

const formAddBusinessLinksSchema = (links, currentUrl = '') =>
    yup.object().shape({
        type: yup.string().emptyToNull().required(),
        url: yup
            .string()
            .transform(businessLinksUtils.normalizeToHttps) // Normalize the input value
            .url(validationMessages.url.matches)
            .test({
                name: 'alreadyExists',
                test: (value, context) => {
                    const { createError } = context;

                    // Normalize all URLs in the links array
                    const normalizedLinks = links.map((link) => ({
                        ...link,
                        url: businessLinksUtils.normalizeToHttps(link.url),
                    }));

                    // Check for duplicates in normalized links
                    const isDuplicate = normalizedLinks.some(
                        (link) =>
                            link.url === value &&
                            link.url !== businessLinksUtils.normalizeToHttps(currentUrl),
                    );

                    if (!isDuplicate) {
                        return true;
                    }

                    return createError({
                        message: 'error.addBusiness.page.sections.link.url.alreadyExists.label',
                    });
                },
            })
            .required(),
        caption: yup.string().emptyToNull().trim().required(),
    });

export default formAddBusinessLinksSchema;
