import {ContentRenderer, Image} from '../../baseline-ui/helpers';
import {H3, typographyPropTypes} from '../../baseline-ui/typography';
import {LOGO_ONLY_EMPTY} from 'assets/images';
import {FormattedMessage, useIntl} from 'react-intl';
import ButtonWrapper from '../../baseline-ui/button/src/components/ButtonWrapper';
import {LinkButton} from '../../baseline-ui/button';
import React from 'react';
import LaunchWrapper from './LaunchWrapper';
import PropTypes from 'prop-types';
import ExtraLargeH1 from '../../baseline-ui/typography/src/components/ExtraLargeH1';
import { UPDATES_PATH } from 'common/consts/pathnames';

const LaunchComponent = ({color, borderRadius, marginBottom, image, type, width }) => {
    const intl = useIntl();
    return(
        <LaunchWrapper borderRadius={borderRadius} marginBottom={marginBottom} image={image} width={width} type={type}>
            <ExtraLargeH1 colorValue='light' fontSize={42}
                margin={{
                    bottom: 12
                }}
            >
                <FormattedMessage id='auth.signUp.launch.title' />
            </ExtraLargeH1>

            <ContentRenderer
                textAlign="center"
                color={color}
                source={intl.formatMessage({
                    id: 'auth.signUp.launch.content',
                })}
            />
            <br/>
            <H3 color={color} fontWeight={700} textAlign="center">
                <Image src={LOGO_ONLY_EMPTY} width={32} height={32} imageFluid={false} />
                {' '}
                <FormattedMessage id='auth.signUp.launch.logo' />
            </H3>
            <ButtonWrapper width={230} marginY={20} marginX={1}>
                <LinkButton size="md" block to={UPDATES_PATH} >
                    <FormattedMessage id="auth.signUp.launch.button" />
                </LinkButton>
            </ButtonWrapper>
        </LaunchWrapper>
    );
}
LaunchComponent.propTypes = {
    color: typographyPropTypes.typographyColor,
    borderRadius: PropTypes.number,
    marginBottom: PropTypes.number
};

export default LaunchComponent;