import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormInput } from 'baseline-ui/form';
import { Field, useFormikContext } from 'formik';
import { GaIcon } from 'baseline-ui/icon';
import useGoogleMapsAutocompletePlaces from 'common/hooks/useGoogleMapsAutocompletePlaces';
import { H6 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { isEmpty } from 'lodash';
import AdminBusinessAdvertisingCityButtonContainer from './AdminBusinessAdvertisingCityButtonContainer';
import AdminBusinessAdvertisingCityListContainer from './AdminBusinessAdvertisingCityListContainer';

const AdminBusinessAdvertisingAddCityField = ({ arrayHelpers, index }) => {
    const ref = useRef();
    const renderSearchId = `categoriesAndFilters.${index}.categorySearchCity`;

    const { values, setFieldValue } = useFormikContext();

    useGoogleMapsAutocompletePlaces({
        ref,
        types: ['(cities)'],
        queryCallback: (city) => {
            arrayHelpers.push(city);
        },
    });

    return (
        <>
            <Field
                component={FormInput}
                label="City"
                name={renderSearchId}
                id={renderSearchId}
                type="text"
                innerRef={ref}
                placeholder=""
                required={false}
                prependAddon={<GaIcon icon="search" size={14} />}
                autoComplete="off"
                onBlur={() => {
                    setFieldValue(renderSearchId, '');
                }}
            />
            {!isEmpty(values.categoriesAndFilters[index].categoryCityList) && (
                <AdminBusinessAdvertisingCityListContainer>
                    {values.categoriesAndFilters[index].categoryCityList.map(
                        (selectedCity, selectedCityIndex) => (
                            <AdminBusinessAdvertisingCityButtonContainer key={selectedCity}>
                                <H6 color="primary">{selectedCity}</H6>
                                <Button
                                    icon="x"
                                    btnType="link"
                                    onClick={() => arrayHelpers.remove(selectedCityIndex)}
                                    size="sm"
                                />
                            </AdminBusinessAdvertisingCityButtonContainer>
                        ),
                    )}
                </AdminBusinessAdvertisingCityListContainer>
            )}
        </>
    );
};

AdminBusinessAdvertisingAddCityField.propTypes = {
    arrayHelpers: PropTypes.shape().isRequired,
    index: PropTypes.number.isRequired,
};

export default AdminBusinessAdvertisingAddCityField;
