import { Typography, Box, Avatar } from '@mui/material';

const CastCrewTabContent = ({ characters }) => {
    if (!characters || !characters.length) return null;

    const charactersByType = characters.reduce((acc, character) => {
        const type = character.peopleType || 'Other';
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(character);
        return acc;
    }, {});

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Cast & Crew
            </Typography>

            {Object.entries(charactersByType).map(([type, actors], typeIndex) => (
                <Box key={`type-${typeIndex}`} sx={{ mb: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 'medium',
                            mb: 1.5,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            pb: 0.5,
                        }}
                    >
                        {type}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        {actors.map((actor, index) => (
                            <Box
                                key={`actor-${typeIndex}-${index}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                <Avatar
                                    src={actor.personImgURL || ''}
                                    alt={actor.personName}
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        mr: 1.5,
                                        border: '1px solid #eee',
                                    }}
                                />
                                <Box>
                                    <Typography variant="body2" fontWeight="bold">
                                        {actor.personName}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {actor.name}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default CastCrewTabContent; 