import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Tab,
    Tabs,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FastFieldTextInput from '../../components/FastFieldTextInput';
import { POST_SYSTEM_NOTIFICATION_START } from 'stores/redux/actions/PushActions';
import SystemPushNotificationsHistory from '../../components/SystemPushNotificationsHistory';
import CommonWrapper from '../../screens/CommonWrapper';

function SystemPush() {
    const dispatch = useDispatch();
    const userStore = { is_admin: true };
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <CommonWrapper>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => {
                    console.log(newValue);
                    setSelectedTab(newValue);
                }}
            >
                <Tab label="Send notification" value={0} />
                <Tab label="Notification history" value={1} />
            </Tabs>
            {selectedTab === 0 && (
                <Formik
                    enableReinitialize={true}
                    validateOnChange={false}
                    initialValues={{}}
                    //   validationSchema={schema}
                    onSubmit={(values) => {
                        dispatch({
                            type: POST_SYSTEM_NOTIFICATION_START,
                            payload: values,
                        });
                        setShowConfirmModal(false);
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldTouched,
                        setFieldValue,
                        validateField,
                        touched,
                        isValid,
                        errors,
                        values,
                        dirty,
                    }) => (
                        <Grid
                            container
                            spacing={2}
                            justify="space-around"
                            style={{ marginTop: 20 }}
                        >
                            <Dialog
                                open={showConfirmModal}
                                onClose={() => setShowConfirmModal(false)}
                            >
                                <Grid xs={12} item container>
                                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            You are about to send a system push notification to all
                                            your users. This action cannot be reversed. Please click
                                            'send' to send it or 'cancel' to go back to edit view.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setShowConfirmModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSubmit} autoFocus>
                                            Send
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Dialog>
                            {userStore?.is_admin && (
                                <>
                                    <Grid item xs={12} container>
                                        <FormControl fullWidth>
                                            <FastFieldTextInput
                                                key={'1'}
                                                id="title"
                                                label="Message title"
                                                name="pushMessageTitle"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} container>
                                        <FormControl fullWidth>
                                            <FastFieldTextInput
                                                key={'2'}
                                                id="pushMessage"
                                                label="Push Message"
                                                name="pushMessage"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={10}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} container>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setShowConfirmModal(true);
                                            }}
                                        >
                                            Send push notification
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </Formik>
            )}
            {selectedTab === 1 && <SystemPushNotificationsHistory />}
        </CommonWrapper>
    );
}
export default SystemPush;
