import { GET_USER_PROFILE_SUCCESS } from 'stores/redux/actions/ProfileActions';

const ProfileReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default ProfileReducer;
