import { TabletDesktop } from 'baseline-ui/layout';
import { H2 } from 'baseline-ui/typography';
import { VIDEO_IDS } from 'common/consts/consts';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import YoutubeVideo from 'components/YoutubeVideo';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import siteBaseTheme from 'styles/siteBaseTheme';

export default function TabletDesktopView() {
    return (
        <TabletDesktop>
            <FormattedMessage
                id="businessScreen.videoPart.help.title"
                values={{
                    h2: (content) => <H2 textAlign="center">{content}</H2>,
                    text: (content) => (
                        <Label
                            display="block"
                            textAlign="center"
                            skin="secondary"
                            lineHeight={50}
                            fontSize={42}
                            fontWeight={700}
                            margin={{
                                bottom: 30,
                            }}
                        >
                            {content}
                        </Label>
                    ),
                    orange: (content) => <Label skin="primary">{content}</Label>,
                }}
            />

            <FormattedMessage
                id="businessScreen.videoPart.help.subtitle"
                values={{
                    text: (content) => (
                        <Label
                            display="block"
                            textAlign="center"
                            lineHeight={24}
                            fontSize={20}
                            fontWeight={600}
                            margin={{
                                bottom: 40,
                            }}
                        >
                            {content}
                        </Label>
                    ),
                    orange: (content) => <Label skin="primary">{content}</Label>,
                    bold: (content) => <Label fontWeight={700}>{content}</Label>,
                    br: () => <br />,
                }}
            />

            <FlexWrapper
                background={siteBaseTheme.base.colors.transparent}
                xsGap={0}
                mdGap={0}
                margin={{
                    top: 0,
                    right: 'auto',
                    left: 'auto',
                    bottom: 230,
                }}
                padding={{
                    top: 0,
                    bottom: 0,
                }}
                maxWidth={870}
            >
                <YoutubeVideo
                    videoId={VIDEO_IDS.BUILT_TO_HELP_BUSINESS_THRIVE}
                    showControls={true}
                    disableKeyboard={true}
                    fullScreenButton={false}
                    loop={false}
                />
            </FlexWrapper>
        </TabletDesktop>
    );
}
