import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { H6 } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import businessAddressUtils from 'common/utils/businessAddressUtils';
import BusinessListItemAddressLabelContainer from './BusinessListItemAddressLabelContainer';
import BusinessListItemLabelIconContainer from './BusinessListItemLabelIconContainer';

const BusinessListItemFormattedAddress = ({ formattedAddress }) => {
    const theme = useContext(ThemeContext);

    // NOTE: Some addresses do not include a formattedAddress.
    if (!formattedAddress?.includes(', USA')) {
        return null;
    }

    const { streetAddress, cityAddress, stateCodeAddress } =
        businessAddressUtils.formatBusinessAddress(formattedAddress);

    return (
        <BusinessListItemAddressLabelContainer>
            <BusinessListItemLabelIconContainer>
                <GaIcon icon="map-pin" size={14} color={theme.typography.colors.dark} />
            </BusinessListItemLabelIconContainer>
            <Row>
                <Col xxs={30}>
                    <H6 color="dark">{streetAddress}</H6>
                    <H6 color="dark">
                        {cityAddress}
                        {stateCodeAddress}
                    </H6>
                </Col>
            </Row>
        </BusinessListItemAddressLabelContainer>
    );
};

BusinessListItemFormattedAddress.propTypes = {
    formattedAddress: PropTypes.string.isRequired,
};

export default BusinessListItemFormattedAddress;
