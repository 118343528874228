import { React, useMemo } from 'react';
import { Field, Form } from 'formik';
import { Col, Row } from 'baseline-ui/layout';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { FormSelect } from 'baseline-ui/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'baseline-ui/button';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS } from 'stores/redux/actions/BusinessAccessRightsActions';
import { USER_ROLES } from 'common/consts/consts';

const AccessRightsForm = () => {
    const intl = useIntl();

    const isLoading = useProcessLoader({
        processNames: [ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withOverlay: true,
    });


    const options = useMemo(() => {
        return []
        .concat({ value: null, label: 'Role', disabled: true })
        .concat(Object.values(USER_ROLES).map(optionValue => ({
            value: optionValue,
            label: intl.formatMessage({
                id: `addBusiness.page.sections.accessRights.form.${optionValue}.label`,
            }),
            disabled: false,
        })));
    });

    return (
        <Form noValidate>
            <Row>
                <Col xxs={30} lg={30} xl={18}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({
                                id: 'addBusiness.page.sections.accessRights.form.emailAddress.label',
                            })}
                            name="accessRightsEmailAddress"
                            id="accessRightsEmailAddress"
                            type="email"
                        />
                    </FieldContainer>
                </Col>
                <Col xxs={30} xs={20} sm={16} md={10} xl={7}>
                    <FieldContainer>
                    <Field
                        component={FormSelect}
                        label={intl.formatMessage({
                            id: 'addBusiness.page.sections.accessRights.list.ROLE.label',
                        })}
                        id="userPageAccessRole"
                        name="userPageAccessRole"
                        options={options}
                        translateOptionsLabel={false}
                        className="capitalize-first-letter"
                    />
                    </FieldContainer>
                </Col>
                <Col xxs={30} xs={20} sm={16} md={10} xl={5}>
                    <Button
                        type="submit"
                        loading={isLoading}
                        loadingMessage={intl.formatMessage({
                            id: 'addBusiness.page.sections.accessRights.form.addingUser.label',
                        })}
                    >
                        <FormattedMessage id="addBusiness.page.sections.accessRights.form.addUser.label" />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AccessRightsForm;
