import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import {
    ADD_BUSINESS_ADDITIONAL_INFORMATION,
    ADD_BUSINESS_CATEGORIES_FILTERS,
    ADD_BUSINESS_LINKS,
    ADD_BUSINESS_PHOTOS,
    ADD_BUSINESS_REQUIRED_INFORMATION,
} from 'common/consts/addBusinessViewIds';
import useAddBusinessValid from 'common/hooks/useAddBusinessValid';

const useFavePageStepper = (currentStep = -1) => {
    const intl = useIntl();
    const { hasUnsavedChanges } = useSelector(businessFormSelector);
    
    const {
        isSaveValid,
        isCategoriesStepValid,
        isDescriptionStepValid,
        isLinksStepValid,
        isPhotosStepValid,
    } = useAddBusinessValid();

    const stepConfig = [
        {
            id: ADD_BUSINESS_REQUIRED_INFORMATION,
            labelId: 'BUSINESS.page.sections.requiredInformation.title',
            isValid: isSaveValid,
            stepIndex: 0,
        },
        {
            id: ADD_BUSINESS_CATEGORIES_FILTERS,
            labelId: 'addBusiness.page.sections.categories.button.label',
            isValid: isCategoriesStepValid(),
            stepIndex: 1,
        },
        {
            id: ADD_BUSINESS_ADDITIONAL_INFORMATION,
            labelId: 'addBusiness.page.sections.additionalInformation.button.label',
            isValid: isDescriptionStepValid(),
            stepIndex: 2,
        },
        {
            id: ADD_BUSINESS_LINKS,
            labelId: 'addBusiness.page.sections.links.button.label',
            isValid: isLinksStepValid(),
            stepIndex: 3,
        },
        {
            id: ADD_BUSINESS_PHOTOS,
            labelId: 'addBusiness.page.sections.photos.button.label',
            isValid: isPhotosStepValid(),
            stepIndex: 4,
        },
    ];

    const steps = stepConfig.map(({ id, labelId, isValid, stepIndex }) => ({
        id,
        label: intl.formatMessage({ id: labelId }),
        completed: isValid && (!hasUnsavedChanges || currentStep !== stepIndex),
    }));

    const getFirstInvalidStep = () => {
        const firstInvalidStep = stepConfig.find(
            (step) => !step.isValid || (hasUnsavedChanges && currentStep !== step.stepIndex)
        );
        return firstInvalidStep ? { id: firstInvalidStep.id, stepIndex: firstInvalidStep.stepIndex } : null;
    };

    return { steps, getFirstInvalidStep };
};

export default useFavePageStepper;
