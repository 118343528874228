import TitleWrapper from './style-components/TitleWrapper';
import {H3, H5} from 'baseline-ui/typography';
import React, {useEffect} from 'react';
import {Button} from 'baseline-ui/button';
import {FormattedMessage, useIntl} from 'react-intl';
import {FieldContainer, FormInput, FormMaskedInput} from 'baseline-ui/form';
import {Field, Formik} from 'formik';
import {MOBILE_PHONE_NUMBER_MASK, POSTAL_CODE_LENGTH} from 'common/consts/consts';
import CustomForm from './style-components/CustomForm';
import formDownloadFavePageApp from 'schema/formDownloadFavePageApp';
import {SET_DOWNLOAD_APP_FORM_DATA} from 'stores/redux/actions/DownloadFavePageAppFormActions';
import {useDispatch} from 'react-redux';
import FormWrapper from './style-components/FormWrapper';
import FieldFlexWrapper from './style-components/FieldFlexWrapper';
import ButtonsWrapper from './style-components/ButtonsWrapper';
import PropTypes from 'prop-types';

const StepOneForm = ({handleNext, onValidationError, formikRef}) =>{
    const intl = useIntl();
    const dispatch = useDispatch();
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                firstName: '',
                lastName: '',
                emailAddress: '',
                phoneNumber: '',
                zipCode: '',
            }}
            validationSchema={formDownloadFavePageApp[0]}
            onSubmit={() => {
                handleNext();
            }}
        >
            {({ values, errors, touched }) => {
                const hasErrors = Object.keys(errors).some((key) => touched[key])
                if (hasErrors) {
                    onValidationError();
                }
                useEffect(() => {
                    dispatch({
                        type: SET_DOWNLOAD_APP_FORM_DATA,
                        payload: values,
                    });
                }, [values, dispatch]);

                return (
                    <CustomForm noValidate>
                        <TitleWrapper>
                            <H5
                                color='primary'
                                fontWeight={500}
                            >
                                <FormattedMessage
                                    id='formTestApp.form.step.number.label'
                                    values={{
                                        number: 1
                                    }}
                                />
                            </H5>
                            <H3 fontWeight={600}>
                                <FormattedMessage id='formTestApp.form.step.one.title' />
                            </H3>
                        </TitleWrapper>
                        <FormWrapper className='prevent-ios-zoom'>
                                <FieldFlexWrapper>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'auth.firstName.label',
                                            })}
                                            name="firstName"
                                            id="firstName"
                                            type="text"
                                            required
                                            autoComplete="off"
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.lastName.label',
                                        })}
                                        name="lastName"
                                        id="lastName"
                                        type="text"
                                        required
                                        autoComplete="off"
                                    />
                                </FieldContainer>
                                </FieldFlexWrapper>
                                <FieldFlexWrapper>
                                <FieldContainer>
                                    <Field
                                        component={FormMaskedInput}
                                        label={intl.formatMessage({
                                            id: 'auth.phoneNumber.label',
                                        })}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        required
                                        mask={MOBILE_PHONE_NUMBER_MASK}
                                    />
                                </FieldContainer>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'auth.zipCode.label',
                                            })}
                                            name="zipCode"
                                            id="zipCode"
                                            maxLength={POSTAL_CODE_LENGTH.toString()}
                                            type="text"
                                            required
                                        />
                                    </FieldContainer>
                                </FieldFlexWrapper>
                                <FieldFlexWrapper>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'auth.emailAddress.label',
                                            })}
                                            name="emailAddress"
                                            id="emailAddress"
                                            type="email"
                                            required
                                            autoComplete="off"
                                        />
                                    </FieldContainer>
                                </FieldFlexWrapper>
                            </FormWrapper>
                        <ButtonsWrapper width={250}>
                            <Button
                                block
                                type='submit'
                            >
                                <FormattedMessage id='formTestApp.form.next.button' />
                            </Button>
                        </ButtonsWrapper>
                    </CustomForm>
                );
            }}
        </Formik>
    );
}
StepOneForm.propTypes = {
    handleNext: PropTypes.func.isRequired,
    onValidationError: PropTypes.func.isRequired,
};
export default StepOneForm;