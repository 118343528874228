import * as yup from 'yup';
import { useSelector } from 'react-redux';

import {
    businessFormRequiredFieldSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';

import addBusinessSectionsUtils from '../utils/addBusinessSectionsUtils';

const useAddBusinessValid = () => {
    const { type, description, selectedCategories, links, photos } =
        useSelector(businessFormSelector);
    const businessFormRequiredFields = useSelector(businessFormRequiredFieldSelector);

    const isSaveValid = addBusinessSectionsUtils.addBusinessHasAllRequiredFields(
        businessFormRequiredFields,
        type,
    );

    const isDescriptionStepValid = () => {
        const stepSchema = yup.object().shape({
            description: yup.string().required(),
        });

        return stepSchema.isValidSync({ description });
    };

    const isCategoriesStepValid = () => {
        // The number of categories must be 2 because the first one is Business, the second one in the array is the first category added into the list view
        const stepSchema = yup.object().shape({
            selectedCategories: yup.array().min(2).required(),
        });

        return stepSchema.isValidSync({ selectedCategories });
    };

    const isLinksStepValid = () => {
        const stepSchema = yup.object().shape({
            links: yup
                .array()
                .of(
                    yup.object().shape({
                        caption: yup.string(),
                        url: yup.string().required(),
                    }),
                )
                .min(1)
                .required(),
        });
        return stepSchema.isValidSync({ links });
    };

    const isPhotosStepValid = () => {
        const stepSchema = yup.object().shape({
            photos: yup.array().min(1).required(),
        });
        return stepSchema.isValidSync({
            photos: photos?.filter((photo) => photo.state !== 'deleted'),
        });
    };

    const isAllStepsValid = () => {
        const validations = [
            isSaveValid,
            isCategoriesStepValid(),
            isDescriptionStepValid(),
            isLinksStepValid(),
            isPhotosStepValid(),
        ];
        return validations.every((isValid) => isValid);
    };

    return {
        isSaveValid,
        isCategoriesStepValid,
        isDescriptionStepValid,
        isLinksStepValid,
        isPhotosStepValid,
        isAllStepsValid,
    };
};

export default useAddBusinessValid;
