import { put, takeEvery } from "redux-saga/effects";
import { GetItemDetails } from "api";
import {
  GET_ITEM_DETAILS_PROCESS,
  GET_ITEM_DETAILS_START,
  GET_ITEM_DETAILS_SUCCESS,
} from "../actions/ItemActions";
import genericSagaHandler from "./CommonSaga";

function* getItemDetailsSaga({ payload }) {
  yield genericSagaHandler(GET_ITEM_DETAILS_PROCESS, function* () {
    const body = yield GetItemDetails(payload);
    yield put({
      type: GET_ITEM_DETAILS_SUCCESS,
      payload: body,
    });
  });
}

export default function* ItemSagas() {
  yield takeEvery(GET_ITEM_DETAILS_START, getItemDetailsSaga);
}
