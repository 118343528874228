import React, { useEffect } from 'react';
import { DefaultAlert } from 'baseline-ui/alert';
import { H5 } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET } from 'stores/redux/actions/EntityLinkActions';
import { initialValues, EntityLinkAdminForm } from './EntityLinkAdminForm';

const AlertColorFix = styled.div`
    color: #000;
`;

export const EntityLinkAdmin = ({entityType} = props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET });
    }, []);
    return (
        <section>
            <DefaultAlert inProp alertType="primaryMuted" appear={false}>
                <AlertColorFix>
                    <H5 color="secondary">
                        <FormattedMessage id={`addBusiness.page.sections.${entityType}.superadmin.addandapprove.title`} />
                    </H5>
                    <Formik initialValues={initialValues}>
                        <EntityLinkAdminForm entityType={entityType} />
                    </Formik>
                </AlertColorFix>
            </DefaultAlert>
        </section>
    );
};

export default EntityLinkAdmin;
