const { useEffect } = require('react');
const { useDispatch } = require('react-redux');
const { GET_TOP_LEVEL_GROUPS_START } = require('stores/redux/actions/CategoriesActions');

const App = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_TOP_LEVEL_GROUPS_START,
        });
    }, []);
    return <>{children}</>;
};

export default App;
