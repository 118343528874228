import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    BACKGROUND_MODES,
    CONTACT_FORM_INQUIRY_TYPE_OPTIONS
} from 'common/consts/consts';
import { useDispatch } from 'react-redux';
import {
    POST_INQUIRE_PROCESS,
    POST_INQUIRE_PROCESS_RESET,
    POST_INQUIRE_START,
} from 'stores/redux/actions/InquireActions';
import useProcessLoader from 'common/hooks/useProcessLoader';
import useProcessErrorMessage from 'common/hooks/useProcessErrorMessage';
import { useComplete } from 'common/hooks/useComplete';
import FormInquire from 'components/FormInquiry';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import CommonWrapper from './CommonWrapper';
import Background from 'baseline-ui/background';
import FlexWrapper from 'components/FlexWrapper';
import ExtraLargeH1 from 'baseline-ui/typography/src/components/ExtraLargeH1';

const InquireRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isInquireComplete = useComplete(POST_INQUIRE_PROCESS);
    const isInquireLoading = useProcessLoader({ processNames: [POST_INQUIRE_PROCESS] });
    const inquireError = useProcessErrorMessage({ processName: POST_INQUIRE_PROCESS });

    useEffect(() => {
        return () => dispatch({ type: POST_INQUIRE_PROCESS_RESET });
    }, []);

    const inquireTypeOptions = Object.values(CONTACT_FORM_INQUIRY_TYPE_OPTIONS).map(optionValue => ({
        value: optionValue,
        label: `formInquiry.form.typeOfInquire.options${optionValue === null ? '' : `.${optionValue}`}.label`,
        disabled: optionValue === null
    }))

    return (
        <CommonWrapper>
            <Background mode={BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN}>
                <WelcomeMainContainer justifyContent='flex-start'>
                    <ExtraLargeH1
                        colorValue="secondary"
                        fontSize={48}
                        textAlign="center"
                        fontWeight={800}
                        margin={{
                            top: 50,
                            bottom: 20,
                        }}
                    >
                        <FormattedMessage id="formInquiry.yourInquire.heading.title" />
                    </ExtraLargeH1>
                    <FlexWrapper
                        border
                        shadow
                        width={45}
                        padding={{
                            top: 35,
                            bottom: 35,
                        }}
                    >
                        <FormInquire
                            showHeading={false}
                            inquireTypeOptions={inquireTypeOptions}
                            onSubmitInquire={(values) => {
                                dispatch({
                                    type: POST_INQUIRE_START,
                                    payload: {
                                        ...values,
                                    },
                                });
                            }}
                            errorMessage={inquireError}
                            isLoading={isInquireLoading}
                            inProp={!isInquireComplete}
                            inPropFeedback={isInquireComplete}
                            feedbackType="success"
                            feedbackMessage={intl.formatMessage({
                                id: 'formInquiry.yourInquire.heading.success',
                            })}
                            appear={false}
                        />
                    </FlexWrapper>
                </WelcomeMainContainer>
            </Background>
        </CommonWrapper>
    );
};

export default InquireRoute;
