import { useSelector } from 'react-redux';
import {
    businessFormRequiredApprovalFieldSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import addBusinessSectionsUtils from '../utils/addBusinessSectionsUtils';

const useEditBusinessValid = () => {
    const { type } = useSelector(businessFormSelector);
    const businessFormRequiredFields = useSelector(businessFormRequiredApprovalFieldSelector);

    const isSubmitValid = addBusinessSectionsUtils.addBusinessHasAllRequiredFields(
        businessFormRequiredFields,
        type,
    );

    return isSubmitValid;
};

export default useEditBusinessValid;
