import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BUSINESS_PATH } from 'common/consts/pathnames';
import { ContentRenderer } from 'baseline-ui/helpers';
import CommonWrapper from '../../CommonWrapper';
import WelcomeMainContainer from '../WelcomeMainContainer';
import ExtraLargeH1 from '../../../baseline-ui/typography/src/components/ExtraLargeH1';
import ButtonWrapper from '../../../baseline-ui/button/src/components/ButtonWrapper';
import { LinkButton } from '../../../baseline-ui/button';
import triangle from 'assets/images/triangle.svg';
import Label from '../../../components/Label';
import MultipleStepsForm from './DownloadFavePageForm/MultipleStepsForm';
import FlexWrapper from '../../../components/FlexWrapper';
import TitleWrapper from './DownloadFavePageForm/style-components/TitleWrapper';
import ParagraphWrapper from './ParagraphWrapper';
import LaunchComponent from '../../../components/LaunchComponent';
import Background from 'baseline-ui/background';
import { BACKGROUND_MODES } from 'common/consts/consts';
import WhatIsFavePage from './WhatIsFavePage';
import { TOKEN_LOGIN_PROCESS } from 'stores/redux/actions/AuthenticationActions';

const HomeRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        if (token) {
            dispatch({
                type: TOKEN_LOGIN_PROCESS,
                payload: { token }
            });
        }
    }, []);

    return (
        <CommonWrapper>
            <Background mode={BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_MOBILE_RIGHT}>
                <WelcomeMainContainer
                    justifyContent='flex-start'
                >
                    <TitleWrapper width={55}>
                        <ExtraLargeH1 margin={{
                            top: 70,
                            bottom: 64
                        }} colorValue='secondary' fontSize={42} textAlign='center'>
                            <FormattedMessage
                                id="auth.download.app.heading.text"
                                values={{
                                    orange: (text) => <Label skin='primary'>{text}</Label>,
                                }}
                            />
                        </ExtraLargeH1>
                    </TitleWrapper>
                    <FlexWrapper border shadow width={50} margin={{ bottom: 30 }} maxWidth={710}>
                        <MultipleStepsForm />
                    </FlexWrapper>
                    <TitleWrapper width={40}>
                        <ContentRenderer
                            textAlign="center"
                            color="muted"
                            source={intl.formatMessage({
                                id: 'welcome.signup.footnote.md',
                            })}
                        />
                    </TitleWrapper>
                </WelcomeMainContainer>
                <WhatIsFavePage />
                <FlexWrapper
                    maxWidth={1115}
                    margin={{
                        bottom: 0
                    }}
                    padding={{
                        bottom: 0
                    }}
                >
                    <LaunchComponent color='light' image={triangle} width={327} type='middle' borderRadius={24} />
                </FlexWrapper>
            </Background>
            <Background mode={BACKGROUND_MODES.TRIANGLE_BOTTOM_SPACE_BETWEEN}>
                <FlexWrapper
                    maxWidth={790}
                    margin={{
                        top: 130,
                        bottom: 100
                    }}
                    padding={{
                        top: 0,
                        bottom: 0
                    }}
                >
                    <ParagraphWrapper>
                        <ExtraLargeH1 colorValue='secondary' fontWeight={700} fontSize={48}>
                            <FormattedMessage
                                id='auth.signUp.banner.content'
                                values={{
                                    orange: (text) => <Label skin='primary'>{text}</Label>
                                }}
                            />
                        </ExtraLargeH1>
                    </ParagraphWrapper>
                    <ButtonWrapper width={260} margin={1}>
                        <LinkButton size="md" block to={BUSINESS_PATH} >
                            <FormattedMessage id="auth.signUp.intro.button.label" />
                        </LinkButton>
                    </ButtonWrapper>
                </FlexWrapper>
            </Background>
        </CommonWrapper>
    );
};

export default HomeRoute;
