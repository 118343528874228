import { SEARCH_BUSINESSES_ANY_STATUS } from 'common/consts/consts';
import { GET_BUSINESSES_SUCCESS, SET_BUSINESS_FILTER_SUCCESS } from '../actions/BusinessesActions';

const BusinessesReducer = (
    state = {
        searchText: '',
        ownedBusinesses: undefined,
        recommendedBusinesses: [],
        approvalstatus: SEARCH_BUSINESSES_ANY_STATUS,
        page: 0,
        cityLocation: '',
        filters: [],
        category: '',
        subcategory: '',
        filter: {}
    },
    action,
) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BUSINESSES_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }
        case SET_BUSINESS_FILTER_SUCCESS: {
            return {
                ...state,
                searchText: payload.searchQuery,
                approvalstatus: payload.approvalstatus,
                type: payload.type,
                page: payload.page ?? 0,
                cityLocation: payload.cityLocation,
                filters: payload.filters,
                category: payload.category,
                subcategory: payload.subcategory,
                filter: payload.filter
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default BusinessesReducer;
