import { isEmpty, map } from 'lodash';
import { createSelector } from 'reselect';
import productsAndServicesUtils from 'common/utils/productsAndServicesUtils';

export const sectionProductsSelector = (state) => state.SectionReducer;
export const allSectionProductsSelector = (state) =>
    state.SectionReducer.sectionProducts?.undefined;

export const allSectionProductsIdSelector = (state) =>
    map(state.SectionReducer.sectionProducts?.undefined, '_id');

export const sectionProductsBySectionNameSelector = (sectionName) =>
    createSelector(sectionProductsSelector, (allSectionProducts) => {
        if (!sectionName) {
            return null;
        }

        return allSectionProducts.sectionProducts[
            productsAndServicesUtils.getSectionKey(sectionName)
        ];
    });

export const sectionProductsNameBySectionNameSelector = (sectionName) =>
    createSelector(sectionProductsSelector, (allSectionProducts) => {
        if (!sectionName) {
            return null;
        }

        return map(
            allSectionProducts.sectionProducts[productsAndServicesUtils.getSectionKey(sectionName)],
            'name',
        );
    });

export const allActiveProductsSelector = createSelector(
    allSectionProductsSelector,
    (sectionProducts) => {
        return sectionProducts?.filter((product) => product.active) || [];
    },
);

export const filterAllActiveProductsSelector = (searchValue) =>
    createSelector(allActiveProductsSelector, (allActiveProducts) => {
        const regex = new RegExp(`${searchValue}`, 'i');

        if (searchValue) {
            return allActiveProducts.filter((allActiveProduct) =>
                regex.test(allActiveProduct.name),
            );
        }
        return allActiveProducts;
    });

export const allSectionsSelector = (state) => state.SectionReducer.sections;

export const initialSectionNameSelector = createSelector(allSectionsSelector, (allSections) => {
    if (!isEmpty(allSections)) {
        return productsAndServicesUtils.getSectionKey(allSections[0].name);
    }

    return null;
});

export const initialSectionIdSelector = createSelector(allSectionsSelector, (allSections) => {
    if (!isEmpty(allSections)) {
        return allSections[0]._id;
    }
    return null;
});

export const allSectionActiveProductsBySectionNameSelector = (sectionName) =>
    createSelector(sectionProductsSelector, (allSectionProducts) => {
        if (!sectionName) {
            return null;
        }
        return allSectionProducts.sectionProducts[
            productsAndServicesUtils.getSectionKey(sectionName)
        ]?.filter((product) => product.active);
    });

export const allSectionsActiveProductsSelector = createSelector(
    [sectionProductsSelector, allSectionsSelector],
    (allSectionProducts, allSections) => {
        if (!isEmpty(allSections) && !isEmpty(allSectionProducts?.sectionProducts)) {
            return allSections.map((section) => {
                return {
                    sectionName: section.name,
                    sectionId: section._id,
                    accepts: ['items'],
                    listItems:
                        map(
                            allSectionProducts.sectionProducts[
                                productsAndServicesUtils.getSectionKey(section.name)
                            ],
                            '_id',
                        ) || [],
                };
            });
        }
        return null;
    },
);

export const selectedSectionIdBySectionNameSelector = createSelector(
    [sectionProductsSelector],
    (sectionProducts) => {
        return sectionProducts.sections?.find(
            (section) => section.name === sectionProducts.selectedSection,
        )?._id;
    },
);

export const selectedSectionNameSelector = createSelector(
    [sectionProductsSelector],
    (sectionProducts) => sectionProducts.selectedSection,
);

export const selectedSectionIndexSelector = createSelector(
    [sectionProductsSelector],
    (sectionProducts) => {
        return sectionProducts.sections?.findIndex(
            (section) => section.name === sectionProducts.selectedSection,
        );
    },
);

export const sectionNameBySectionIdSelector = (id) =>
    createSelector(allSectionsSelector, (allSections) => {
        return allSections.find((section) => section._id === id).name;
    });
