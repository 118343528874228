import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { H5, Small } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { useDispatch } from 'react-redux';
import {
    REMOVE_PRODUCT_FROM_SECTION_START,
    SET_PRODUCT_TO_EDIT_START,
} from 'stores/redux/actions/EditProductActions';
import productsAndServicesUtils from 'common/utils/productsAndServicesUtils';
import { OverlayContext } from 'baseline-ui/overlay';
import ModalEditProduct from 'components/productsAndServices/modalProduct/ModalEditProduct';
import { useIntl } from 'react-intl';
import { useMessageBox } from 'components/MessageBox';
import ProductsAndServicesItemButton from './ProductsAndServicesItemButton';
import ProductsAndServicesItemTitle from './ProductsAndServicesItemTitle';
import ProductsAndServicesItemDescription from './ProductsAndServicesItemDescription';
import ProductsAndServicesItemMainDetails from './ProductsAndServicesItemMainDetails';
import ProductsAndServicesItemPrice from './ProductsAndServicesItemPrice';
import ProductsAndServicesItemCard from './ProductsAndServicesItemCard';
import ProductsAndServicesItemImage from './ProductsAndServicesItemImage';

const ProductsAndServicesItem = forwardRef(({ item, sectionId, sectionName, allowDrag }, ref) => {
    const { name, description, price, photos, _id: id } = item;
    const dispatch = useDispatch();
    const { showOverlay } = useContext(OverlayContext);
    const intl = useIntl();

    const messageBox = useMessageBox({
        messageId: 'addBusiness.modal.productsAndServices.removeItem.message',
        show: {
            yes: true,
            no: true,
        },
        data: {
            name,
        },
        onResponse(answer) {
            if (answer === 'yes') {
                dispatch({
                    type: REMOVE_PRODUCT_FROM_SECTION_START,
                    payload: {
                        item,
                        sectionId,
                    },
                });
            }
            return true;
        },
    });

    const handleOnClickRemove = () => {};

    return (
        <ProductsAndServicesItemCard ref={ref} allowDrag={allowDrag}>
                            <ProductsAndServicesItemImage
                    image={productsAndServicesUtils.getSectionItemPhoto(photos)}
                />
            <ProductsAndServicesItemButton
                onClick={() => {
                    dispatch({
                        type: SET_PRODUCT_TO_EDIT_START,
                        payload: id,
                    });
                    showOverlay(ModalEditProduct);
                }}
            >
                <ProductsAndServicesItemMainDetails>
                    <ProductsAndServicesItemTitle>
                        <H5 color="secondary">{name}</H5>
                        <ProductsAndServicesItemPrice>
                            {productsAndServicesUtils.formatProductPrice(intl, price)}
                        </ProductsAndServicesItemPrice>
                    </ProductsAndServicesItemTitle>
                    <ProductsAndServicesItemDescription>
                        <Small>
                            { truncate(description, {
                                length: 60,
                            })}
                        </Small>
                    </ProductsAndServicesItemDescription>
                    
                </ProductsAndServicesItemMainDetails>
            </ProductsAndServicesItemButton>
            <Button
                icon="x"
                btnType="link"
                skin="muted"
                size='sm'
                onClick={messageBox.handleShow({ id })}
            />
        </ProductsAndServicesItemCard>
    );
});

ProductsAndServicesItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.string,
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                name: PropTypes.string,
                order: PropTypes.number,
                state: PropTypes.string,
            }),
        ),
        _id: PropTypes.string,
    }).isRequired,
    sectionId: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    allowDrag: PropTypes.bool,
};

ProductsAndServicesItem.defaultProps = {
    allowDrag: false,
};

export default ProductsAndServicesItem;
