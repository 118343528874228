import Paper from "@mui/material/Paper";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {GaIcon} from "../../baseline-ui/icon";
import {LinkButton} from "../../baseline-ui/button";
import {EDIT_FAVEPAGE_PATH} from "common/consts/pathnames";
import {
    GET_ALL_LINK_NOTIFICATIONS_START,
    GET_LINK_NOTIFICATIONS_START,
    PUT_LINK_NOTIFICATIONS_START,
    SET_LINK_NOTIFICATIONS_NEXT_PAGE_START
} from "stores/redux/actions/LinkNotificationActions";
import React from "react";
import {styled} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import {ThemeContext} from "styled-components";
import PropTypes from "prop-types";
import {ADD_BUSINESS_PEOPLE} from "common/consts/addBusinessViewIds";

const NotificationTable = () => {
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);
    const { linkNotifications, total } = useSelector(
        ({ LinkNotificationsReducer }) => LinkNotificationsReducer,
    );
    const StyledTableCell = styled(TableCell)(() => ({
        color: "#482655",
        fontSize: "16px",
        textWrap: "nowrap",
    }));
    useEffect(() => {
        dispatch({type: GET_ALL_LINK_NOTIFICATIONS_START});
    }, []);

    useEffect(() => {
        if (!linkNotifications) {
            dispatch({
                type: GET_LINK_NOTIFICATIONS_START,
            });
        }
    }, [linkNotifications]);
    return(
        <TableContainer component={Paper} >
            <Table style={{marginTop: "100px"}}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Message</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {linkNotifications?.map((notification, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {notification.status === 'UNREAD'
                                    ? <GaIcon icon="unread" size={34} color={theme.base.colors.primary}/>
                                    : <GaIcon icon="open-mail" size={34} color={theme.base.colors.primary} />}
                            </TableCell>
                            <TableCell>{notification.title}</TableCell>
                            <TableCell>{notification.body}</TableCell>
                            <TableCell>{new Date(notification.created).toLocaleString()}</TableCell>
                            <TableCell>
                                <LinkButton
                                    icon="eye"
                                    btnType="link"
                                    to={`${EDIT_FAVEPAGE_PATH}/${encodeURIComponent(notification.entity)}?section=${ADD_BUSINESS_PEOPLE}`}
                                    onClick={() => {
                                        dispatch({
                                            type: PUT_LINK_NOTIFICATIONS_START,
                                            payload: { notificationId: notification.id }
                                        });
                                    }}
                                >
                                </LinkButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <StyledTableCell>Total: {total}</StyledTableCell>
                        {(linkNotifications?.length >=10 && linkNotifications?.length !== total) && (
                            <TableCell>
                                <LinkButton
                                    btnType="link"
                                    onClick={() => {
                                        dispatch({
                                            type: SET_LINK_NOTIFICATIONS_NEXT_PAGE_START,
                                        });
                                    }}
                                >
                                    LOAD MORE
                                </LinkButton>
                            </TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )

}
NotificationTable.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })),
};
export default NotificationTable;