import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { OverlayContext } from 'baseline-ui/overlay';
import { useDispatch, useSelector } from 'react-redux';
import { productToEditSelector } from 'stores/redux/selectors/productSelectors';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { ADD_PRODUCT_PROCESS, ADD_PRODUCT_START } from 'stores/redux/actions/EditProductActions';
import useProcessComplete from 'common/hooks/useProcessComplete';
import editProductSchema from 'schema/editProductSchema';
import { useIntl } from 'react-intl';
import ModalEditProductForm from './ModalEditProductForm';

const ModalAddProduct = ({ onRequestClose, ...props }) => {
    const intl = useIntl();
    const { hideOverlay } = useContext(OverlayContext);
    const dispatch = useDispatch();
    const product = useSelector(productToEditSelector);

    const { name, price, description } = product;

    const initialValues = {
        name,
        price,
        description,
    };

    const isLoading = useProcessLoader({
        processNames: [ADD_PRODUCT_PROCESS],
        withOverlay: true,
    });

    const isComplete = useProcessComplete({
        processNames: [ADD_PRODUCT_PROCESS],
        withFeedback: true,
    });

    useEffect(() => {
        if (isComplete && !isLoading) {
            hideOverlay();
        }
    }, [isComplete, isLoading]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={editProductSchema}
            onSubmit={() => dispatch({ type: ADD_PRODUCT_START })}
        >
            {({ handleSubmit }) => (
                <ModalEditProductForm
                    title={intl.formatMessage({ id: 'addBusiness.modal.addProduct.title' })}
                    footerActions={[
                        {
                            label: intl.formatMessage({
                                id: 'addBusiness.modal.addProduct.footerActions.cancel.button.label',
                            }),
                            btnType: 'link',
                            skin: 'muted',
                            onClick: () => {
                                hideOverlay();
                            },
                            type: 'button',
                        },
                        {
                            label: intl.formatMessage({
                                id: 'addBusiness.modal.addProduct.footerActions.confirm.button.label',
                            }),
                            skin: 'secondary',
                            onClick: () => {
                                handleSubmit();
                            },
                            loading: isLoading,
                            loadingMessage: intl.formatMessage({
                                id: 'addBusiness.modal.addProduct.footerActions.confirming.button.label',
                            }),
                            type: 'submit',
                        },
                    ]}
                    onRequestClose={onRequestClose}
                    {...props}
                />
            )}
        </Formik>
    );
};

ModalAddProduct.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
};

export default ModalAddProduct;
