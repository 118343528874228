import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import CategoriesFields from './CategoriesFields';
import { useDispatch } from 'react-redux';
import {
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    GET_BUSINESS_SUBCATEGORIES_START,
} from 'stores/redux/actions/CategoriesActions';
import isEqual from 'lodash/isEqual';

const CategoriesForm = ({
    onSubmit,
    categories,
    subcategories,
    selectedCategories,
    handleCategoryChange,
    handleSubcategoryChange,
    categoryId,
    subcategoryId,
    subcategoryFilters,
    onFormChange,
    sectionSelectedFilters,
}) => {
    const dispatch = useDispatch();
    const setFieldValueRef = useRef(null);
    const previousValuesRef = useRef({});
    const initialValues = {
        category: categoryId || '',
        subcategory: subcategoryId || '',
        filter: [],
    };

    useEffect(() => {
        if (setFieldValueRef.current) {
            setFieldValueRef.current('category', categoryId);
            setFieldValueRef.current('subcategory', subcategoryId);
            setFieldValueRef.current('filter', sectionSelectedFilters);
        }
    }, [categoryId, subcategoryId, sectionSelectedFilters]);

    useEffect(() => {
        if (categoryId) {
            dispatch({
                type: GET_BUSINESS_SUBCATEGORIES_START,
                payload: { categoryId },
            });
        }
    }, [categoryId, dispatch]);

    useEffect(() => {
        if (subcategoryId && categoryId) {
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId, categoryId },
            });
        }
    }, [subcategoryId, categoryId, dispatch]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ setFieldValue, values }) => {
                setFieldValueRef.current = setFieldValue;

                useEffect(() => {
                    if (!isEqual(previousValuesRef.current, values)) {
                        previousValuesRef.current = values;
                        if (onFormChange) {
                            onFormChange(values);
                        }
                    }
                }, [values, onFormChange]);

                return (
                    <Form>
                        <CategoriesFields
                            subcategories={subcategories}
                            subcategoryFilters={subcategoryFilters}
                            handleSubcategoryChange={handleSubcategoryChange}
                            handleCategoryChange={handleCategoryChange}
                            categories={categories}
                            selectedCategories={selectedCategories}
                            sectionSelectedFilters={sectionSelectedFilters}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

CategoriesForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    subcategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    handleCategoryChange: PropTypes.func.isRequired,
    handleSubcategoryChange: PropTypes.func.isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryFilters: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    sectionSelectedFilters: PropTypes.array,
    onFormChange: PropTypes.func.isRequired,
};

CategoriesForm.defaultProps = {
    subcategories: [],
    selectedCategories: [],
    categoryId: null,
    subcategoryId: null,
    subcategoryFilters: [],
    sectionSelectedFilters: [],
};

export default CategoriesForm;
