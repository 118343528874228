import update from 'immutability-helper';
import { cloneDeep, forEach, get, reject, remove } from 'lodash';
import productsAndServicesUtils from 'common/utils/productsAndServicesUtils';
import {
    ADD_SECTION_START,
    ADD_SINGLE_SECTION_SUCCESS,
    DELETE_SECTION_START,
    GET_SECTIONS_RESET,
    GET_SECTIONS_SUCCESS,
    GET_SECTION_PRODUCTS_SUCCESS,
    SET_SELECTED_SECTION_START,
    UPDATE_PRODUCT_IN_ALL_SECTIONS_START,
    UPDATE_PRODUCT_IN_SECTIONS_START,
    UPDATE_SECTIONS_START,
    UPDATE_SECTION_SUCCESS,
} from '../actions/SectionActions';
import {
    DELETE_PRODUCT_FROM_SECTION_START,
    SET_SHOW_ACTIVE_PRODUCTS_ONLY_START,
    UPDATE_PRODUCTS_TO_EDIT_START,
    UPDATE_SECTION_PRODUCTS_ORDER_START,
} from '../actions/EditProductActions';

// eslint-disable-next-line default-param-last
const SectionReducer = (state = { showActiveOnly: true }, { type, payload }) => {
    switch (type) {
        case UPDATE_PRODUCT_IN_SECTIONS_START: {
            const { updatedProduct } = payload;
            const sectionProducts = cloneDeep(state.sectionProducts);
            const sectionKeys = Object.keys(sectionProducts);
            sectionKeys.forEach((sectionKey) => {
                sectionProducts[sectionKey] = sectionProducts[sectionKey].map((product) => {
                    if (product._id === updatedProduct?._id) {
                        return {
                            ...product,
                            ...updatedProduct,
                            mapping: updatedProduct?.mapping
                                ? updatedProduct?.mapping
                                : product.mapping,
                        };
                    }
                    return product;
                });
            });

            return { ...state, sectionProducts };
        }
        case UPDATE_PRODUCT_IN_ALL_SECTIONS_START: {
            const { newProduct } = payload;
            const loadedProductSections = cloneDeep(state.sectionProducts);

            for (let key in loadedProductSections) {
                const section = loadedProductSections[key];
                section.find((product, index) => {
                    if (product._id === newProduct._id) {
                        loadedProductSections[key][index] = newProduct;
                    }
                });
            }
            return {
                ...state,
                sectionProducts: loadedProductSections,
            };
        }
        case DELETE_PRODUCT_FROM_SECTION_START: {
            const newDeletedProducts = [...(state.deletedProducts || [])];
            newDeletedProducts.push(payload);
            return { ...state, deletedProducts: newDeletedProducts };
        }
        case SET_SHOW_ACTIVE_PRODUCTS_ONLY_START: {
            return { ...state, showActiveOnly: !state.showActiveOnly };
        }
        case UPDATE_SECTION_PRODUCTS_ORDER_START: {
            return {
                ...state,
                sectionProducts: {
                    ...state.sectionProducts,
                    [productsAndServicesUtils.getSectionKey(state.selectedSection)]: payload,
                },
            };
        }
        case UPDATE_PRODUCTS_TO_EDIT_START: {
            const newSectionProducts = cloneDeep(state.sectionProducts);
            const { item, updateSections } = payload;

            if (updateSections) {
                const { sectionId, action } = updateSections;

                const sectionName = productsAndServicesUtils.getSectionKey(
                    state.sections.find((s) => s._id === sectionId)?.name,
                );

                const updatedSectionProducts = newSectionProducts[sectionName] || [];
                const index = updatedSectionProducts.findIndex((p) => p._id === item._id);

                if (index === -1) {
                    updatedSectionProducts.push(item);
                } else {
                    if (action === 'ADD') {
                        updatedSectionProducts[index] = item;
                    }
                    if (action === 'REMOVE') {
                        remove(updatedSectionProducts, (p) => p._id === item._id);
                    }
                }
            }

            return {
                ...state,
                sectionProducts: newSectionProducts,
            };
        }
        case GET_SECTIONS_SUCCESS: {
            return {
                ...state,
                sections: payload,
                originalSections: payload,
            };
        }
        case GET_SECTION_PRODUCTS_SUCCESS: {
            const { selectedSection, products, setOriginalSections } = payload;

            // TODO: FIGURE OUT IMMUTABILITY, I SUSPECT THERE COULD BE SOME LEAKS HERE !IMPORTANT!

            const newSectionProducts = cloneDeep(get(state, ['sectionProducts'], {}));

            // newSectionProducts[productsAndServicesUtils.getSectionKey(selectedSection)]?.forEach(
            //     (sectionProduct) => {
            //         const returnedProduct = products.find((p) => p._id === sectionProduct._id);
            //         if (returnedProduct) {
            //             sectionProduct = returnedProduct;
            //         }
            //     },
            // );

            newSectionProducts[productsAndServicesUtils.getSectionKey(selectedSection)] = products;

            return {
                ...state,
                sectionProducts: newSectionProducts,
                originalSectionProducts: setOriginalSections
                    ? cloneDeep(newSectionProducts)
                    : state.originalSectionProducts,
            };
        }
        case SET_SELECTED_SECTION_START: {
            return {
                ...state,
                selectedSection: payload,
            };
        }
        case DELETE_SECTION_START: {
            const sectionIndex = payload;
            const newSections = [...state.sections];
            newSections.splice(sectionIndex, 1);
            return {
                ...state,
                sections: newSections,
            };
        }
        case UPDATE_SECTION_SUCCESS: {
            const { name, sectionIndex } = payload;

            const newSections = update(state.sections, {
                [sectionIndex]: { name: { $set: name } },
            });

            const editedSectionOriginalName = state.sections[sectionIndex].name;

            const newSectionProducts = update(state.sectionProducts, {
                [productsAndServicesUtils.getSectionKey(name)]: {
                    $set: state.sectionProducts[
                        productsAndServicesUtils.getSectionKey(editedSectionOriginalName)
                    ],
                },
            });

            delete newSectionProducts[
                productsAndServicesUtils.getSectionKey(editedSectionOriginalName)
            ];

            return {
                ...state,
                sections: newSections,
                sectionProducts: newSectionProducts,
            };
        }
        case UPDATE_SECTIONS_START: {
            return {
                ...state,
                sections: payload,
            };
        }
        case ADD_SECTION_START: {
            const newSections = [...state.sections, payload];

            return {
                ...state,
                sections: newSections,
            };
        }
        case ADD_SINGLE_SECTION_SUCCESS: {
            const { originalSections } = payload;

            const oldSections = state.sections;
            const newSections = cloneDeep(originalSections);

            // const newSections = cloneDeep(state.sections);
            const newSectionProducts = cloneDeep(state.sectionProducts);
            newSections.forEach((newSection) => {
                const oldSection = oldSections.find((section) => section._id === newSection._id);
                if (oldSection?.name) {
                    newSection.name = oldSection.name;
                }

                if (!newSectionProducts[productsAndServicesUtils.getSectionKey(newSection.name)]) {
                    newSectionProducts[productsAndServicesUtils.getSectionKey(newSection.name)] =
                        [];
                }
            });

            return {
                ...state,
                sections: newSections,
                originalSections,
                sectionProducts: newSectionProducts,
            };
        }
        case GET_SECTIONS_RESET: {
            return { showActiveOnly: true };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};
export default SectionReducer;
