import React from 'react';
import joinBetaBackgroundImage from 'assets/images/banner.jpg';
import { ContentRenderer } from 'baseline-ui/helpers';
import { useIntl } from 'react-intl';
import WelcomeAsideContainer from '../WelcomeAsideContainer';
import WelcomeAsideBackgroundImage from '../WelcomeAsideBackgroundImage';
import WelcomeAsideContentContainer from '../WelcomeAsideContentContainer';
import WelcomeAsideContentRendererContainer from '../JoinBetaAside/WelcomeAsideContentRendererContainer';

const WelcomeTermsAndConditions = () => {
    const intl = useIntl();
    return (
        <WelcomeAsideContainer>
            <WelcomeAsideContentContainer>
                <WelcomeAsideContentRendererContainer>
                    <ContentRenderer
                        color="light"
                        source={intl.formatMessage({ id: 'welcome.inquiry.aside.content.md' })}
                    />
                </WelcomeAsideContentRendererContainer>
            </WelcomeAsideContentContainer>
            <WelcomeAsideBackgroundImage image={joinBetaBackgroundImage} />
        </WelcomeAsideContainer>
    );
};

export default WelcomeTermsAndConditions;
