import React, { useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { P } from 'baseline-ui/typography';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'baseline-ui/layout';
import {
    addedUsersBusinessAccessRightsSelector,
    allUsersBusinessAccessRightsSelector,
} from 'stores/redux/selectors/businessAccessRightsSelectors';
import {
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
} from 'stores/redux/actions/BusinessAccessRightsActions';
import { businessIdSelector } from 'stores/redux/selectors/businessFormSelectors';
import useProcessLoader from 'common/hooks/useProcessLoader';
import useProcessComplete from 'common/hooks/useProcessComplete';
import { ModalContext } from 'baseline-ui/modal';
import { currentUserEmailSelector } from 'stores/redux/selectors/authenticationSelectors';
import AccessRightsUserCreator from '../AccessRightsUserCreator';
import AccessRightsUsersListContainer from './AccessRightsUsersListContainer';
import AccessRightsUsersListItem from './AccessRightsUsersListItem';
import ModalAccessRightsRemoveUser from './ModalAccessRightsRemoveUser';
import { MY_FAVEPAGE_PATH } from 'common/consts/pathnames';

const AccessRightsUsersList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(addedUsersBusinessAccessRightsSelector);
    const allUsers = useSelector(allUsersBusinessAccessRightsSelector);
    const currentUserEmail = useSelector(currentUserEmailSelector);

    const businessId = useSelector(businessIdSelector);
    const { showModal } = useContext(ModalContext);

    const handleOnRemoveLink = useCallback((id, email) => {
        dispatch({
            type: REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
            payload: {
                entityid: businessId,
                userId: id,
            },
        });

        if (email === currentUserEmail) {
            navigate(MY_FAVEPAGE_PATH);
        }
    }, [dispatch, businessId, currentUserEmail, navigate]);

    useProcessLoader({
        processNames: [REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withOverlay: true,
    });

    useProcessComplete({
        processNames: [REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS],
        withFeedback: true,
    });

    if (isEmpty(allUsers)) {
        return null;
    }

    return (
        <AccessRightsUsersListContainer>
            <Row justifyContent="space-between">
                <Col>
                    <P lead>
                        <FormattedMessage id="addBusiness.page.sections.accessRights.list.title" />
                    </P>
                </Col>
            </Row>
            <AccessRightsUserCreator />
            {users.map((user, index) => {
                const { email, id } = user;
                return (
                    <AccessRightsUsersListItem
                        isFirst={index === 0}
                        key={`${email}-${index}`}
                        userEmailAddress={email}
                        isManager={user?.manager}
                        isOwner={user?.owner}
                        onClick={() =>
                            showModal(ModalAccessRightsRemoveUser, {
                                emailAddress: email,
                                confirmDeleteCallback: () => handleOnRemoveLink(id, email),
                            })
                        }
                    />
                );
            })}
        </AccessRightsUsersListContainer>
    );
};

export default AccessRightsUsersList;
