import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import EntityTagFilters from 'components/EntityTagFilters/EntityTagFilters';
import PageHeader from 'components/pageLayout/PageHeader';
import StatusTag from 'components/Tags/StatusTag';
import { useMemo, useState } from 'react';
import CommonWrapper from 'screens/CommonWrapper';
import { useTagManagement } from './hooks/useTagManagement';
import { useColumnHeight } from './hooks/useColumnHeight';

const TabPanel = ({ children, value, index, sx = {}, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`interest-tabpanel-${index}`}
            {...other}
            style={{ width: '100%', height: value === index ? '100%' : 0 }}
        >
            {value === index && <Box sx={{ p: 3, ...sx, height: '100%' }}>{children}</Box>}
        </div>
    );
};

const ImageSection = ({ imageSrc, title, imageStyle, imageProps }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            height: '100%',
        }}
    >
        <Box
            sx={{
                borderRadius: 2,
                overflow: 'hidden',
                display: 'flex',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
                mb: 3,
            }}
        >
            <Box component="img" sx={imageStyle} src={imageSrc} alt={title} {...imageProps} />
        </Box>
    </Box>
);

const LeftColumnContent = ({
    tabSections,
    leftTabValue,
    handleLeftTabChange,
    imageSrc,
    title,
    imageStyle,
    imageProps,
}) => {
    if (tabSections.length === 0) {
        return (
            <ImageSection
                imageSrc={imageSrc}
                title={title}
                imageStyle={imageStyle}
                imageProps={imageProps}
            />
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs
                    value={leftTabValue}
                    onChange={handleLeftTabChange}
                    aria-label="media tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ mb: 1 }}
                >
                    <Tab label="Cover" />
                    {tabSections.map((section, index) => (
                        <Tab key={`left-tab-${index}`} label={section.label} />
                    ))}
                </Tabs>
            </Box>

            <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <TabPanel value={leftTabValue} index={0} sx={{ width: '100%', p: 0 }}>
                    <ImageSection
                        imageSrc={imageSrc}
                        title={title}
                        imageStyle={imageStyle}
                        imageProps={imageProps}
                    />
                </TabPanel>

                {tabSections.map((section, index) => (
                    <TabPanel
                        key={`left-tabpanel-${index + 1}`}
                        value={leftTabValue}
                        index={index + 1}
                        sx={{ width: '100%', p: 0, overflowY: 'auto' }}
                    >
                        {section.content}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
};

const DetailItems = ({ detailItems }) => (
    <Grid container spacing={3} sx={{ marginTop: 2 }}>
        {detailItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={`detail-${index}`} {...(item.gridProps || {})}>
                <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                        {item.label}
                    </Typography>
                    <Typography variant="body1" sx={item.sx || {}}>
                        {item.value}
                    </Typography>
                </Box>
            </Grid>
        ))}
    </Grid>
);

const TagsSection = ({ entityId, filterTagIds, isSaving, handleSaveTags, handleFilterChange }) => {
    return (
        <Box mt={3}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Tags
            </Typography>
            <EntityTagFilters
                entityId={entityId}
                onFilterChange={handleFilterChange}
                preselectedTagIds={filterTagIds}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTags}
                    disabled={isSaving}
                >
                    {isSaving ? 'Saving...' : 'Save Tags'}
                </Button>
            </Box>
        </Box>
    );
};

const InterestDetailsLayout = ({
    entityId,
    title,
    approvalStatus,
    imageSrc,
    subtitle,
    description,
    detailItems = [],
    headerExtras = null,
    imageProps = {},
    icon = null,
    tags = [],
    customSections = {
        belowDetails: null,
        aboveDescription: null,
    },
    tabSections = [],
}) => {
    const [leftTabValue, setLeftTabValue] = useState(0);
    const handleLeftTabChange = (event, newValue) => {
        setLeftTabValue(newValue);
    };

    const { columnRef: rightColumnRef, columnHeight: rightColumnHeight } = useColumnHeight();
    const { isSaving, filterTagIds, handleFilterChange, handleSaveTags } = useTagManagement(
        entityId,
        tags,
    );

    const defaultImageStyle = {
        width: '100%',
        height: 'auto',
        maxHeight: '450px',
        objectFit: 'contain',
        display: 'block',
    };

    const imageStyle = useMemo(
        () => ({
            ...defaultImageStyle,
            ...(imageProps.sx || {}),
        }),
        [imageProps.sx],
    );

    return (
        <CommonWrapper>
            <PageHeader title={title} showStatus={true} icon={icon} />
            <Container maxWidth="xl">
                <Box sx={{ padding: { xs: 2, md: 4 }, marginTop: '2rem' }}>
                    <Card sx={{ padding: 3, mb: 4 }}>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: 2,
                                    borderRight: {
                                        xs: 'none',
                                        md: '1px solid rgba(0, 0, 0, 0.12)',
                                    },
                                    height:
                                        rightColumnHeight > 0
                                            ? `${rightColumnHeight - 20}px`
                                            : 'auto',
                                }}
                            >
                                <LeftColumnContent
                                    tabSections={tabSections}
                                    leftTabValue={leftTabValue}
                                    handleLeftTabChange={handleLeftTabChange}
                                    imageSrc={imageSrc}
                                    title={title}
                                    imageStyle={imageStyle}
                                    imageProps={imageProps}
                                />
                            </Grid>

                            <Grid item xs={12} md={8} ref={rightColumnRef}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography variant="h4" gutterBottom>
                                            {title}
                                        </Typography>
                                        <Box display="flex" alignItems="center" gap={2}>
                                            {headerExtras}
                                            <StatusTag status={approvalStatus} editable={true} />
                                        </Box>
                                    </Box>

                                    <Typography
                                        variant="subtitle1"
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        {subtitle}
                                    </Typography>

                                    {customSections.aboveDescription && (
                                        <Box mt={2} mb={2}>
                                            {customSections.aboveDescription}
                                        </Box>
                                    )}

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="body1" paragraph>
                                        {description}
                                    </Typography>

                                    <DetailItems detailItems={detailItems} />

                                    {customSections.belowDetails && (
                                        <Box mt={3}>{customSections.belowDetails}</Box>
                                    )}

                                    <TagsSection
                                        entityId={entityId}
                                        filterTagIds={filterTagIds}
                                        isSaving={isSaving}
                                        handleSaveTags={handleSaveTags}
                                        handleFilterChange={handleFilterChange}
                                    />
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Container>
        </CommonWrapper>
    );
};

export default InterestDetailsLayout;
