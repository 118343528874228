import React from 'react';
import { H1 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { Container } from '@mui/material';
import EventForm from './EventForm';
import formEventSchema from 'schema/formEventSchema';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const EventInformation = ({ eventDetails }) => {
    const categories = useSelector((state) => state.CategoriesReducer.interestsCategory);
    const initialValues = {
        entityId: eventDetails?.entity_id || '',
        eventName: eventDetails?.eventName || '',
        startDate: eventDetails?.startDate
            ? dayjs(eventDetails?.startDate).format('YYYY-MM-DD')
            : null,
        endDate:
            eventDetails?.endDate &&
            dayjs(eventDetails?.endDate).format('YYYY-MM-DD') !== 'Invalid Date'
                ? dayjs(eventDetails?.endDate).format('YYYY-MM-DD')
                : null,
        startTime: eventDetails?.startTime ? dayjs(eventDetails?.startTime, 'HH:mm') : null,
        endTime: eventDetails?.endTime
            ? dayjs(eventDetails.endTime, 'HH:mm').isValid()
                ? dayjs(eventDetails.endTime, 'HH:mm')
                : null
            : null,
        description: eventDetails?.description || '',
        locationType: eventDetails?.locationType || 'CREATOR',
        locationBusiness_selected: eventDetails?.locationBusiness_selected || '',
        locationName: eventDetails?.locationName || '',
        address: eventDetails?.address || '',
        city: eventDetails?.city || '',
        state: eventDetails?.state || '',
        zipCode: eventDetails?.zipCode || '',
        eventType: eventDetails?.eventType || '',
        customEventType: eventDetails?.customEventType || '',
        headlinePerson: eventDetails?.headlinePerson || [],
        additionalPeople: eventDetails?.additionalPeople || [],
        selectedCategories: eventDetails?.selectedCategories
            ? JSON.parse(eventDetails.selectedCategories)
            : [],
        eventLinks: eventDetails?.eventLinks || [],
        category: categories[0]?._id || '',
        subcategory: '',
        filter: {},
        photos: eventDetails?.photos ? JSON.parse(eventDetails.photos) : [],
        hasUnsavedChanges: eventDetails?.hasUnsavedChanges || false,
    };

    return (
        <>
            <Container>
                <H1 color="primary">
                    <FormattedMessage id="addBusiness.page.sections.events.title" />
                </H1>

                <Formik initialValues={initialValues} validationSchema={formEventSchema}>
                    {() => <EventForm initialValues={initialValues} />}
                </Formik>
            </Container>
        </>
    );
};

export default EventInformation;
