import { useSelector } from 'react-redux';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import InterestDetailsLayout from 'screens/Interests/_Layout/InterestDetailsLayout';
import { FaClipboardList } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

const RecipeDetails = () => {
    const {
        name,
        approval_status,
        description,
        prep_time,
        cook_time,
        servings,
        photos
    }= useSelector(businessFormSelector);

    const { id } = useParams();
    const tags = useSelector(businessCategoriesSelector);
    const coverImage = photos?.[0]?.url || "https://th.bing.com/th/id/OIP.xdOm4CvsOJsailk7TMJURQHaDt?rs=1&pid=ImgDetMain";

    const detailItems = [
        {
            label: 'Preparation time',
            value: prep_time?.toLocaleString(),
        },
        {
            label: 'Cook Time',
            value: cook_time,
        },
        {
            label: 'Servings',
            value: servings,
        },
    ];


    return (
        <InterestDetailsLayout
            entityId={id}
            title={name}
            approvalStatus={approval_status}
            imageSrc={coverImage}
            description={description}
            detailItems={detailItems}
            icon={FaClipboardList}
            tags={tags}
        />
    );
};

export default RecipeDetails;
