import React, { forwardRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import ButtonBase from './ButtonBase';
import Container from './Label/Container';
import Label from './Label/Label';
import buttonPropTypes from '../prop-types/buttonPropTypes';

const Button = forwardRef(
    (
        {
            loading,
            loadingMessage,
            icon,
            iconPostLabel,
            iconRotate,
            size,
            block,
            iconSize,
            iconColor,
            children,
            skin,
            btnType,
            isActive,
            type,
            fullWidth,
            ...buttonProps
        },
        ref,
    ) => {
        const intl = useIntl();
        const { themeMode } = useContext(ThemeModeContext);

        if (loading) {
            return (
                <ButtonBase
                    ref={ref}
                    sizeValue={size}
                    blockValue={block}
                    skin={skin}
                    btnType={btnType}
                    themeMode={themeMode}
                    isActive={isActive}
                    {...buttonProps}
                    disabled
                >
                    <Container >
                        <Label
                            skin={skin}
                            size={iconSize}
                            color={iconColor}
                            btnSize={size}
                            iconPostLabel={iconPostLabel}
                            isLoading={loading}
                            aria-label={
                                loadingMessage ||
                                intl.formatMessage({ id: 'button.loadingMessage' })
                            }
                        >
                            {loadingMessage || intl.formatMessage({ id: 'button.loadingMessage' })}
                        </Label>
                    </Container>
                </ButtonBase>
            );
        }

        const buttonContent = icon ? (
            <Container>
                <Label
                    icon={icon}
                    iconPostLabel={iconPostLabel}
                    iconRotate={iconRotate}
                    size={iconSize}
                    color={iconColor}
                    btnSize={size}
                    skin={skin}
                >
                    {children}
                </Label>
            </Container>
        ) : (
            children
        );

        return (
            <ButtonBase
                ref={ref}
                sizeValue={size}
                blockValue={block}
                skin={skin}
                btnType={btnType}
                themeMode={themeMode}
                isActive={isActive}
                type={type}
                style={fullWidth ? { width: '100%' } : {}}
                {...buttonProps}
            >
                {buttonContent}
            </ButtonBase>
        );
    },
);

Button.propTypes = {
    skin: buttonPropTypes.buttonSkin,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    block: PropTypes.bool,
    size: buttonPropTypes.buttonSize,
    btnType: buttonPropTypes.buttonBtnType,
    children: PropTypes.node,
    isActive: PropTypes.bool,
    iconPostLabel: PropTypes.bool,
    type: PropTypes.string,
    iconRotate: PropTypes.bool,
};

Button.defaultProps = {
    skin: 'primary',
    btnType: 'default',
    size: 'default',
    disabled: false,
    loading: false,
    loadingMessage: undefined,
    icon: undefined,
    iconColor: 'currentColor',
    iconSize: undefined,
    block: false,
    children: undefined,
    isActive: false,
    iconPostLabel: false,
    type: 'button',
    iconRotate: false,
};

export default Button;
