import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormCheckbox } from 'baseline-ui/form';
import { useDispatch, useSelector } from 'react-redux';
import {
    SET_PRODUCT_SECTIONS_TO_EDIT_START,
} from 'stores/redux/actions/EditProductActions';
import { productToEditSelector } from 'stores/redux/selectors/productSelectors';
import ModalEditProductManageSectionsListItemContainer from './ModalEditProductManageSectionsListItemContainer';
import ModalEditProductManageSectionsListItemTitle from './ModalEditProductManageSectionsListItemTitle';

const ModalEditProductManageSectionsListItem = ({ sectionName, productId }) => {
    const dispatch = useDispatch();
    const item = useSelector(productToEditSelector);
    let isSectionSelected = item.sectionsFoundIn?.some(
        (section) => section.name === sectionName
    );

    const handleOnChange =
        (targetId, targetValue ) => {
            dispatch({
                type: SET_PRODUCT_SECTIONS_TO_EDIT_START,
                payload: {
                    _id: targetId,
                    targetValue: targetValue,
                    name: sectionName,
                },
            });
        };
    return (
        <ModalEditProductManageSectionsListItemContainer>
            <Field
                component={FormCheckbox}
                id={productId}
                name={productId}
                defaultChecked={isSectionSelected}
                onChange={(v) => handleOnChange(v.target.id, v.target.checked)}
            >
                <ModalEditProductManageSectionsListItemTitle>
                    {sectionName}
                </ModalEditProductManageSectionsListItemTitle>
            </Field>
        </ModalEditProductManageSectionsListItemContainer>
    );
};

ModalEditProductManageSectionsListItem.propTypes = {
    sectionName: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
};

export default ModalEditProductManageSectionsListItem;
