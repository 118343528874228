import React from 'react';
import PropTypes from 'prop-types';
import { SET_BUSINESS_FILTER_PROCESS } from 'stores/redux/actions/BusinessesActions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useProcessComplete from 'common/hooks/useProcessComplete';
import { H5 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { useFormikContext } from 'formik';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { FormattedMessage } from 'react-intl';
import businessUtils from 'common/utils/businessUtils';
import MyBusinessListEmptyContainer from './MyBusinessListEmptyContainer';
import MyBusinessListEmptyContent from './MyBusinessListEmptyContent';
import MyBusinessListEmptyTitle from './MyBusinessListEmptyTitle';

const MyBusinessListEmpty = ({ searchType }) => {
    const { ownedBusinesses } = useSelector(({ BusinessesReducer }) => BusinessesReducer);
    const isComplete = useProcessComplete({ processNames: [SET_BUSINESS_FILTER_PROCESS] });
    const isLoading = useProcessLoader({ processNames: [SET_BUSINESS_FILTER_PROCESS] });
    const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();

    if (!isEmpty(ownedBusinesses) || (isLoading && !isComplete)) {
        return null;
    }

    return (
        <MyBusinessListEmptyContainer>
            <MyBusinessListEmptyContent>
                <MyBusinessListEmptyTitle>
                    <H5 color="muted" textAlign="center">
                        <FormattedMessage id="myBusinesses.list.noResults.label" />
                    </H5>
                </MyBusinessListEmptyTitle>
                <Button
                    btnType="outlined"
                    icon="x-circle"
                    type="button"
                    skin="muted"
                    size="sm"
                    onClick={() => {
                        businessUtils.resetSearchFavePagesForm(setFieldValue);
                        dispatch({
                            type: searchType,
                        });
                    }}
                >
                    <FormattedMessage id="myBusinesses.page.clearSearch.button.label" />
                </Button>
            </MyBusinessListEmptyContent>
        </MyBusinessListEmptyContainer>
    );
};

MyBusinessListEmpty.propTypes = {
    searchType: PropTypes.string.isRequired,
};

export default MyBusinessListEmpty;
