import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {  useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import {FieldContainer, FormInput} from 'baseline-ui/form';
import { ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_PEOPLE } from 'common/consts/consts';
import {  Box } from '@mui/material';
import useGoogleMapsAutocomplete from 'common/hooks/useGoogleMapsAutocomplete';
import { makeStyles } from '@mui/styles';
import { FEATURE_ENTITYPICKER_BY_ADDRESS } from './features';
import { GET_ENTITYPICKER_START, RESET_ENTITYPICKER_START } from 'stores/redux/actions/EntityPicker';
import { EntityPickerContent } from './EntityPickerContent'; // Import your component

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
            },
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#f15a24',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f15a24',
        },
    },
    entityBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        borderRadius: '15px',
        border: '1px solid #ccc',
        marginBottom: '16px',
        cursor: 'pointer',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            backgroundColor: '#f9f9f9',
        },
    },
    entityList: {
        display: (props) => (props.hidden ? 'none' : 'block'),
        height: '16rem',
        padding: '0.5rem 1rem',
        overflowX: 'hidden',
        overflowY: 'scroll',
        border: 'thin solid #ccc',
        marginTop: '16px',
    },
}));

const DEBOUNCE_OPTIONS = [
    200,
    {
        maxWait: 1000,
    },
];

const EventEntityPicker = (props) => {
    const classes = useStyles();
    const { type, hidden, onEntitySelect, identifier } = props;
    const name = props.name ?? 'entitypicker';
    const label = props.label ?? `${type}.entitypicker.search.placeholder`;
    const intl = useIntl();
    const ref = useRef();
    const dispatch = useDispatch();
    if (FEATURE_ENTITYPICKER_BY_ADDRESS) {
        useGoogleMapsAutocomplete(ref);
    }
    const { values, setFieldValue } = useFormikContext();
    const { entities } = useSelector((state) => state.EntityPickerReducer);
    const searchFieldId = `${name}_search`;
    const searchQuery = values[searchFieldId];
    useEffect(() => {
        const triggerSearch = debounce((search) => {
            dispatch({
                type: GET_ENTITYPICKER_START,
                payload: {
                    type,
                    searchQuery: search,
                    identifier
                },
            });
        }, ...DEBOUNCE_OPTIONS);
        triggerSearch(searchQuery);
        return () => {
            triggerSearch.cancel();
        };
    }, [searchQuery, type, dispatch, identifier]);

    const handleReset = () => {
        setFieldValue(searchFieldId, '');
        dispatch({ type: RESET_ENTITYPICKER_START });
        const searchField = document.querySelector(`#${searchFieldId}`);
        if (searchField) {
            searchField.disabled = false;
            searchField.focus();
        }
    };

    const handleEntitySelect = (entity) => {
        if (onEntitySelect) {
            onEntitySelect(entity);
        }
        handleReset();
    };

    return (
        <FieldContainer hidden={hidden}>
            <Field
                component={FormInput}
                name={searchFieldId}
                label={label}
                inputRef={ref}
                placeholder=""
                type="text"
                autoComplete="off"
            />
            {entities?.[identifier] && entities?.[identifier].length > 0 && (
                <Box className={classes.entityList}>
                    {entities?.[identifier].map((entity) => (
                        <Box
                            key={entity._id}
                            className={classes.entityBox}
                            onClick={() => handleEntitySelect(entity)}
                        >
                            <EntityPickerContent entity={entity} />
                        </Box>
                    ))}
                </Box>
            )}
        </FieldContainer>
    );
};

EventEntityPicker.propTypes = {
    type: PropTypes.oneOf([ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_PEOPLE]).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    onEntitySelect: PropTypes.func, // Callback to handle entity selection
    hidden: PropTypes.bool,
};

EventEntityPicker.defaultProps = {
    name: undefined,
    label: undefined,
    onEntitySelect: undefined,
    hidden: false,
};

export default EventEntityPicker;
