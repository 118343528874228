import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { editEntityTags } from 'api';
import { FeedbackContext } from 'baseline-ui/feedback';
import { ProcessOverlayContext } from 'baseline-ui/processOverlay';

export const useTagManagement = (entityId, tags) => {
    const { setProcessOverlayOnStage } = useContext(ProcessOverlayContext);
    const { setFeedbackOnStage } = useContext(FeedbackContext);

    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [initialTagsSet, setInitialTagsSet] = useState(false);

    const filterTagIds = useMemo(
        () => tags?.filter((tag) => tag.type === 'filter').map((tag) => tag.id),
        [tags],
    );

    const categoryTagIds = useMemo(
        () =>
            tags
                ?.filter((tag) => ['group', 'category', 'subcategory', 'header'].includes(tag.type))
                .map((tag) => tag.id),
        [tags],
    );

    useEffect(() => {
        if (filterTagIds?.length > 0 && !initialTagsSet) {
            setSelectedTagIds(filterTagIds);
            setInitialTagsSet(true);
        }
    }, [filterTagIds, initialTagsSet]);

    const handleFilterChange = useCallback(
        (selectedFilters) => {
            const combinedTagIds = [...selectedFilters, ...categoryTagIds];
            setSelectedTagIds(combinedTagIds);
        },
        [categoryTagIds],
    );

    const handleSaveTags = async () => {
        if (!entityId) {
            setFeedbackOnStage({
                label: 'Entity ID is missing',
                type: 'danger',
                show: true,
                timeout: 3000,
            });
            return;
        }

        try {
            setIsSaving(true);
            setProcessOverlayOnStage({ show: true });

            const formData = new FormData();
            formData.append('entity_id', entityId);
            formData.append('tags', JSON.stringify(selectedTagIds));

            await editEntityTags(formData);

            setFeedbackOnStage({
                label: 'Tags updated successfully',
                type: 'success',
                show: true,
                timeout: 3000,
            });
        } catch (error) {
            setFeedbackOnStage({
                label: 'Failed to update tags',
                type: 'danger',
                show: true,
                timeout: 3000,
            });
        } finally {
            setProcessOverlayOnStage({ show: false });
            setIsSaving(false);
        }
    };

    return {
        selectedTagIds,
        isSaving,
        filterTagIds,
        handleFilterChange,
        handleSaveTags,
    };
};
