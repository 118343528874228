import React from 'react';
import { Container, Main, MainWrapper, Row } from 'baseline-ui/layout';
import { LinkButton } from 'baseline-ui/button';
import {
    BETA_SIGNUPS,
    TAG_MANAGER_PATH,
    CATEGORYMANAGER_PATH as TIERMANAGER_PATH,
    SYSTEM_PUSH,
    ENTITY_MAPPING_CONFIGURATION,
    TEASERS_CONFIGURATION,
    USER_LIST,
} from 'common/consts/pathnames';
import { useIntl } from 'react-intl';
import CommonWrapper from '../CommonWrapper';

const AdminArea = ({ isMobile }) => {
    const intl = useIntl();

    return (
        <CommonWrapper>
            <Container>
                <Row flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                    <MainWrapper>
                        <Main>
                            <LinkButton to={BETA_SIGNUPS}>
                                {intl.formatMessage({ id: 'superAdmin.betaSignUps.page.title' })}
                            </LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={SYSTEM_PUSH}>
                                {intl.formatMessage({ id: 'superAdmin.systemPush.page.title' })}
                            </LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={TAG_MANAGER_PATH}>
                                {intl.formatMessage({ id: 'superAdmin.tags.page.title' })}
                            </LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={TIERMANAGER_PATH}>
                                {intl.formatMessage({ id: 'superAdmin.tiers.page.title' })}
                            </LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={ENTITY_MAPPING_CONFIGURATION}>
                                Entity Mapping Confguration
                            </LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={TEASERS_CONFIGURATION}>Teasers</LinkButton>
                        </Main>
                        <Main>
                            <LinkButton to={USER_LIST}>User List</LinkButton>
                        </Main>
                    </MainWrapper>
                </Row>
            </Container>
        </CommonWrapper>
    );
};

export default AdminArea;
