import { Box, Chip } from '@mui/material';

const GenresSection = ({ genres }) => {
    if (!genres || !genres.length) return null;

    return (
        <Box display="flex" gap={1} flexWrap="wrap">
            {genres.map((genre, index) => (
                <Chip
                    key={`genre-${index}`}
                    label={genre.charAt(0).toUpperCase() + genre.slice(1)}
                    color="primary"
                    variant="outlined"
                    size="small"
                />
            ))}
        </Box>
    );
};

export default GenresSection; 