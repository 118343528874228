import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import InterestDetailsLayout from 'screens/Interests/_Layout/InterestDetailsLayout';
import { MdOutlineLiveTv } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const TVShowDetails = () => {
    const {
        name,
        approval_status,
        averageRuntime,
        year,
        status,
        originalCountry,
        originalLanguage,
        image,
        overview,
        score,
        firstAired,
        lastAired,
    } = useSelector(businessFormSelector);

    const { id } = useParams();

    const tags = useSelector(businessCategoriesSelector);

    const detailItems = [
        {
            label: 'Show Status',
            value: status?.name,
        },
        {
            label: 'Release Year',
            value: year,
        },
        {
            label: 'Country',
            value: originalCountry?.toUpperCase(),
        },
        {
            label: 'Language',
            value: originalLanguage?.toUpperCase(),
        },
        {
            label: 'First Aired',
            value: firstAired ? new Date(firstAired).toLocaleDateString() : 'N/A',
        },
        {
            label: 'Last Aired',
            value: lastAired ? new Date(lastAired).toLocaleDateString() : 'N/A',
        },
    ];

    const scoreChip = (
        <Chip label={`Score: ${score?.toLocaleString()}`} color="primary" variant="outlined" />
    );

    return (
        <InterestDetailsLayout
            entityId={id}
            title={name}
            approvalStatus={approval_status}
            imageSrc={image}
            subtitle={`${year} • ${averageRuntime} minutes per episode`}
            description={overview}
            detailItems={detailItems}
            headerExtras={scoreChip}
            icon={MdOutlineLiveTv}
            tags={tags}
        />
    );
};

export default TVShowDetails;
