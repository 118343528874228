import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { H3 } from 'baseline-ui/typography';
import ModalEditProductContentContainer from './ModalEditProductContentContainer';
import ModalEditProductContentIntroContainer from './ModalEditProductContentIntroContainer';
import { allSectionsSelector } from 'stores/redux/selectors/sectionSelectors';
import ModalEditProductManageSectionsListContainer from './ModalEditProductManageSectionsListContainer';
import { Form } from 'formik';
import { FieldContainer } from 'baseline-ui/form';
import ModalEditProductManageSectionsListItem from './ModalEditProductManageSectionsListItem';
import ModalAddSection from '../modalSection/ModalAddSection';
import { ModalContext } from 'baseline-ui/modal';
import { Button } from 'baseline-ui/button';

const ModalEditProductSections = () => {
    const { showModal } = useContext(ModalContext);

    const allSections = useSelector(allSectionsSelector);
    return (
        <ModalEditProductContentContainer>
            <ModalEditProductContentIntroContainer>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.modal.editProduct.section.sections.title" />
                </H3>
            </ModalEditProductContentIntroContainer>
            <ModalEditProductManageSectionsListContainer>
                <Form noValidate>
                    {allSections.map((section) => {
                        const { name, _id: id } = section;
                        return (
                            <FieldContainer key={id}>
                                <ModalEditProductManageSectionsListItem
                                    productId={id}
                                    sectionName={name}
                                />
                            </FieldContainer>
                        );
                    })}
                </Form>
                <Button
                    btnType="link"
                    size="sm"
                    onClick={() => showModal(ModalAddSection)}
                >
                    <FormattedMessage id="addBusiness.page.sections.productsAndServices.items.addSection.label" />
                </Button>
            </ModalEditProductManageSectionsListContainer>
        </ModalEditProductContentContainer>
    );
};

export default ModalEditProductSections;
