import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import EventList from './EventsList';
import EventInformation from 'screens/Events/EventInformation';
import { GET_EVENT_START } from 'stores/redux/actions/EventActions';
import { useDispatch } from 'react-redux';

const TAB_INDEX = {
    EVENT_LIST: 0,
    ADD_EVENT: 1,
};

const EventsTabs = () => {
    const [activeTab, setActiveTab] = useState(TAB_INDEX.EVENT_LIST);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const dispatch = useDispatch();

    const handleTabChange = (_, newTab) => {
        setActiveTab(newTab);
    };

    const handleFetchEventDetails = (event) => {
        dispatch({ type: GET_EVENT_START, payload: event });
        setSelectedEvent(event);
        setActiveTab(TAB_INDEX.ADD_EVENT);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Event Tabs">
                    <Tab label="Event List" />
                    <Tab label="Add Event" />
                </Tabs>
            </Box>
            <Box>
                {activeTab === TAB_INDEX.EVENT_LIST && (
                    <EventList
                        onFetchEventDetails={handleFetchEventDetails}
                        onAddEvent={() => {
                            setSelectedEvent(null);
                            setActiveTab(TAB_INDEX.ADD_EVENT);
                        }}
                    />
                )}
                {activeTab === TAB_INDEX.ADD_EVENT && (
                    <EventInformation eventDetails={selectedEvent} />
                )}
            </Box>
        </Box>
    );
};

export default EventsTabs;
