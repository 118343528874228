import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  EditOwnBusiness,
  GetBusinessMapping,
  GetOwnedBusiness,
  GetProductItems,
  OwnBusiness,
} from "api";
import {
  ADD_BUSINESS_PRODUCT_PROCESS,
  ADD_BUSINESS_PRODUCT_START,
  ADD_BUSINESS_PRODUCT_SUCCESS,
  GET_BUSINESS_PROCESS,
  GET_BUSINESS_PRODUCTS_PROCESS,
  GET_BUSINESS_PRODUCTS_START,
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_BUSINESS_START,
  GET_BUSINESS_SUCCESS,
  GET_FEATURE_MAPPING_PROCESS,
  GET_FEATURE_MAPPING_START,
  GET_FEATURE_MAPPING_SUCCESS,
  POST_BUSINESS_PROCESS,
  POST_BUSINESS_START,
  RESET_BUSINESS_START,
  SET_PRODUCT_FILTER_PROCESS,
  SET_PRODUCT_FILTER_START,
  SET_PRODUCT_FILTER_SUCCESS,
} from "../actions/OwnABussinessActions";
import genericSagaHandler from "./CommonSaga";

function* getBusinessSaga({ payload }) {
  yield genericSagaHandler(GET_BUSINESS_PROCESS, function* () {
    yield put({
      type: RESET_BUSINESS_START,
    });
    const { business, mapping } = yield GetOwnedBusiness(payload);
    yield put({
      type: GET_BUSINESS_SUCCESS,
      payload: { business, mapping },
    });
  });
}

function* getFeatureMappingSaga() {
  yield genericSagaHandler(GET_FEATURE_MAPPING_PROCESS, function* () {
    const mapping = yield GetBusinessMapping();
    yield put({
      type: GET_FEATURE_MAPPING_SUCCESS,
      payload: mapping,
    });
  });
}

function* postBusinessSaga({ payload }) {
  yield genericSagaHandler(POST_BUSINESS_PROCESS, function* () {
    const { business } = yield select(
      ({ OwnABusinessReducer }) => OwnABusinessReducer,
    );
    if (business && business._id) {
      const {
        advertise,
        advertisementPriority,
        photos,
        businessMappingDeserialized,
        links,
        name,
        ownerFirstName,
        ownerLastName,
        description,
        phone,
        privatephone,
        email,
        position,
        website,
        formattedAddress,
        followupStatus,
        privateCell,
        approval_status,
        originalPhotos,
      } = payload;

      const payloadToSend = {
        business_id: business._id,
        photos: JSON.stringify(
          photos ? photos.filter((photo) => photo.state === "original") : [],
        ),
        photosOrder: JSON.stringify(
          photos
            ? photos.map((photo, index) => {
                return { name: photo.name, order: index };
              })
            : [],
        ),
        name,
        advertise,
        advertisementPriority,
        ownerFirstName,
        ownerLastName,
        description,
        phone,
        privatephone,
        email,
        position,
        website,
        formattedAddress,
        followupStatus,
        privateCell,
        approval_status,
        links: links,
        categories: businessMappingDeserialized,
      };

      const formData = new FormData();
      for (let item in payloadToSend) {
        const value =
          payloadToSend[item] !== undefined ? payloadToSend[item] : "";

        formData.append(item, value);
      }
      if (photos) {
        for (let idx in photos) {
          const currentPhoto = photos[idx];
          if (currentPhoto.state === "new") {
            formData.append(currentPhoto.name, currentPhoto);
          }
        }
      }

      yield EditOwnBusiness(formData);
      yield call(getBusinessSaga, { payload: business._id });
    } else {
      const {
        businessMappingDeserialized,
        name,
        ownerFirstName,
        ownerLastName,
        phone,
        privatephone,
        email,
        formattedAddress,
        followupStatus,
        privateCell,
        approval_status,
        navigate,
        advertise,
        advertisementPriority,
      } = payload;

      const payloadToSend = {
        advertise,
        advertisementPriority,
        tags: businessMappingDeserialized || JSON.stringify([]),
        photos: JSON.stringify([]),
        links: JSON.stringify([]),
        name,
        ownerFirstName,
        ownerLastName,
        phone,
        privatephone,
        email,
        formattedAddress,
        followupStatus,
        privateCell,
        approval_status,
      };

      const formData = new FormData();
      for (let item in payloadToSend) {
        if (payloadToSend[item] !== undefined) {
          formData.append(item, payloadToSend[item]);
        }
      }

      const {
        payload: { id },
      } = yield OwnBusiness(formData);

      navigate(`/own-a-business/edit/${encodeURIComponent(id)}`, {
        replace: true,
      });
    }
  });
}

function* getBusinessProductsSaga({ payload }) {
  yield genericSagaHandler(GET_BUSINESS_PRODUCTS_PROCESS, function* () {
    const { _id } = yield select(
      ({ OwnABusinessReducer }) => OwnABusinessReducer.business,
    );

    const { productFilter } = yield select(
      ({ OwnABusinessReducer }) => OwnABusinessReducer,
    );
    const searchQuery = productFilter ? productFilter : "";
    const products = yield GetProductItems(_id, searchQuery);
    yield put({
      type: GET_BUSINESS_PRODUCTS_SUCCESS,
      payload: products,
    });
  });
}

function* addBusinessProductSaga() {
  yield genericSagaHandler(ADD_BUSINESS_PRODUCT_PROCESS, function* () {
    const { products } = yield select(
      ({ OwnABusinessReducer }) => OwnABusinessReducer,
    ) || [];

    products.push({ item: { _id: "NEW" }, mapping: [] });
    yield put({
      type: ADD_BUSINESS_PRODUCT_SUCCESS,
      payload: products,
    });
  });
}

function* setProductFilterSaga({ payload }) {
  yield genericSagaHandler(SET_PRODUCT_FILTER_PROCESS, function* () {
    yield put({
      type: SET_PRODUCT_FILTER_SUCCESS,
      payload,
    });
  });
}

export default function* OwnABusinessSagas() {
  yield takeEvery(GET_BUSINESS_START, getBusinessSaga);
  yield takeEvery(GET_FEATURE_MAPPING_START, getFeatureMappingSaga);
  yield takeEvery(GET_BUSINESS_PRODUCTS_START, getBusinessProductsSaga);
  yield takeEvery(POST_BUSINESS_START, postBusinessSaga);
  
  yield takeEvery(SET_PRODUCT_FILTER_START, setProductFilterSaga);
  yield takeEvery(ADD_BUSINESS_PRODUCT_START, addBusinessProductSaga);
}
