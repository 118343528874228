import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import FavePagePreview from 'components/FavePagePreview';
import { PREVIEW_MODAL_OFFSET_TOP } from 'common/consts/previewConstants';

const ModalPreviewBusiness = ({ onRequestClose, addToSection, ...props }) => {
    return (
        <Modal
            offsetBottom={0}
            offsetTop={PREVIEW_MODAL_OFFSET_TOP}
            size="sm"
            isContentCover
            customBackgroundColor="transparent"
            handleOnDismiss={onRequestClose}
            isDismissible
            showCloseButton={false}
            isFullWidth
            {...props}
        >
            <FavePagePreview addToSection={addToSection} />
        </Modal>
    );
};

ModalPreviewBusiness.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    addToSection: PropTypes.func,
};
ModalPreviewBusiness.defaultProps = {
    addToSection: () => {},
};

export default ModalPreviewBusiness;
