import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'baseline-ui/typography';
import PageHeaderTitleContainer from './PageHeaderTitleContainer';
import PageHeaderTitleContainerOuter from './PageHeaderTitleContainerOuter';

const PageHeaderTitle = ({ title, icon: Icon }) => {
    return (
        <PageHeaderTitleContainerOuter>
            <H2 color="secondary">
                <PageHeaderTitleContainer>
                    {Icon && <Icon style={{ fontSize: '1.8rem', marginRight: '8px' }} />}
                    {title}
                </PageHeaderTitleContainer>
            </H2>
        </PageHeaderTitleContainerOuter>
    );
};

PageHeaderTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageHeaderTitle;
