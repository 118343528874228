import { ModalContext } from 'baseline-ui/modal';
import { useCallback, useContext, useEffect } from 'react';
import { useBeforeUnload, useBlocker, useNavigate } from 'react-router-dom';
import { POST_BUSINESS_FORM_PROCESS } from 'stores/redux/actions/BusinessFormActions';
import ModalUnsavedChanges from 'screens/BusinessForm/ModalUnsavedChanges';

const useBlockerOnUnsavedChangesBusiness = (doBlock, onSaveCallback) => {
    const navigate = useNavigate();
    const { showModal, isOpen } = useContext(ModalContext);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            doBlock && currentLocation.pathname !== nextLocation.pathname,
    );
    useEffect(() => {
        let navigateOnTimeout;
        if (blocker.state === 'blocked') {
            if (!isOpen) {
                showModal(ModalUnsavedChanges, {
                    blocker,
                    onSaveCallback,
                    onSaveCompleteCallback: () => {
                        const navigateTo = blocker.location.pathname;
                        /** navigateOnTimeout
                         * This is a workaround to the useBlocker issue of
                         * invalid blocker.proceed() being returning an invalid transition between unblocked and proceed state although being in a state of blocked scenario
                         */
                        navigateOnTimeout = setTimeout(() => {
                            blocker.reset();
                            navigate(navigateTo);
                        }, 150);
                    },
                    processNames: [POST_BUSINESS_FORM_PROCESS],
                });
            }
        }
        return () => clearTimeout(navigateOnTimeout);
    }, [blocker, isOpen, navigate]);

    useBeforeUnload(
        useCallback(
            (event) => {
                if (doBlock) {
                    event.preventDefault();
                }
            },
            [doBlock],
        ),
    );
};

export default useBlockerOnUnsavedChangesBusiness;
