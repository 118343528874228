import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { HorizontalScroll } from 'baseline-ui/horizontal-scroll';
import { Button } from 'baseline-ui/button';
import { ThemeContext } from 'styled-components';
import addBusinessSectionsUtils from 'common/utils/addBusinessSectionsUtils';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import FavePageAsideMyBusinessHorizontalScrollContainer from './FavePageAsideMyBusinessHorizontalScrollContainer';
import FavePageAsideMyBusinessHorizontalScrollItemsContainer from './FavePageAsideMyBusinessHorizontalScrollItemsContainer';
import { isAdminSelector } from 'stores/redux/selectors/authenticationSelectors';
import { isPageAdminSelector } from 'stores/redux/selectors/businessAccessRightsSelectors';

const FavePageAsideMyBusinessMobileTablet = ({ onClick, currentSectionId, role }) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);
    const theme = useContext(ThemeContext);
    const isSuperAdmin = useSelector(isAdminSelector);
    const isPageAdmin = useSelector(isPageAdminSelector);

    const addBusinessSections = addBusinessSectionsUtils.renderAddBusinessSections({
        intl,
        type,
        isSuperAdmin: isSuperAdmin,
        isPageAdmin: isPageAdmin,
    });

    if (isEmpty(addBusinessSections)) {
        return null;
    }

    return (
        <FavePageAsideMyBusinessHorizontalScrollContainer>
            <HorizontalScroll
                showScrollbar={false}
                fadeColor={theme.site.admin.bgStart}
                fade={false}
            >
                <FavePageAsideMyBusinessHorizontalScrollItemsContainer>
                    {addBusinessSections.map((section, index) => {
                        const { id, label, renderInSideMenu } = section;
                        return (
                            renderInSideMenu && (
                                <Button
                                    onClick={() => onClick(id, index)}
                                    skin={currentSectionId === id ? 'primary' : 'muted'}
                                    btnType="link"
                                    key={label}
                                >
                                    {label}
                                </Button>
                            )
                        );
                    })}
                </FavePageAsideMyBusinessHorizontalScrollItemsContainer>
            </HorizontalScroll>
        </FavePageAsideMyBusinessHorizontalScrollContainer>
    );
};

FavePageAsideMyBusinessMobileTablet.propTypes = {
    onClick: PropTypes.func.isRequired,
    currentSectionId: PropTypes.string.isRequired,
};

export default FavePageAsideMyBusinessMobileTablet;
