import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import { ThemeContext } from 'styled-components';
import PageHeader from 'components/pageLayout/PageHeader';
import { useIntl } from 'react-intl';
import FavePageAsideMyBusiness from 'components/favePageAside/FavePageAsideMyBusiness';
import useBusinessSectionsNavigation from 'common/hooks/useBusinessSectionsNavigation';
import FavePageMainMyBusiness from 'components/favePageMain/FavePageMainMyBusiness';
import NavigationFooter from 'components/NavigationFooter';
import { ModalContext } from 'baseline-ui/modal';
import useProcessLoader from 'common/hooks/useProcessLoader';
import {
    BUSINESS_APPROVAL_STATUS_APPROVED,
    BUSINESS_APPROVAL_STATUS_PENDING,
    EDIT_BUSINESS_ACTION_SUBMIT,
    EDIT_BUSINESS_ACTION_UPDATE,
} from 'common/consts/consts';
import useProcessComplete from 'common/hooks/useProcessComplete';
import useProcessError from 'common/hooks/useProcessError';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import { currentUserIdSelector } from 'stores/redux/selectors/authenticationSelectors';
import { get } from 'lodash';
import AdminBusinessStatus from 'components/favePageMain/admin/AdminBusinessStatus';
import useEditBusinessValid from 'common/hooks/useEditBusinessValid';
import useAddBusinessValid from 'common/hooks/useAddBusinessValid';
import useBlockerOnUnsavedChangesBusiness from 'common/hooks/useBlockerOnUnsavedChangesBusiness';
import {
    GET_SECTIONS_RESET,
    GET_SECTIONS_START,
    GET_SECTION_PRODUCTS_START,
} from 'stores/redux/actions/SectionActions';
import {
    RESET_PRODUCT_TO_EDIT_START,
    SAVE_PRODUCTS_BULK_PROCESS,
} from 'stores/redux/actions/EditProductActions';
import {
    GET_BUSINESS_FORM_PROCESS,
    GET_BUSINESS_FORM_START,
    POST_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    RESET_BUSINESS_FORM_START,
} from 'stores/redux/actions/BusinessFormActions';
import CommonWrapper from './CommonWrapper';
import { isDraftlikeState } from 'common/utils/businessUtils';
import ModalEditBusinessConfirmSubmit from './BusinessForm/ModalEditBusinessConfirmSubmit';
import ModalPreviewBusiness from './BusinessForm/ModalPreviewBusiness';
import ModalSaveBusinessConfirmSubmit from './BusinessForm/ModalSaveBusinessConfirmSubmit';
import ModalUnsavedChangesSection from './BusinessForm/ModalUnsavedChangesSection';
import ModalSaveEvent from './Events/ModalSaveEvent';
import { ADD_EVENT_START } from 'stores/redux/actions/EventActions';
import useAddEventValid from 'common/hooks/useAddEventValid';
import ModalEditBusinessConfirmUpdate from './BusinessForm/ModalEditBusinessConfirmUpdate';
import { GET_PAGE_ACCESS_RIGHTS_USER_START } from 'stores/redux/actions/BusinessAccessRightsActions';
import FavePageStepper from './BusinessForm/FavePageStepper';
import useFavePageStepper from 'common/hooks/useFavePageStepper';

const EditBusiness = () => {
    const { id } = useParams();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { state } = useLocation();
    let url = new URL(window.location);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location.search);
    const activeSection = urlParams.get('section');
    const processCompleteWithFeedbackFromNavigation = get(
        state,
        'processCompleteWithFeedback',
        true,
    );
    const theme = useContext(ThemeContext);
    const { showModal } = useContext(ModalContext);
    const {
        name,
        approval_status: approvalStatus,
        hasUnsavedChanges,
    } = useSelector(businessFormSelector);
    const eventHasUnsavedChanges = useSelector((state) => state.EventReducer?.hasUnsavedChanges);
    const userId = useSelector(currentUserIdSelector);

    const { steps, getFirstInvalidStep } = useFavePageStepper();
    const basicInformationStepsCount = steps.length;

    const [loadingByStatus, setLoadingByStatus] = useState();

    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
        withOverlay: true,
    });

    const isLoadingBusinessForm = useProcessLoader({
        processNames: [GET_BUSINESS_FORM_PROCESS],
    });

    useProcessComplete({
        processNames: [POST_BUSINESS_FORM_PROCESS, SAVE_PRODUCTS_BULK_PROCESS],
        withFeedback: processCompleteWithFeedbackFromNavigation,
    });
    useProcessError({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: true });

    const isSubmitValid = useEditBusinessValid();

    const { isSaveValid, isAllStepsValid } = useAddBusinessValid();
    const isEventSubmitValid = useAddEventValid();
    useEffect(() => {
        if (id) {
            dispatch({ type: GET_BUSINESS_FORM_START, payload: id });
            dispatch({
                type: GET_SECTIONS_START,
                payload: { entityId: id },
            });
            dispatch({
                type: GET_SECTION_PRODUCTS_START,
                payload: {
                    businessId: id,
                },
            });
            dispatch({
                type: GET_PAGE_ACCESS_RIGHTS_USER_START,
                payload: {
                    entityid: id,
                    userid: userId,
                },
            });
        }
    }, [id]);

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_SECTIONS_RESET,
            });
            dispatch({
                type: RESET_PRODUCT_TO_EDIT_START,
            });
            dispatch({
                type: RESET_BUSINESS_FORM_START,
            });
        };
    }, []);

    const handleOnClickSubmitCallback = (submitForApproval) => {
        if (
            !isSubmitValid &&
            (approvalStatus === BUSINESS_APPROVAL_STATUS_PENDING ||
                approvalStatus === BUSINESS_APPROVAL_STATUS_APPROVED)
        ) {
            return showModal(ModalEditBusinessConfirmUpdate);
        }
        if (!isSubmitValid) {
            return showModal(ModalEditBusinessConfirmSubmit);
        }
        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                submitForApproval,
            },
        });
    };

    const handleOnClickSaveCallback = (submitForApproval) => {
        if (!isSaveValid) {
            return showModal(ModalSaveBusinessConfirmSubmit);
        }
        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                submitForApproval,
            },
        });
    };

    const submitEvent = () => {
        if (!isEventSubmitValid) {
            return showModal(ModalSaveEvent);
        }

        return dispatch({
            type: ADD_EVENT_START,
        });
    };

    const handleOnClickSubmitForApprovalCallback = () => {
        handleOnClickSubmitCallback(true);
    };

    const {
        handleBackNavigation,
        handleNextNavigation,
        activeView,
        setActiveView,
        lastSectionIndex,
    } = useBusinessSectionsNavigation(hasUnsavedChanges, handleOnClickSaveCallback);

    useBlockerOnUnsavedChangesBusiness(hasUnsavedChanges, handleOnClickSaveCallback);

    const { sectionId, sectionIndex } = activeView;

    useEffect(() => {
        if (isDraftlikeState(approvalStatus)) {
            const firstInvalidStep = getFirstInvalidStep();
            const currentSection = urlParams.get('section');
            if (firstInvalidStep && !currentSection) {
                navigate(`${url.pathname}?section=${firstInvalidStep.id}`);
            }
        }
    }, [approvalStatus, getFirstInvalidStep, navigate, url.pathname, urlParams]);
    

    const renderMainFooterActions = useMemo(() => {
        if (!approvalStatus) {
            return null;
        }
        const mainFooterActions = [
            {
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.preview.button.label',
                }),
                skin: 'primary',
                accessibilityLabel: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.preview.button.label',
                }),
                onClick: () =>
                    showModal(ModalPreviewBusiness, {
                        addToSection: (addSectionId) => setActiveView({ sectionId: addSectionId }),
                    }),
            },
        ];

        if (isDraftlikeState(approvalStatus)) {
            mainFooterActions.push({
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.submit.button.label',
                }),
                skin: 'secondary',
                onClick: () =>
                    showModal(ModalEditBusinessConfirmSubmit, {
                        onClickSubmitCallback: () => {
                            handleOnClickSubmitForApprovalCallback();
                            setLoadingByStatus(EDIT_BUSINESS_ACTION_SUBMIT);
                        },
                    }),
                loading: isLoading && loadingByStatus === EDIT_BUSINESS_ACTION_SUBMIT,
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.submitting.button.label',
                }),
            });
        }
        if (
            approvalStatus === BUSINESS_APPROVAL_STATUS_PENDING ||
            approvalStatus === BUSINESS_APPROVAL_STATUS_APPROVED
        ) {
            mainFooterActions.push({
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.update.button.label',
                }),
                skin: 'secondary',
                onClick: () => {
                    handleOnClickSubmitCallback();
                    setLoadingByStatus(EDIT_BUSINESS_ACTION_UPDATE);
                },
                loading: isLoading && loadingByStatus === EDIT_BUSINESS_ACTION_UPDATE,
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.updating.button.label',
                }),
            });
        }

        return mainFooterActions;
    }, [approvalStatus, isLoading, isSubmitValid, isSaveValid]);

    const handleSectionChange = (currentSectionId, currentSectionIndex) => {
        const handleSetActiveView = () => {
            setActiveView({
                sectionId: currentSectionId,
                sectionIndex: currentSectionIndex,
            });
            navigate(`${url.pathname}?section=${currentSectionId}`);
        };

        if (eventHasUnsavedChanges && activeSection === 'EVENTS') {
            return showModal(ModalSaveEvent, {
                onLeave: true,
                onClickSubmitCallback: submitEvent,
                onCompleteCallback: () => handleSetActiveView(),
            });
        }

        if (hasUnsavedChanges && activeSection !== 'EVENTS') {
            return showModal(ModalUnsavedChangesSection, {
                onSaveCallback: handleOnClickSaveCallback,
                onCompleteCallback: () => {
                    handleSetActiveView();
                    dispatch({ type: GET_BUSINESS_FORM_START, payload: id });
                },
            });
        }

        return handleSetActiveView();
    };

    const renderStepper =
        !!activeView?.sectionId && activeView?.sectionIndex <= basicInformationStepsCount - 1;

    return (
        <CommonWrapper>
            <AdminLeftAsideTemplate
                bgStart={theme.site.admin.bgStart}
                bgEnd={theme.site.admin.bgEnd}
                banner={<PageHeader title={name} showStatus={true} />}
                aside={
                    <FavePageAsideMyBusiness
                        currentSectionId={sectionId}
                        onClick={(currentSectionId, currentSectionIndex) => {
                            handleSectionChange(currentSectionId, currentSectionIndex);
                        }}
                    />
                }
                main={
                    <>
                        <AdminBusinessStatus />
                        {renderStepper && !isLoadingBusinessForm && (
                            <FavePageStepper
                                onStepClick={(currentSectionId, currentSectionIndex) =>
                                    handleSectionChange(currentSectionId, currentSectionIndex)
                                }
                                currentStep={activeView?.sectionIndex || 0}
                            />
                        )}
                        {activeSection !== 'EVENTS' && (
                            <NavigationFooter actions={renderMainFooterActions} />
                        )}
                        <FavePageMainMyBusiness
                            currentSectionId={sectionId}
                            handleBackNavigation={handleBackNavigation}
                            disableBackNavigation={sectionIndex === 0}
                            handleNextNavigation={handleNextNavigation}
                            disableNextNavigation={sectionIndex >= basicInformationStepsCount - 1}
                        />
                    </>
                }
            />
        </CommonWrapper>
    );
};

export default EditBusiness;
