import { call, put, takeEvery } from 'redux-saga/effects';
import {
    GetEntityLinkRequests,
    UpdateEntityLinkRequests,
    CreateEntityLinkRequests,
    DeleteEntityLinkRequest,
    ResendEntityLinkRequest,
} from 'api';
import {
    GET_ENTITY_LINKS_TO_ENTITY,
    GET_ENTITY_LINKS_TO_ENTITY_CALL,
    GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
    SEND_ENTITY_LINK_REQUEST,
    SEND_ENTITY_LINK_REQUEST_CALL,
    SEND_ENTITY_LINK_REQUEST_SUCCESS,
    SEND_ENTITY_LINK_REQUEST_ERROR,
    SEND_AND_APPROVE_ENTITY_LINK_REQUEST,
    SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL,
    SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR,
    SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS,
    UPDATE_ENTITY_LINKS_TO_ENTITY,
    UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
    UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS,
    DELETE_ENTITY_LINK,
    DELETE_ENTITY_LINK_CALL,
    DELETE_ENTITY_LINK_ERROR,
    DELETE_ENTITY_LINK_SUCCESS,
    RESEND_ENTITY_LINK_REQUEST,
    RESEND_ENTITY_LINK_REQUEST_CALL,
    RESEND_ENTITY_LINK_REQUEST_ERROR,
    RESEND_ENTITY_LINK_REQUEST_SUCCESS,
    UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR,
} from '../actions/EntityLinkActions';
import genericSagaHandler from './CommonSaga';
import {
    ENTITY_LINK_STATUS_PENDING,
    ENTITY_LINK_STATUS_APPROVED,
} from 'common/consts/entityLinkStatus';

function* getEntityLinksToEntitySaga({ payload }) {
    yield genericSagaHandler(GET_ENTITY_LINKS_TO_ENTITY, function* () {
        const [results] = yield GetEntityLinkRequests(payload);

        yield put({
            type: GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
            payload: {
                ...payload,
                results,
            },
        });
    });
}

function* sendEntityLinkRequest({ payload }) {
    yield genericSagaHandler(SEND_ENTITY_LINK_REQUEST, function* () {
        const { entityRequestedType } = payload;

        const [results, err] = yield CreateEntityLinkRequests({
            entityIdRequested: payload.entityId,
            requestingEntityId: payload.requestingEntityId,
            name: payload.name,
            firstName: payload.firstName,
            lastName: payload.lastName,
            approvalstatus: ENTITY_LINK_STATUS_PENDING,
            message: payload.message,
            position: payload.position,
        });

        if (err) {
            yield put({
                type: SEND_ENTITY_LINK_REQUEST_ERROR,
                payload: {
                    error: err.code,
                },
            });
            return [results, err];
        }

        yield put({
            type: SEND_ENTITY_LINK_REQUEST_SUCCESS,
            payload: {
                entityRequestedType,
                item: results,
            },
        });

        yield call(getEntityLinksToEntitySaga, {
            payload: {
                requestingEntityId: payload.requestingEntityId,
            },
        });

        return [results, err];
    });
}

function* updateEntityLinksToEntitySaga({ payload }) {
    yield genericSagaHandler(UPDATE_ENTITY_LINKS_TO_ENTITY, function* () {
        const { entityLinkRequestsId, approvalstatus } = payload;
        const [results, err] = yield UpdateEntityLinkRequests({
            entityLinkRequestsId,
            approvalstatus,
        });

        if (err) {
            yield put({
                type: UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR,
                payload: {
                    error: err.code,
                },
            });
            return [results, err];
        }

        yield put({
            type: UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS,
            payload: {
                ...payload,
                results,
            },
        });

        return [results, err];
    });
}

function* sendAndApproveEntityLinkRequestSaga({ payload }) {
    yield genericSagaHandler(SEND_AND_APPROVE_ENTITY_LINK_REQUEST, function* () {
        const { entityRequestedType } = payload;

        const [sendLinkRequestResults, sendLinkRequestError] = yield CreateEntityLinkRequests({
            entityIdRequested: payload.entityId,
            requestingEntityId: payload.requestingEntityId,
            name: payload.name,
            firstName: payload.firstName,
            lastName: payload.lastName,
            approvalstatus: ENTITY_LINK_STATUS_PENDING,
            message: payload.message,
            position: payload.position,
        });

        if (sendLinkRequestError) {
            yield put({
                type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR,
                payload: {
                    error: sendLinkRequestError.code,
                },
            });

            return;
        }

        const [results, err] = yield UpdateEntityLinkRequests({
            entityLinkRequestsId: sendLinkRequestResults._id,
            approvalstatus: ENTITY_LINK_STATUS_APPROVED,
        });

        if (err) {
            yield put({
                type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR,
                payload: {
                    error: err.code,
                },
            });
            return;
        }

        yield put({
            type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS,
            payload: {
                entityRequestedType,
                item: {
                    ...sendLinkRequestResults,
                    ...results,
                },
            },
        });

        yield call(getEntityLinksToEntitySaga, {
            payload: {
                entityIdRequested: payload.entityId,
            },
        });
    });
}

function* deleteEntityLinksToEntitySaga({ payload }) {
    yield genericSagaHandler(DELETE_ENTITY_LINK, function* () {
        const [result, error] = yield DeleteEntityLinkRequest({ id: payload.id });

        if (error) {
            yield put({
                type: DELETE_ENTITY_LINK_ERROR,
                payload: {
                    error: error.data.message,
                },
            });

            return;
        }

        if (result) {
            yield put({
                type: DELETE_ENTITY_LINK_SUCCESS,
                payload: {
                    id: payload.id,
                    entityType: payload.entityType,
                },
            });

            if (payload.entityIdRequested) {
                yield call(getEntityLinksToEntitySaga, {
                    payload: {
                        entityIdRequested: payload.entityIdRequested,
                        entityRequestingType: payload.entityType,
                    },
                });
            } else if (payload.requestingEntityId) {
                yield call(getEntityLinksToEntitySaga, {
                    payload: {
                        requestingEntityId: payload.requestingEntityId,
                        entityRequestedType: payload.entityType,
                    },
                });
            }
        }
    });
}

function* resendEntityLinksToEntitySaga({ payload }) {
    yield genericSagaHandler(RESEND_ENTITY_LINK_REQUEST, function* () {
        const [result, error] = yield ResendEntityLinkRequest({
            id: payload.id,
            newApprovalStatus: payload.newApprovalStatus,
        });

        if (error) {
            yield put({
                type: RESEND_ENTITY_LINK_REQUEST_ERROR,
                payload: {
                    error: error.data.message,
                },
            });

            return;
        }

        if (result.updatedEntity) {
            yield put({
                type: RESEND_ENTITY_LINK_REQUEST_SUCCESS,
                payload: {
                    entity: result.updatedEntity,
                    entityType: payload.entityType,
                },
            });
        }
    });
}

export default function* EntityLinkToEntitySagas() {
    yield takeEvery(GET_ENTITY_LINKS_TO_ENTITY_CALL, getEntityLinksToEntitySaga);
    yield takeEvery(UPDATE_ENTITY_LINKS_TO_ENTITY_CALL, updateEntityLinksToEntitySaga);
    yield takeEvery(SEND_ENTITY_LINK_REQUEST_CALL, sendEntityLinkRequest);
    yield takeEvery(SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL, sendAndApproveEntityLinkRequestSaga);
    yield takeEvery(DELETE_ENTITY_LINK_CALL, deleteEntityLinksToEntitySaga);
    yield takeEvery(RESEND_ENTITY_LINK_REQUEST_CALL, resendEntityLinksToEntitySaga);
}
