import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import ModalAddCategory from 'components/modalAddCategoriesAndFilters/ModalAddCategory';
import { CONTEXT_ENTITY } from 'common/consts/consts';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';
import AdminBusinessAdvertisingAddItemContainer from './AdminBusinessAdvertisingAddItemContainer';
import AdminBusinessAdvertisingAddItemOuter from './AdminBusinessAdvertisingAddItemOuter';
import DataTable from '../../../../../screens/BusinessForm/DataTable';

const AdminBusinessAdvertisingAddItem = ({ index }) => {
    const { showModal, hideModal } = useContext(ModalContext);
    const { values, setFieldValue } = useFormikContext();

    const onSubmit = (selectedCategories) => {
        setFieldValue(`categoriesAndFilters.${index}.categoryTags`, selectedCategories);
        hideModal();
    };

    const hasNoCategories = isEmpty(values.categoriesAndFilters[index].categoryTags);

    return (
        <AdminBusinessAdvertisingAddItemOuter>
            <AdminBusinessAdvertisingAddItemContainer>
                <Button
                    onClick={() =>
                        showModal(ModalAddCategory, {
                            context: CONTEXT_ENTITY,
                            categories: values.categoriesAndFilters[index].categoryTags,
                            onSubmit,
                        })
                    }
                    icon={hasNoCategories ? 'plus' : 'edit'}
                    size="sm"
                    btnType="outlined"
                >
                    {hasNoCategories ? 'Add' : 'Edit'}
                </Button>

            </AdminBusinessAdvertisingAddItemContainer>
            <DataTable
                data={categoriesAndFiltersUtils.getOrganizedSelectedTableData(values.categoriesAndFilters[index].categoryTags)}
                onRemoveFilter={filterId => {
                    const val = categoriesAndFiltersUtils.removeFilterFromSelected(
                        values.categoriesAndFilters[index].categoryTags,
                        filterId,
                    );

                    setFieldValue(`categoriesAndFilters.${index}.categoryTags`, val);
                }}
                onRemoveSubcategory={subcategoryId => {
                    const val = categoriesAndFiltersUtils.removeSubcategoryFromSelected(
                        values.categoriesAndFilters[index].categoryTags,
                        subcategoryId,
                    );

                    setFieldValue(`categoriesAndFilters.${index}.categoryTags`, val);
                }}
                onRemoveCategory={categoryId => {
                    const val = categoriesAndFiltersUtils.removeCategoryFromSelected(
                        values.categoriesAndFilters[index].categoryTags,
                        categoryId,
                    );

                    setFieldValue(`categoriesAndFilters.${index}.categoryTags`, val);
                }}
            />
        </AdminBusinessAdvertisingAddItemOuter>
    );
};

AdminBusinessAdvertisingAddItem.propTypes = {
    index: PropTypes.number.isRequired,
};

export default AdminBusinessAdvertisingAddItem;
