import {Carousel} from 'baseline-ui/carousel';
import React, {useEffect, useRef, useState} from 'react';
import SlideWrapper from './style-components/SlideWrapper';
import { useSelector } from 'react-redux';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThreeForm from './StepThreeForm';
import StepFourForm from './StepFourForm';
import CongratsCard from './CongratsCard';
import {DEVICE_TYPE_APPLE} from 'common/consts/consts';
import CarouselDotList from 'baseline-ui/carousel/src/components/CarouselDotList';
import {SrLabel} from 'baseline-ui/helpers';
import CarouselDot from 'baseline-ui/carousel/src/components/CarouselDot';

const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const carouselRef = useRef(null);
    const formikRefs = useRef([]);
    const formData = useSelector((state) => state.DownloadFavePageAppFormReducer);
    const formSubmit = useSelector((state) => state.ProcessReducer?.POST_DOWNLOAD_APP_FORM_DATA);
    const isDone = formSubmit === 'SUCCESS' && formData?.isLastSlide;

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.slickNext();
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.slickPrev();
            setCurrentStep(currentStep - 1);
        }
    };
    const handleValidationError = () => {
        if (carouselRef.current && carouselRef.current.innerSlider) {
            carouselRef.current.innerSlider.forceUpdate();
        }
    };

    const handleDotClick = async (i) => {
        if (carouselRef.current) {
            try {
                const errors = await formikRefs.current[currentStep]?.validateForm();
               if(i > currentStep){
                   await formikRefs.current[currentStep]?.submitForm();
               }
                const hasErrors = Object.keys(errors).length > 0;
                if (!hasErrors && i === currentStep + 1) {
                    handleNext();
                }
                else if(i <= currentStep){
                    carouselRef.current.slickGoTo(i);
                    setCurrentStep(i);
                }
                else {
                    formikRefs.current[currentStep]?.setErrors(errors);
                }
            } catch (error) {
                console.error("Validation failed", error);
            }
        }
    };

    useEffect(() => {
        setCurrentStep(0);
    }, [isDone]);

    return (
        <>
            {isDone ? (
               <CongratsCard />
                ) : (
                <Carousel
                    ref={carouselRef}
                    arrows={false}
                    infinite={false}
                    adaptiveHeight={true}
                    swipe={false}
                    initialSlide={currentStep}
                    appendDots={(dots) => {
                        const customDots = dots.map((dot, index) =>
                          <li className={index === currentStep && 'slick-active'}>
                              <CarouselDot onClick={() => handleDotClick(index)}>
                                <SrLabel as="span">{index + 1}</SrLabel>
                            </CarouselDot>
                          </li>
                        )
                        return <CarouselDotList>{customDots}</CarouselDotList>
                    }}
                >
                    <SlideWrapper>
                        <StepOneForm
                            handleNext={handleNext}
                            onValidationError={handleValidationError}
                            formikRef={(el) => (formikRefs.current[0] = el)}
                        />
                    </SlideWrapper>

                    <SlideWrapper>
                        <StepTwoForm
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            onValidationError={handleValidationError}
                            formikRef={(el) => (formikRefs.current[1] = el)}
                        />
                    </SlideWrapper>

                    <SlideWrapper>
                        <StepThreeForm
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            formData={formData}
                            onValidationError={handleValidationError}
                            formikRef={(el) => (formikRefs.current[2] = el)}
                        />
                    </SlideWrapper>
                    {(formData.device && formData.device === DEVICE_TYPE_APPLE && formSubmit === 'SUCCESS') && (
                        <SlideWrapper>
                            <StepFourForm />
                        </SlideWrapper>
                    )
                    }
                </Carousel>
            )}
        </>
    );
}

export default MultiStepForm;