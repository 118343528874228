import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_PROMO_NOTIFICATIONS_START,
    SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START,
} from 'stores/redux/actions/PushActions';
import { Col, Row } from 'baseline-ui/layout';
import { H5, Small } from 'baseline-ui/typography';
import { HorizontalRule } from './favePageAside/FavePageAsideMyBusinessDesktop/components';
import { Button } from 'baseline-ui/button';

const PromotionalNotificationsHistory = ({ businessId, entityIds }) => {
    const dispatch = useDispatch();
    const { notifications, hasNext } = useSelector(
        ({ PromotionNotificationsReducer }) => PromotionNotificationsReducer,
    );

    useEffect(() => {
        dispatch({
            type: GET_PROMO_NOTIFICATIONS_START,
            payload: entityIds,
        });
    }, []);

    return (
        <>
            {notifications?.map((notification) => {
                const notifierName = '';

                const {
                    title,
                    body,
                    created,
                    subjectEntity: { name },
                } = notification;
                const createdDate = new Date(created);

                return (
                    <Col key={`notification_history_entry_${notification.id}`}>
                        <Row>
                            <H5>Title: {title}</H5>
                        </Row>
                        <Row>
                            <Small>Body: {body}</Small>
                        </Row>
                        <Row>
                            <Small>
                                Sent on: <b>{createdDate.toISOString()}</b>
                            </Small>
                        </Row>
                        <Row>
                            <Small>Sent as a promotion for {name}</Small>
                        </Row>
                        <Row>
                            <HorizontalRule />
                        </Row>
                    </Col>
                );
            })}
            {hasNext && (
                <Button
                    btnType="outlined"
                    onClick={() =>
                        dispatch({
                            type: SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START,
                            payload: businessId,
                        })
                    }
                >
                    Load more
                </Button>
            )}
        </>
    );
};

export default PromotionalNotificationsHistory;
