import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import {
    SET_PRODUCT_SECTIONS_START,
    UNSET_PRODUCT_SECTIONS_START,
    UPDATE_PRODUCT_SECTION_START,
} from 'stores/redux/actions/EditProductActions';
import { GET_SECTIONS_START } from 'stores/redux/actions/SectionActions';
import { useParams } from 'react-router-dom';

const ProductSectionForm = () => {
    const { businessId } = useParams();
    const dispatch = useDispatch();
    const { sections } = useSelector(({ SectionReducer }) => SectionReducer);
    const { productToEdit, originalProduct } = useSelector(
        ({ EditProductReducer }) => EditProductReducer,
    );
    const { sectionsFoundIn } = productToEdit || {};

    useEffect(() => {
        if (!sections && businessId) {
            dispatch({
                type: GET_SECTIONS_START,
                payload: {
                    entityId: businessId,
                },
            });
        }
    }, [sections, businessId]);

    return (
        <Grid item container xs={12}>
            <Grid item xs={12} marginBottom={2}>
                <Typography variant="h4">Sections</Typography>
                <Typography variant="body2">
                    You can reference this product in any section
                </Typography>
            </Grid>
            <Grid item container xs={12}>
                {sectionsFoundIn?.map((section, idx) => {
                    if (!section) {
                        return;
                    }
                    return (
                        <Grid
                            container
                            marginTop={1}
                            key={`section_picker_${section?._id}`}
                            flexDirection={'row'}
                        >
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    id="demo-simple-select"
                                    value={section?._id}
                                    label="Section"
                                    select
                                    onChange={(e) => {
                                        dispatch({
                                            type: UPDATE_PRODUCT_SECTION_START,
                                            payload: {
                                                index: idx,
                                                value: e?.target?.value,
                                            },
                                        });
                                    }}
                                >
                                    <MenuItem value={'new'}>Select section name</MenuItem>
                                    {sections?.map((oneSection) => (
                                        <MenuItem
                                            value={oneSection?._id}
                                            key={`section_name_${idx}_${oneSection?._id}`}
                                        >
                                            {oneSection.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={() =>
                                        dispatch({
                                            type: UNSET_PRODUCT_SECTIONS_START,
                                            payload: idx,
                                        })
                                    }
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid item xs={12} marginTop={2}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                        dispatch({
                            type: SET_PRODUCT_SECTIONS_START,
                        });
                    }}
                >
                    Reference this product <br />
                    in another section
                </Button>
            </Grid>
        </Grid>
    );
};
export default ProductSectionForm;
