import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { FormInput, FieldContainer, FormPasswordInput } from 'baseline-ui/form';
import { H2, P } from 'baseline-ui/typography';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import { RESET_PASSWORD_CODE_LENGHT } from 'common/consts/consts';
import AuthFormContainer from './AuthFormContainer';
import AuthFormSignError from './AuthFormSignError';
import AuthHeadingContainer from './AuthHeadingContainer';
import formResetPasswordSchema from '../schema/formResetPasswordSchema';
import authPropTypes from '../prop-types/authPropTypes';
import ResendRecoveryEmailButtonContainer from './ResendRecoveryEmailButtonContainer';

const FormResetPassword = ({
    onSubmitResetPassword,
    onClickRequestRecoveryEmail,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    appear,
}) => {
    const intl = useIntl();

    const submitResetPassword = (values) => {
        onSubmitResetPassword(values);
    };

    const initialValues = {
        passwordCode: '',
        password: '',
        confirmPassword: '',
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="auth.resetPassword.heading.title" />
            </H2>
            <P>
                <FormattedMessage id="auth.resetPassword.heading.content" />
            </P>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formResetPasswordSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        submitResetPassword(values);
                    }}
                >
                    {() => {
                        return (
                            <Form noValidate>
                                <FieldContainer>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'auth.passwordCode.label',
                                        })}
                                        name="passwordCode"
                                        id="passwordCode"
                                        type="text"
                                        disabled={isLoading}
                                        required
                                        autoComplete="one-time-code"
                                        maxLength={RESET_PASSWORD_CODE_LENGHT.toString()}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <Field
                                        component={FormPasswordInput}
                                        label={intl.formatMessage({
                                            id: 'auth.newPassword.label',
                                        })}
                                        name="password"
                                        id="password"
                                        type="password"
                                        disabled={isLoading}
                                        autoComplete="new-password"
                                        required
                                        preventWhiteSpaces
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <Field
                                        component={FormPasswordInput}
                                        label={intl.formatMessage({
                                            id: 'auth.confirmNewPassword.label',
                                        })}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        type="password"
                                        disabled={isLoading}
                                        autoComplete="new-password"
                                        required
                                        preventWhiteSpaces
                                    />
                                </FieldContainer>
                                <Button
                                    skin={buttonSkin}
                                    btnType={buttonBtnType}
                                    block
                                    type="submit"
                                    loading={isLoading}
                                    loadingMessage={intl.formatMessage({
                                        id: 'auth.resetPassword.loading.label',
                                    })}
                                >
                                    <FormattedMessage id="auth.resetPassword.label" />
                                </Button>
                                <AuthFormSignError errorMessage={errorMessage} />
                            </Form>
                        );
                    }}
                </Formik>
                <ResendRecoveryEmailButtonContainer>
                    <Button btnType="link" size="sm" onClick={onClickRequestRecoveryEmail}>
                        <FormattedMessage id="auth.resetPassword.footer.resendEmail.button.label" />
                    </Button>
                </ResendRecoveryEmailButtonContainer>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormResetPassword.propTypes = {
    onSubmitResetPassword: PropTypes.func.isRequired,
    onClickRequestRecoveryEmail: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    appear: PropTypes.bool,
};

FormResetPassword.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    appear: true,
};

export default FormResetPassword;
