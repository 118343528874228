import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { UL } from 'baseline-ui/typography';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    businessFormRequiredApprovalFieldSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import addBusinessSectionsUtils from 'common/utils/addBusinessSectionsUtils';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalEditBusinessConfirmSubmit = ({ onRequestClose, onClickSubmitCallback, ...props }) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);
    const businessFormRequiredFields = useSelector(businessFormRequiredApprovalFieldSelector);

    const isSubmitValid = addBusinessSectionsUtils.addBusinessHasAllRequiredFields(
        businessFormRequiredFields,
        type,
    );

    const validSubmitFooterActions = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.submit.footerAction.submit.button.label',
            }),
            skin: 'secondary',
            onClick: () => {
                onRequestClose();
                onClickSubmitCallback();
            },
        },
    ];
    const invalidSubmitFooterActions = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.submit.footerAction.continue.button.label',
            }),
            onClick: () => onRequestClose(),
        },
    ];

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.submit.title' })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={isSubmitValid ? validSubmitFooterActions : invalidSubmitFooterActions}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: isSubmitValid
                        ? 'addBusiness.modal.submit.isValid.content.md'
                        : 'addBusiness.modal.submit.isInvalid.content.md',
                })}
            />
            <UL
                list={addBusinessSectionsUtils.addBusinessMissingRequiredFields(
                    businessFormRequiredFields,
                    intl,
                    type,
                )}
            />
        </Modal>
    );
};

ModalEditBusinessConfirmSubmit.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onClickSubmitCallback: PropTypes.func,
};

ModalEditBusinessConfirmSubmit.defaultProps = {
    onClickSubmitCallback: null,
};

export default ModalEditBusinessConfirmSubmit;
