import React from 'react';
import Container from 'components/Container';
import ParagraphWrapper from '../ParagraphWrapper';
import { FormattedMessage } from 'react-intl';
import Label from 'components/Label';
import { LinkButton } from 'baseline-ui/button';
import { ABOUT_PATH } from 'common/consts/pathnames';
import YoutubeVideo from 'components/YoutubeVideo';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';
import { Desktop } from 'baseline-ui/layout';
import FlexWrapper from 'components/FlexWrapper';
import styled from 'styled-components';
import { rem } from 'polished';
import { VIDEO_IDS } from 'common/consts/consts';

const DesktopView = () => {
    return (
        <Desktop>
            <WhatIsFavePageContainer>
                <Container>
                    <FlexWrapper
                        padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        mdGap={0}
                        maxWidth={490}
                    >
                        <FormattedMessage
                            id="homeScreen.whatIsFavePage.title"
                            values={{
                                content: (content) => (
                                    <Label
                                        skin="secondary"
                                        margin={{ bottom: 25 }}
                                        fontSize={48}
                                        fontWeight={700}
                                        textAlign="left"
                                    >
                                        {content}
                                    </Label>
                                ),
                                orange: (text) => <Label skin="primary">{text}</Label>,
                            }}
                        />
                        <ParagraphWrapper>
                            <FormattedMessage
                                id="homeScreen.whatIsFavePage.content"
                                values={{
                                    content: (content) => (
                                        <Label
                                            margin={{ bottom: 25 }}
                                            fontSize={24}
                                            fontWeight={400}
                                            lineHeight={30}
                                        >
                                            {content}
                                        </Label>
                                    ),
                                    orange: (text) => (
                                        <Label skin="primary" fontWeight={600}>
                                            {text}
                                        </Label>
                                    ),
                                    bold: (text) => <Label fontWeight={600}>{text}</Label>,
                                }}
                            />
                        </ParagraphWrapper>
                        <ButtonWrapper width={310} marginY={32} marginX={1} justifyContent="left">
                            <LinkButton block size="lg" to={ABOUT_PATH}>
                                <FormattedMessage id="auth.signUp.intro.button.label" />
                            </LinkButton>
                        </ButtonWrapper>
                    </FlexWrapper>
                </Container>
                <Container>
                    <YoutubeVideo videoId={VIDEO_IDS.WHAT_IS_FAVE_PAGE} />
                </Container>
            </WhatIsFavePageContainer>
        </Desktop>
    );
};

const WhatIsFavePageContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${rem(16)};
    justify-content: center;
    align-items: flex-start;
    padding: ${rem(16)};
    margin: 0 auto ${rem(88)} auto;
    width: 100%;
    max-width: ${rem(1120)};

    & > div {
        flex: 1 1 1;
    }
`;

export default DesktopView;
