import { useMemo } from 'react';
import { Typography, Box, Grid, Avatar } from '@mui/material';

const FeaturedCastSection = ({ characters }) => {
    const featuredActors = useMemo(() => {
        return characters?.filter((char) => char.isFeatured)?.slice(0, 6) || [];
    }, [characters]);

    if (!featuredActors.length) return null;

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="h6"
                gutterBottom
                sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', pb: 1 }}
            >
                Featured Cast
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {featuredActors.map((actor, index) => (
                    <Grid item xs={6} sm={4} md={4} lg={2} key={`actor-${index}`}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                                height: '100%',
                                p: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <Avatar
                                src={actor.personImgURL || ''}
                                alt={actor.personName}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    mr: 2,
                                    border: '1px solid #eee',
                                }}
                            />
                            <Box>
                                <Typography variant="body2" fontWeight="bold">
                                    {actor.personName}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {actor.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FeaturedCastSection; 