import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'baseline-ui/modal';
import { ContentRenderer } from 'baseline-ui/helpers';

const ModalEventConfirmDelete = ({ onRequestClose, confirmDeleteCallback, ...props }) => {
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.page.sections.events.delete.title' })}
            handleOnDismiss={onRequestClose}
            size="sm"
            {...props}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'messagebox.modal.default.buttons.cancel',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => {
                        onRequestClose();
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'messagebox.modal.default.buttons.yes',
                    }),
                    onClick: () => {
                        confirmDeleteCallback();
                        onRequestClose();
                    },
                },
            ]}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: `addBusiness.page.sections.events.delete.message`,
                })}
            />
        </Modal>
    );
};

ModalEventConfirmDelete.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    confirmDeleteCallback: PropTypes.func.isRequired,
};

ModalEventConfirmDelete.defaultProps = {};

export default ModalEventConfirmDelete;
