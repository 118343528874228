import { MobileTablet } from 'baseline-ui/layout';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ParagraphWrapper from '../ParagraphWrapper';
import { LinkButton } from 'baseline-ui/button';
import YoutubeVideo from 'components/YoutubeVideo';
import { ABOUT_PATH } from 'common/consts/pathnames';
import { VIDEO_IDS } from 'common/consts/consts';
import siteBaseTheme from 'styles/siteBaseTheme';

const MobileTabletView = () => {
    return (
        <MobileTablet>
            <FlexWrapper background={siteBaseTheme.base.colors.transparent} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <FormattedMessage
                    id="homeScreen.whatIsFavePage.title"
                    values={{
                        content: (content) => (
                            <Label
                                skin="secondary"
                                margin={{ top: 20 }}
                                fontSize={36}
                                fontWeight={700}
                                lineHeight={50}
                                textAlign="center"
                            >
                                {content}
                            </Label>
                        ),
                        orange: (text) => <Label skin="primary">{text}</Label>,
                    }}
                />
                <ParagraphWrapper>
                    <FormattedMessage
                        id="homeScreen.whatIsFavePage.content"
                        values={{
                            content: (content) => (
                                <Label
                                    display="block"
                                    margin={{ bottom: 10, right: 'auto', left: 'auto' }}
                                    fontSize={16}
                                    fontWeight={500}
                                    lineHeight={25}
                                    maxWidth={330}
                                >
                                    {content}
                                </Label>
                            ),
                            orange: (text) => <Label skin="primary">{text}</Label>,
                            bold: (text) => <Label fontWeight={700}>{text}</Label>,
                        }}
                    />
                </ParagraphWrapper>
            </FlexWrapper>

            <FlexWrapper background={siteBaseTheme.base.colors.transparent} margin={{ top: 0, right: 0, left: 0 }} padding={{ right: 36, left: 36 }}>
                <YoutubeVideo videoId={VIDEO_IDS.WHAT_IS_FAVE_PAGE} />
            </FlexWrapper>

            <FlexWrapper background={siteBaseTheme.base.colors.transparent}
                padding={{ top: 0, right: 36, bottom: 0, left: 36 }}
                margin={{ top: 0, right: 0, bottom: 61, left: 0 }}
                mdGap={0}
                maxWidth={490}
            >
                <LinkButton block size="lg" to={ABOUT_PATH}>
                    <FormattedMessage id="auth.signUp.intro.button.label" />
                </LinkButton>
            </FlexWrapper>
        </MobileTablet>
    );
};

export default MobileTabletView;
