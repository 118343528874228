import { Box, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import useWindowSize from "common/hooks/useWindowSize";
import CategoriesPicker from "../../components/Categories/CategoriesPicker";
import { useDispatch, useSelector } from "react-redux";
import { GET_ITEM_CATEGORIES_START } from "stores/redux/actions/CategoriesActions";
import SelectPicker from "../../components/SelectPicker";
import update from "immutability-helper";
import PrimaryButton from "../../components/PrimaryButton";

const ProductCategoriesFormModal = ({ open, onClose, onSubmit }) => {
  const { height, width } = useWindowSize();
  let { itemCategories } = useSelector(
    ({ CategoriesReducer }) => CategoriesReducer
  );
  // console.log("itemCategories", itemCategories[26].headers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!itemCategories) {
      dispatch({
        type: GET_ITEM_CATEGORIES_START,
      });
    } else {
      setSelectedCategory(itemCategories[0]);
    }
  }, [itemCategories, open]);

  const [selectedCategory, setSelectedCategory] = useState();

  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        rowSpacing={1}
        container
        sx={{
          borderRadius: "20px",
          backgroundColor: "white",
          padding: "36px",
          minHeight: {
            xs: height,
            md: "500px",
          },
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: {
            xs: width,
            md: "400px",
          },
        }}
      >
        <Grid item xs={12}>
          {selectedCategory && (
            <SelectPicker
              value={selectedCategory?.id}
              label="Select category"
              onChange={(value) => {
                if (!value) {
                  return;
                }
                const selectedCategoryObject = itemCategories.find(
                  (category) => category.id === value
                );
                setSelectedCategory({ ...selectedCategoryObject });
              }}
              options={itemCategories?.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
            />
          )}
        </Grid>
        {/* render subcategories coming from selectedCategory -> headers -> subcategories */}
        {selectedCategory &&
          selectedCategory?.headers.map((header, headerIndex) => {
            return (
              <Grid item xs={12} key={`item_header_${header.id}`}>
                <Box>
                  <SelectPicker
                    value={header.subcategories
                      .filter((h) => h.selected)
                      .map((h) => h.id)}
                    multiple
                    label={header.name}
                    options={header.subcategories.map((subcategory) => ({
                      value: subcategory.id,
                      label: subcategory.name,
                    }))}
                    onChange={(value) => {
                      if (!value) {
                        return;
                      }
                      const subcategoryIndex = header.subcategories.findIndex(
                        (subcategory) => subcategory.id === value
                      );
                      const newSelectedCategory = update(selectedCategory, {
                        headers: {
                          [headerIndex]: {
                            subcategories: {
                              [subcategoryIndex]: {
                                selected: {
                                  $set: !header.subcategories[subcategoryIndex]
                                    ?.selected,
                                },
                              },
                            },
                          },
                        },
                      });
                      setSelectedCategory(newSelectedCategory);
                    }}
                  />
                </Box>
              </Grid>
            );
          })}
        <Grid item container xs={12} alignItems={"flex-end"}>
          <PrimaryButton
            onClick={() => {
              onSubmit(selectedCategory);
              onClose();
            }}
            caption={"Add category"}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ProductCategoriesFormModal;
