import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import PhotoListItemContainer from './PhotoListItemContainer';
import PhotoListItemImageContainer from './PhotoListItemImageContainer';
import PhotoListItemOuter from './PhotoListItemOuter';
import { FormRadio } from 'baseline-ui/form';
import { Field } from 'formik';
import { Col, Row } from 'baseline-ui/layout';

const PhotoListItem = ({
    image,
    radioId,
    radioName,
    radioValue,
    radioLabel
}) => {
    return (
        <PhotoListItemOuter>
            <PhotoListItemContainer>
                <PhotoListItemImageContainer>
                    <ProgressiveBg image={image} />
                </PhotoListItemImageContainer>
                {radioValue && (
                    <Row alignItems={'center'}>
                        <Col>
                            <Field
                                component={FormRadio}
                                id={radioId}
                                name={radioName}
                                value={radioValue}
                            >
                                {radioLabel}
                            </Field>
                        </Col>
                    </Row>
                )}
            </PhotoListItemContainer>
        </PhotoListItemOuter>
    );
};

PhotoListItem.propTypes = {
    image: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    radioId: PropTypes.string,
    radioName: PropTypes.string,
    radioValue: PropTypes.string,
    radioLabel: PropTypes.string,
    isCoverPhoto: PropTypes.bool,
};

PhotoListItem.defaultProps = {};

export default PhotoListItem;
