import { useComplete } from 'common/hooks/useComplete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_SESSION_PROCESS, GET_SESSION_START } from 'stores/redux/actions/AuthenticationActions';

const AuthRequired = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const navigate = useNavigate();
    const isSessionComplete = useComplete(GET_SESSION_PROCESS);
    useEffect(() => {
        if (!user) {
            dispatch({ type: GET_SESSION_START, payload: { navigate, redirectRequired: true } });
        }
    }, [user, navigate]);

    if (!isSessionComplete) {
        return <div>Loading...</div>;
    }

    return <>{children}</>;
};

export default AuthRequired;
