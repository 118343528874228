import { useEffect, useRef, useState } from 'react';

export const useColumnHeight = () => {
    const columnRef = useRef(null);
    const [columnHeight, setColumnHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (columnRef.current) {
                const height = columnRef.current.clientHeight;
                setColumnHeight(height);
            }
        };

        updateHeight();

        const resizeObserver = new ResizeObserver(updateHeight);
        if (columnRef.current) {
            resizeObserver.observe(columnRef.current);
        }

        return () => {
            if (columnRef.current) {
                resizeObserver.unobserve(columnRef.current);
            }
        };
    }, []);

    return { columnRef, columnHeight };
};
