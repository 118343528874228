import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_PATH } from 'common/consts/pathnames';
import { BetaSignUp, Login, Logout, SendLoginUsingCookie, Signup, LoginWithToken } from 'api';
import {
    GET_SESSION_PROCESS,
    GET_SESSION_START,
    GET_SESSION_SUCCESS,
    LOGOUT_PROCESS,
    LOGOUT_START,
    LOGOUT_SUCCESS,
    POST_BETA_SIGNUP_PROCESS,
    POST_BETA_SIGNUP_START,
    POST_LOGIN_PROCESS,
    POST_LOGIN_START,
    POST_LOGIN_SUCCESS,
    POST_SIGNUP_PROCESS,
    POST_SIGNUP_START,
    TOKEN_LOGIN_PROCESS
} from '../actions/AuthenticationActions';
import genericSagaHandler from './CommonSaga';
import { getBusinessesSaga } from './BussinessesSagas';

function* postLoginSaga({ payload }) {
    yield genericSagaHandler(POST_LOGIN_PROCESS, function* () {
        const { email, password } = payload;
        const { payload: loginPayload } = yield Login({ email, password });

        yield put({
            type: POST_LOGIN_SUCCESS,
            payload: {
                ...loginPayload,
            },
        });
        yield call(getBusinessesSaga);
    });
}

function* postLoginTokenSaga({ payload }) {
    yield genericSagaHandler(TOKEN_LOGIN_PROCESS, function* () {
        const { payload: loginPayload } = yield LoginWithToken({ token: payload.token });

        yield put({
            type: POST_LOGIN_SUCCESS,
            payload: { 
                ...loginPayload,
            },
        });
        yield call(getBusinessesSaga);
    });
}

function* logoutSaga({ payload }) {
    yield genericSagaHandler(LOGOUT_PROCESS, function* () {
        const { navigate } = payload;
        yield Logout();
        yield put({
            type: LOGOUT_SUCCESS,
        });
        navigate(LOGIN_PATH);
    });
}

function* getSessionSaga({ payload }) {
    yield genericSagaHandler(GET_SESSION_PROCESS, function* () {
        const { redirectRequired } = payload;
        const { navigate } = yield getContext('router');
        if (document.cookie.indexOf('jwt_token=') !== -1) {
            const res = yield SendLoginUsingCookie();
            if (res && !res.errorMessage) {
                yield put({
                    type: GET_SESSION_SUCCESS,
                    payload: {
                        ...res.payload,
                    },
                });
                yield call(postLoginSaga, { payload: { navigate } });
            }
        } else if (redirectRequired) {
            navigate(LOGIN_PATH);
        }
    });
}

function* postSignupSaga({ payload }) {
    yield genericSagaHandler(POST_SIGNUP_PROCESS, function* () {
        const {
            navigate,
            emailAddress,
            confirmEmailAddress,
            password,
            confirmPassword,
            firstName,
            lastName,
        } = payload;
        const payloadToSend = {
            firstName,
            lastName,
            email: emailAddress,
            confirmEmail: confirmEmailAddress,
            password,
            confirmPassword,
        };
        yield Signup(payloadToSend);
        yield call(postLoginSaga, { payload: { navigate, email: emailAddress, password } });
    });
}

function* postBetaSignupSaga({ payload }) {
    yield genericSagaHandler(POST_BETA_SIGNUP_PROCESS, function* handlePostBetaSignup() {
        const {
            dobInputDay,
            dobInputMonth,
            dobInputYear,
            emailAddress,
            firstName,
            lastName,
            phoneNumber,
            referralByBusiness,
            referralByPerson,
            zipCode,
        } = payload;
        const payloadToSend = {
            firstName,
            lastName,
            email: emailAddress,
            phone: phoneNumber,
            zipcode: zipCode,
            referralBusiness: referralByBusiness,
            referralPerson: referralByPerson,
            dob: `${dobInputYear}-${dobInputMonth}-${dobInputDay}`,
        };
        yield BetaSignUp(payloadToSend);
    });
}

export default function* AuthenticationSagas() {
    yield takeEvery(POST_LOGIN_START, postLoginSaga);
    yield takeEvery(LOGOUT_START, logoutSaga);
    yield takeEvery(TOKEN_LOGIN_PROCESS, postLoginTokenSaga);
    yield takeEvery(GET_SESSION_START, getSessionSaga);
    yield takeEvery(POST_SIGNUP_START, postSignupSaga);
    yield takeEvery(POST_BETA_SIGNUP_START, postBetaSignupSaga);
}
