import TitleWrapper from './style-components/TitleWrapper';
import {H5} from 'baseline-ui/typography';
import React from 'react';
import {Button, LinkButton} from 'baseline-ui/button';
import {SET_DOWNLOAD_APP_FORM_DATA} from 'stores/redux/actions/DownloadFavePageAppFormActions';
import {useDispatch} from 'react-redux';
import DownloadSlideWrapper from './style-components/DownloadSlideWrapper';
import FlexWrapper from '../../../../components/FlexWrapper';
import IconWrapper from './style-components/IconWrapper';
import {Image} from 'baseline-ui/helpers';
import {TEST_FLIGHT} from 'assets/images';
import ButtonsWrapper from './style-components/ButtonsWrapper';
import {FormattedMessage} from 'react-intl';

const StepFourForm = () => {
    const dispatch = useDispatch();
    const handleFinish = () =>
    {
        dispatch({
            type: SET_DOWNLOAD_APP_FORM_DATA,
            payload: {isLastSlide: true},
        });
    };
    return(
        <DownloadSlideWrapper>
            <TitleWrapper>
                <H5 color='primary' fontWeight={500}>Step 4</H5>
            </TitleWrapper>
            <FlexWrapper border width={60}>
                <IconWrapper>
                    <Image src={TEST_FLIGHT} width={40} height={40} imageFluid={false} />
                </IconWrapper>
                <TitleWrapper>
                    <H5 fontWeight={600}>
                        <FormattedMessage id='formTestApp.form.fourth.step.text'/>
                    </H5>
                    <ButtonsWrapper width={300}>
                        <LinkButton
                            skin='download'
                            block
                            to='https://apps.apple.com/us/app/testflight/id899247664'
                            target='_blank'
                        >
                            <FormattedMessage id='formTestApp.form.fourth.step.download.button' />
                        </LinkButton>
                    </ButtonsWrapper>
                </TitleWrapper>
            </FlexWrapper>
            <ButtonsWrapper width={300}>
                <Button
                    block
                    onClick={handleFinish}
                >
                    <FormattedMessage id='formTestApp.form.finish.button' />
                </Button>
            </ButtonsWrapper>
        </DownloadSlideWrapper>
    );
}

export default StepFourForm;