import { useState, useCallback } from 'react';

const useDropdownState = () => {
    const [open, setOpen] = useState(false);
    
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    return { open, handleClose, handleOpen };
};

export default useDropdownState; 