import { LinkButton } from 'baseline-ui/button';
import { Mobile } from 'baseline-ui/layout';
import { H1 } from 'baseline-ui/typography';
import { CREATE_FAVEPAGE } from 'common/consts/pathnames';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import siteBaseTheme from 'styles/siteBaseTheme';

const MobileView = () => {
    return (
        <Mobile>
            <FlexWrapper
                background={siteBaseTheme.base.colors.transparent}
                xsGap={0}
                mdGap={0}
                margin={{
                    top: 58,
                    bottom: 75,
                }}
                padding={{
                    top: 0,
                    bottom: 0,
                }}
            >
                <FormattedMessage
                    id="businessScreen.title"
                    values={{
                        h1: (content) => <H1 textAlign="center">{content}</H1>,
                        text: (content) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={50}
                                fontSize={42}
                                fontWeight={800}
                                margin={{
                                    bottom: 25,
                                }}
                            >
                                {content}
                            </Label>
                        ),
                    }}
                />

                <FormattedMessage
                    id="businessScreen.subtitle"
                    values={{
                        text: (text) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={24}
                                fontSize={21}
                                fontWeight={600}
                                margin={{
                                    bottom: 35,
                                    left: 25,
                                    right: 25,
                                }}
                            >
                                {text}
                            </Label>
                        ),
                    }}
                />

                <LinkButton block size="lg" to={CREATE_FAVEPAGE}>
                    <FormattedMessage id="businessScreen.button.createFavePage.label" />
                </LinkButton>
            </FlexWrapper>
        </Mobile>
    );
};

export default MobileView;
