import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import productsAndServicesUtils from 'common/utils/productsAndServicesUtils';
import { truncate } from 'lodash';
import { H5, Small } from 'baseline-ui/typography';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import FavePagePreviewEntityTypesProduct from './FavePagePreviewEntityTypesProduct';

const PRODUCT_CARD_IMAGE_SIZE = 100;
const PRODUCT_CARD_DESCRIPTION_TRUNCATE_LENGTH = 100;

// Note: it uses inline styles and no styled components to put together the item rendered in the preview assuming that none of these elements would be reused.
// Consider moving elements to styled components

const FavePagePreviewEntityTypesProductItem = ({ photos, name, description, totalFaves }) => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);

    return (
        <FavePagePreviewEntityTypesProduct>
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        borderRadius: 8,
                        height: PRODUCT_CARD_IMAGE_SIZE,
                        marginRight: 16,
                        overflow: 'hidden',
                        width: PRODUCT_CARD_IMAGE_SIZE,
                        flexShrink: 0,
                    }}
                >
                    <ProgressiveBg image={productsAndServicesUtils.getSectionItemPhoto(photos)} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <H5>{name}</H5>
                    <Small color="muted">
                        {truncate(description, {
                            length: PRODUCT_CARD_DESCRIPTION_TRUNCATE_LENGTH,
                        })}
                    </Small>
                </div>
            </div>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 16,
                }}
            >
                <div>
                    <Small color="muted">Faved by: {intl.formatNumber(totalFaves)}</Small>
                </div>
                <div style={{ display: 'flex' }}>
                    <GaIcon icon="favepage-heart" color={theme.base.grays.grayLighter} size={18} />
                </div>
            </div>
        </FavePagePreviewEntityTypesProduct>
    );
};

FavePagePreviewEntityTypesProductItem.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
            order: PropTypes.number,
            state: PropTypes.string,
        }),
    ),
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    totalFaves: PropTypes.number.isRequired,
};

FavePagePreviewEntityTypesProductItem.defaultProps = {
    photos: undefined,
    description: undefined,
};

export default FavePagePreviewEntityTypesProductItem;
