import CustomForm from './style-components/CustomForm';
import TitleWrapper from './style-components/TitleWrapper';
import {H3, H5} from 'baseline-ui/typography';
import CardsWrapper from './style-components/CardsWrapper';
import CardBlock from './style-components/CardBlock';
import {Field, Formik} from 'formik';
import {Button} from 'baseline-ui/button';
import CreateFavePageOptionButtonContent from 'components/createFavePage/CreateFavePageOptionButtonContent';
import {GaIcon} from 'baseline-ui/icon';
import {FormattedMessage} from 'react-intl';
import FormInputFeedback from 'baseline-ui/form/src/components/feedback/FormInputFeedback';
import React, {useEffect} from "react";
import formDownloadFavePageApp from 'schema/formDownloadFavePageApp';
import {SET_DOWNLOAD_APP_FORM_DATA} from 'stores/redux/actions/DownloadFavePageAppFormActions';
import {useDispatch} from 'react-redux';
import ButtonsWrapper from './style-components/ButtonsWrapper';
import {DEVICE_TYPE_APPLE, DEVICE_TYPE_GOOGLE} from 'common/consts/consts';
import PropTypes from 'prop-types';

const StepTwoForm = ({ handlePrevious, handleNext, onValidationError, formikRef}) => {
    const dispatch = useDispatch();
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                device: '',
            }}
            validationSchema={formDownloadFavePageApp[1]}
            onSubmit={() => {
                handleNext();
            }}
        >
            {({setFieldValue, errors, values, touched}) => {
                const hasErrors = Object.keys(errors).some((key) => touched[key])
                if (hasErrors) {
                    onValidationError();
                }
                useEffect(() => {
                    dispatch({
                        type: SET_DOWNLOAD_APP_FORM_DATA,
                        payload: values,
                    });
                }, [values, dispatch]);
                return (
                    <CustomForm noValidate>
                        <TitleWrapper>
                            <H5
                                color='primary'
                                fontWeight={500}
                            >
                                <FormattedMessage
                                    id='formTestApp.form.step.number.label'
                                    values={{
                                        number: 2
                                    }}
                                />
                            </H5>
                            <H3 fontWeight={600}>
                                <FormattedMessage id='formTestApp.form.step.two.title' />
                            </H3>
                        </TitleWrapper>
                        <CardsWrapper>
                            <CardBlock>
                                <Field
                                    component={Button}
                                    btnType="card"
                                    skin='primaryDisabled'
                                    onClick={() => setFieldValue('device', DEVICE_TYPE_APPLE)}
                                    block
                                    isActive={values.device === DEVICE_TYPE_APPLE}
                                >
                                    <CreateFavePageOptionButtonContent>
                                        <GaIcon
                                            icon='apple'
                                            size={80}
                                            color='black'
                                        />
                                        <H5 fontWeight={500} textAlign='center' color='muted'>
                                            <FormattedMessage id="formTestApp.form.yourDevice.apple.label" />
                                        </H5>
                                    </CreateFavePageOptionButtonContent>

                                </Field>
                                <FormInputFeedback
                                    feedbackType="error"
                                    message={errors.device}
                                />
                            </CardBlock>
                            <CardBlock>
                                <Field
                                    component={Button}
                                    btnType="card"
                                    skin='primaryDisabled'
                                    onClick={() => setFieldValue('device', DEVICE_TYPE_GOOGLE)}
                                    block
                                    isActive={values.device === DEVICE_TYPE_GOOGLE}
                                >
                                    <CreateFavePageOptionButtonContent>
                                        <GaIcon
                                            icon='google'
                                            size={80}
                                        />
                                        <H5 fontWeight={500} textAlign='center' color='muted'>
                                            <FormattedMessage id="formTestApp.form.yourDevice.google.label" />
                                        </H5>
                                    </CreateFavePageOptionButtonContent>
                                </Field>
                                <FormInputFeedback
                                    feedbackType="error"
                                    message={errors.device}
                                />
                            </CardBlock>
                        </CardsWrapper>
                        <ButtonsWrapper width={500}>
                            <Button
                                btnType='outline'
                                block
                                onClick={handlePrevious}
                            >
                               <FormattedMessage id='formTestApp.form.back.button' />
                            </Button>
                            <Button
                                block
                                type='submit'
                            >
                                <FormattedMessage id='formTestApp.form.next.button' />
                            </Button>
                        </ButtonsWrapper>
                    </CustomForm>
                );
            }}
       </Formik>
    );
}
StepTwoForm.propTypes = {
    handlePrevious: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onValidationError: PropTypes.func.isRequired,
};
export default StepTwoForm;