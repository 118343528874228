import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    allCategoriesAndFiltersSelector,
    businessFormSelector,
    getSubcategoryFilters,
    getSubcategoryById,
} from 'stores/redux/selectors/businessFormSelectors';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';
import {
    GET_BUSINESS_CATEGORIES_START,
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    GET_DESTINATION_CATEGORIES_START,
} from 'stores/redux/actions/CategoriesActions';
import CategoriesForm from './CategoriesForm';
import PropTypes from 'prop-types';
import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ENTITY_TYPE_DESTINATION } from 'common/consts/consts';

const BaseCategories = ({ selectedCategories, onSubmit }) => {
    const dispatch = useDispatch();
    const { type } = useSelector(businessFormSelector);
    const businessCategories = useSelector(allCategoriesAndFiltersSelector);
    const destinationCategories = useSelector(
        ({ CategoriesReducer }) => CategoriesReducer.destinationCategories,
    );
    const [formValues, setFormValues] = useState({});
    const tableData = categoriesAndFiltersUtils.getOrganizedSelectedTableData(selectedCategories);
    const [sections, setSections] = useState(() =>
        Object.entries(tableData).flatMap(([categoryId, categoryData]) => {
            // If no subcategories, create a single section for the category
            if (
                !categoryData.subcategories ||
                Object.keys(categoryData.subcategories).length === 0
            ) {
                return [
                    {
                        id: `${categoryId}-base`,
                        categoryId: categoryId,
                        subcategoryId: '',
                        filter: [],
                    },
                ];
            }

            // Create sections for each subcategory
            return Object.entries(categoryData.subcategories).map(
                ([subcategoryId, subcategoryData], index) => {
                    // Create a Map to group filters by header ID
                    const filterGroups = new Map();

                    if (subcategoryData.filters) {
                        subcategoryData.filters.forEach((filter) => {
                            const filterHeader = filter.crumbs?.find(
                                (crumb) => crumb.type === 'header',
                            );
                            if (filterHeader) {
                                const headerId = filterHeader._id;
                                if (!filterGroups.has(headerId)) {
                                    filterGroups.set(headerId, []);
                                }
                                filterGroups.set(headerId, [
                                    ...filterGroups.get(headerId),
                                    `${headerId},${filter.id}`,
                                ]);
                            }
                        });
                    }

                    return {
                        id: `${categoryId}-${subcategoryId}-${index}`,
                        categoryId: categoryId,
                        subcategoryId: subcategoryId,
                        filter: Array.from(filterGroups.values()),
                    };
                },
            );
        }),
    );

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!type) {
            return;
        }

        switch (type) {
            case ENTITY_TYPE_DESTINATION:
                dispatch({ type: GET_DESTINATION_CATEGORIES_START });
                break;

            default:
                dispatch({ type: GET_BUSINESS_CATEGORIES_START });
                break;
        }
    }, [type, dispatch]);

    useEffect(() => {
        if (Object.keys(formValues).length > 0) {
            requestAnimationFrame(() => {
                if (isFirstRender.current) {
                    isFirstRender.current = false;
                    return;
                }
                onSubmitData();
            });
        } else {
            if (Object.keys(formValues).length === 0 && !isFirstRender.current) {
                onSubmitData();
            }
        }
    }, [formValues]);

    const handleAddSection = () => {
        setSections((prev) => [
            ...prev,
            { id: prev.length, categoryId: '', subcategoryId: '', filter: [] },
        ]);
    };

    const handleRemoveSection = (id) => {
        setSections((prev) => prev.filter((section) => section.id !== id));
        setFormValues((prevValues) => {
            const { [id]: removed, ...rest } = prevValues;
            return rest;
        });
    };

    const handleCategoryChange = (sectionId, e, setFieldValue) => {
        const newCategoryId = e.target.value;
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? { ...section, categoryId: newCategoryId, subcategoryId: '' }
                    : section,
            ),
        );
        setFieldValue('category', newCategoryId);
        setFieldValue('subcategory', '');
        setFieldValue('filter', []);
        dispatch({
            type: GET_BUSINESS_SUBCATEGORIES_START,
            payload: { categoryId: newCategoryId },
        });
    };

    const handleSubcategoryChange = (sectionId, e, setFieldValue) => {
        const newSubcategoryId = e.target.value;
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? { ...section, subcategoryId: newSubcategoryId }
                    : section,
            ),
        );
        setFieldValue('subcategory', newSubcategoryId);
        setFieldValue('filter', []);
        if (newSubcategoryId) {
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId: newSubcategoryId },
            });
        }
    };

    const handleFormChange = (sectionId, values) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [sectionId]: { ...prevValues[sectionId], ...values },
        }));
    };

    const getCategories = () => {
        switch (type) {
            case ENTITY_TYPE_DESTINATION:
                return destinationCategories;

            default:
                return businessCategories;
        }
    };

    const onSubmitData = () => {
        const processedCategories = new Map();
        const processedSubcategories = new Map();
        const filtersList = [];

        Object.values(formValues).forEach((section) => {
            if (section.category) {
                const category = getCategories().find((e) => e.id === section.category);
                if (category && !processedCategories.has(category._id)) {
                    processedCategories.set(category._id, category);
                }
            }

            if (section.subcategory) {
                const subcategoryData = searchInSubcategoriesMap(
                    subcategoriesMap,
                    section.subcategory,
                );
                if (
                    subcategoryData?.subcategory &&
                    !processedSubcategories.has(subcategoryData.subcategory._id)
                ) {
                    const subcategory = subcategoryData.subcategory;
                    subcategory.crumbs = [processedCategories.get(section.category)];
                    processedSubcategories.set(subcategory._id, subcategory);
                }
            }

            if (section.filter && Array.isArray(section.filter)) {
                section.filter.forEach((filterGroup) => {
                    if (Array.isArray(filterGroup)) {
                        filterGroup.forEach((item) => {
                            if (!item) return;

                            const [_, filterId] = item.split(',');
                            const { filter, header } =
                                searchInSubcategoryFiltersMap(subcategoryFiltersMap, filterId) ||
                                {};

                            if (filter && header) {
                                const { filters, ...headerWithoutFilters } = header;

                                filter.crumbs = [
                                    processedCategories.get(section.category),
                                    processedSubcategories.get(section.subcategory),
                                    headerWithoutFilters,
                                ].filter(Boolean);

                                filtersList.push(filter);
                            }
                        });
                    }
                });
            }
        });

        const result = [
            ...Array.from(processedCategories.values()),
            ...Array.from(processedSubcategories.values()),
            ...filtersList,
        ];

        onSubmit(result);
    };

    const subcategoriesMap = useSelector((state) =>
        sections.reduce((acc, section) => {
            acc[section.id] = getSubcategoryById(state, section.categoryId);
            return acc;
        }, {}),
    );

    const subcategoryFiltersMap = useSelector((state) =>
        sections.reduce((acc, section) => {
            acc[section.id] = getSubcategoryFilters(
                state,
                section.subcategoryId || section.categoryId,
            );
            return acc;
        }, {}),
    );

    const searchInSubcategoriesMap = (map, subcategoryId) => {
        const subcategoriesArray = Object.entries(map).flatMap(([sectionId, subcategories]) =>
            subcategories.map((subcategory) => ({ sectionId, subcategory })),
        );

        return (
            subcategoriesArray.find(({ subcategory }) => subcategory._id === subcategoryId) || null
        );
    };

    const searchInSubcategoryFiltersMap = (map, filterId) => {
        for (const [_, headers] of Object.entries(map)) {
            for (const header of headers) {
                const foundFilter = header.filters.find((filter) => filter._id === filterId);
                if (foundFilter) {
                    return { filter: foundFilter, header: header };
                }
            }
        }
        return null;
    };

    return (
        <>
            <div>
                {sections.map((section) => {
                    const subcategories = subcategoriesMap[section.id];
                    const subcategoryFilters = subcategoryFiltersMap[section.id];

                    return (
                        <Box key={section.id} mb={3} display="flex" alignItems="center">
                            <Box sx={{ width: '-webkit-fill-available' }}>
                                <CategoriesForm
                                    onSubmit={onSubmitData}
                                    categories={getCategories()}
                                    subcategories={subcategories}
                                    selectedCategories={selectedCategories}
                                    handleCategoryChange={(e, setFieldValue) =>
                                        handleCategoryChange(section.id, e, setFieldValue)
                                    }
                                    handleSubcategoryChange={(e, setFieldValue) =>
                                        handleSubcategoryChange(section.id, e, setFieldValue)
                                    }
                                    categoryId={section.categoryId}
                                    subcategoryId={section.subcategoryId}
                                    subcategoryFilters={subcategoryFilters}
                                    sectionSelectedFilters={section.filter}
                                    onFormChange={(values) => handleFormChange(section.id, values)}
                                />
                            </Box>
                            {sections.length > 0 && (
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => handleRemoveSection(section.id)}
                                    sx={{ marginLeft: 2 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    );
                })}
                <Box display="flex" justifyContent="space-between" gap={2}>
                    <Button
                        type="button"
                        variant="contained"
                        color="warning"
                        sx={{ borderRadius: '25px' }}
                        onClick={handleAddSection}
                    >
                        Add New Category
                    </Button>
                </Box>
            </div>
        </>
    );
};

BaseCategories.propTypes = {
    selectedCategories: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default BaseCategories;
