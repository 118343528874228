import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Small } from 'baseline-ui/typography';
import EntityLinkRequestSentItem from './EntityLinkRequestSentItem';
import EntityLinkActionModal from '../EntityLinkActionModal';
import { ModalContext } from 'baseline-ui/modal';
import {
    ENTITY_LINK_STATUS_APPROVED,
    ENTITY_LINK_STATUS_DENIED,
    ENTITY_LINK_STATUS_PENDING,
} from 'common/consts/entityLinkStatus';
import { ENTITY_LINK_ACTION_MODAL_MODES } from 'common/consts/consts';

const EntityLinkRequestSentList = (props) => {
    const { items, entityType } = props;
    const { showModal } = useContext(ModalContext);

    const onLinkRemoveHandler = (item) => {
        showModal(EntityLinkActionModal, {
            mode: ENTITY_LINK_ACTION_MODAL_MODES.REMOVE,
            entityLink: item,
            entityType,
        });
    };

    const onLinkResendHandler = (item) => {
        showModal(EntityLinkActionModal, {
            mode: ENTITY_LINK_ACTION_MODAL_MODES.RESEND,
            entityLink: item,
            entityType,
        });
    };

    const { requests, currentLinks, denied } = useMemo(() => {
        const requests = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_PENDING);
        const currentLinks = items.filter(
            (item) => item.approvalstatus === ENTITY_LINK_STATUS_APPROVED,
        );
        const denied = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_DENIED);
        return { requests, currentLinks, denied };
    }, [items]);

    const renderSection = (titleId, sectionItems, emptyMessageId) => (
        <>
            <h3>
                <FormattedMessage id={titleId} />
            </h3>
            {sectionItems.length ? (
                sectionItems.map((item) => (
                    <EntityLinkRequestSentItem
                        data={item}
                        key={item._id}
                        onRemove={onLinkRemoveHandler}
                        onResend={onLinkResendHandler}
                    />
                ))
            ) : (
                <Small>
                    <FormattedMessage id={emptyMessageId} />
                </Small>
            )}
        </>
    );

    return (
        <div>
            {renderSection(
                'addBusiness.page.sections.alltypes.pending.title',
                requests,
                'addBusiness.page.sections.alltypes.pending.empty',
            )}
            {renderSection(
                'addBusiness.page.sections.alltypes.accepted.title',
                currentLinks,
                'addBusiness.page.sections.alltypes.accepted.empty',
            )}
            {renderSection(
                'addBusiness.page.sections.alltypes.denied.title',
                denied,
                'addBusiness.page.sections.alltypes.accepted.empty',
            )}
        </div>
    );
};

export default EntityLinkRequestSentList;
