import React from 'react';
import { useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import addBusinessRequiredInformationSchema from 'schema/addBusinessRequiredInformationSchema';
import AddBusinessRequiredInformationForm from './AddBusinessRequiredInformationForm';

const BusinessRequiredInformation = () => {
    const basicInformation = useSelector(businessFormSelector);

    const userInfo = useSelector(({ AuthenticationReducer }) => {
        const { firstName, lastName, email } = AuthenticationReducer || {};
        return { firstName, lastName, email };
    });

    const {
        name,
        ownerFirstName,
        ownerLastName,
        website,
        position,
        phone,
        privatephone,
        email,
        formattedAddress,
        type,
        showAddress,
        isOnlineStore
    } = basicInformation;

    const initialValues = {
        name,
        ownerFirstName: ownerFirstName || userInfo.firstName,
        ownerLastName: ownerLastName || userInfo.lastName,
        website,
        position,
        phone,
        privatephone,
        email: email || userInfo.email,
        formattedAddress,
        searchBusinessAddress: '',
        showAddress: showAddress,
        isOnlineStore,
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id={`${type}.page.sections.requiredInformation.title`} />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.requiredInformation.subtitle`} />
            </P>
            <Formik
                initialValues={initialValues}
                validationSchema={addBusinessRequiredInformationSchema(type)}
            >
                {() => <AddBusinessRequiredInformationForm />}
            </Formik>
        </>
    );
};

export default BusinessRequiredInformation;
