import React from 'react';
import PropTypes from 'prop-types';
import { SortableItem } from 'react-easy-sort';
import { isEmpty } from 'lodash';
import PhotoListItemSortableItem from './PhotoListItemSortableItem';
import PhotoListItem from './PhotoListItem';
import { Box } from '@mui/system';
import { H6 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import PhotoListItemFooter from './PhotoListItem/PhotoListItemFooter';
import { Button } from 'baseline-ui/button';

const PhotoListSortable = ({ photos, onClickDelete, onClickEdit, onClickRestoreOriginal, isInOverlay }) => {
    if (isEmpty(photos)) {
        return null;
    }

    return photos.map((photo, index) => {
        const isCoverPhoto = index === 0;

        let image = photo.useCropped ? photo.updatedImage?.preview || photo.updatedImage?.url : photo.originalImage?.preview || photo.originalImage?.url || photo.preview || photo.url;
        if (!image) {
            image = photo.preview || photo.url;
        }
        return (
            <Box
                key={photo.preview || photo.url || `photo-${index}`}
                sx={{
                    boxShadow: isCoverPhoto
                        ? '0 0 0 5px orange'
                        : 'rgba(0, 0, 0, 0.125) 0px 0.125rem 0.25rem',
                    borderRadius: '0.5rem',
                    padding: '0.25rem',
                }}
            >
                <SortableItem>
                    <PhotoListItemSortableItem isInOverlay={isInOverlay}>
                        <PhotoListItem
                            image={image}
                        />
                    </PhotoListItemSortableItem>
                </SortableItem>

                {(onClickEdit || onClickDelete || isCoverPhoto) && (
                    <PhotoListItemFooter>
                        {isCoverPhoto && (
                            <H6 className="cover-photo" color="primary">
                                <FormattedMessage id="addBusiness.page.sections.photos.coverPhoto.label" />
                            </H6>
                        )}
                        <div className="button-group">
                            {onClickEdit && (
                                <Button
                                    btnType="link"
                                    icon="edit"
                                    size="sm"
                                    onClick={() => onClickEdit(photo, index)}
                                />
                            )}
                            {onClickDelete && (
                                <Button
                                    btnType="link"
                                    icon="x"
                                    size="sm"
                                    onClick={() => onClickDelete(photo)}
                                />
                            )}
                            {onClickRestoreOriginal && photo.useCropped && (
                                <Button
                                    btnType="link"
                                    icon="refresh-cw"
                                    size="sm"
                                    onClick={() => onClickRestoreOriginal(photo, index)}
                                />
                            )}
                        </div>
                    </PhotoListItemFooter>
                )}
            </Box>
        );
    });
};

PhotoListSortable.propTypes = {
    onClickDelete: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickRestoreOriginal: PropTypes.func,
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
            order: PropTypes.number,
            state: PropTypes.string,
            originalImage: PropTypes.object || null || undefined,
            updatedImage: PropTypes.object || null || undefined,
            useCropped: PropTypes.bool || null || undefined,
        }),
    ).isRequired,
    isInOverlay: PropTypes.bool,
};

PhotoListSortable.defaultProps = {
    isInOverlay: false,
};

export default PhotoListSortable;