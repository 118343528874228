import { createTheme } from '@mui/material/styles';
import siteBaseTheme from './siteBaseTheme';
import siteTypographyTheme from './siteTypographyTheme';
import { formTheme } from '../baseline-ui/form';
import { rem } from 'polished';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: siteBaseTheme.base.colors.primary,
        },
    },
    typography: {
        fontFamily: siteTypographyTheme.typography.base.fontFamily,
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontSize: formTheme.form.input.fontSize,
                    padding: rem(16),
                    borderRadius: formTheme.form.input.borderRadius,
                    borderColor: siteBaseTheme.base.grays.grayLightest,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: siteBaseTheme.base.colors.light,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    },
                    '&:hover, &.Mui-focused': {
                        borderColor: siteBaseTheme.base.colors.primary,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    },
                },
                select: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontWeight: '400',
                    fontSize: formTheme.form.input.fontSize,
                    '&:focus': {
                        backgroundColor: siteBaseTheme.base.colors.light,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.selectLabelItem': {
                        fontFamily: siteTypographyTheme.typography.base.fontFamily,
                        fontSize: formTheme.form.label.fontSizeFocus,
                        paddingLeft: rem(16),
                        paddingTop: rem(4),
                        paddingBottom: rem(4),
                        textTransform: 'capitalize',
                        position: 'absolute',
                        zIndex: '1',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontWeight: '400',
                    fontSize: formTheme.form.input.fontSize,
                    '&.stickyMenuItem': {
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: siteBaseTheme.base.colors.light,
                        zIndex: 1,
                    },
                    '& .MuiButton-root': {
                        color: siteBaseTheme.base.colors.primary,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: siteBaseTheme.base.colors.secondary,
                    '&.Mui-checked': {
                        color: siteBaseTheme.base.colors.primary,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
                        fontFamily: siteTypographyTheme.typography.base.fontFamily,
                        fontSize: rem(15),
                        paddingLeft: rem(4),
                        paddingTop: rem(16),
                        paddingBottom: rem(4),
                        textTransform: 'capitalize',
                        position: 'absolute',
                        zIndex: '1',
                        '&.Mui-focused': {
                            color: siteBaseTheme.base.colors.primary,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        height: rem(54),
                        borderRadius: formTheme.form.input.borderRadius,
                        borderColor: siteBaseTheme.base.grays.grayLightest,
                        color: siteBaseTheme.base.colors.dark,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        fontFamily: siteTypographyTheme.typography.base.fontFamily,
                        fontSize: formTheme.form.input.fontSize,
                        '&:hover': {
                            borderColor: siteBaseTheme.base.colors.primary,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                        },
                        '&.Mui-focused': {
                            borderColor: siteBaseTheme.base.colors.primary,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        fieldset: {
                            borderWidth: '0',
                        },
                        '& .MuiOutlinedInput-input': {
                            paddingTop: rem(24),
                            paddingBottom: rem(6),
                        },
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: siteBaseTheme.base.colors.primary,
                    },
                    '& .MuiTab-root': {
                        textTransform: 'none',
                        color: siteBaseTheme.base.colors.dark,
                        '&.Mui-selected': {
                            color: siteBaseTheme.base.colors.primary,
                        },
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: siteBaseTheme.base.colors.primary,
                    },
                    '&.Mui-disabled': {
                        color: siteBaseTheme.base.grays.grayLightest,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: siteBaseTheme.base.borderRadius.lg,
                },
            },
        },
    },
});

export default muiTheme;
