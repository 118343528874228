import { Box, Chip, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import InterestDetailsLayout from 'screens/Interests/_Layout/InterestDetailsLayout';
import { FaPodcast } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

const PodcastDetails = () => {
    const {
        name,
        approval_status,
        description,
        publisher,
        languages,
        total_episodes,
        external_urls,
        images,
        media_type,
        explicit,
    } = useSelector(businessFormSelector);

    const { id } = useParams();
    const tags = useSelector(businessCategoriesSelector);

    const coverImage = images?.[0]?.url;

    const languagesValue = languages?.length ? (
        <Box display="flex" gap={1} flexWrap="wrap">
            {languages.map((lang) => (
                <Chip key={lang} label={lang.toUpperCase()} size="small" variant="outlined" />
            ))}
        </Box>
    ) : (
        'N/A'
    );

    const detailItems = [
        {
            label: 'Total Episodes',
            value: total_episodes?.toLocaleString(),
        },
        {
            label: 'Media Type',
            value: media_type,
            sx: { textTransform: 'capitalize' },
        },
        {
            label: 'Languages',
            value: languagesValue,
        },
    ];

    if (external_urls?.spotify) {
        detailItems.push({
            value: (
                <Link
                    href={external_urls.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                >
                    <Chip label="Listen on Spotify" color="primary" clickable />
                </Link>
            ),
        });
    }

    const explicitChip = explicit ? (
        <Chip label="Explicit" color="error" variant="outlined" size="small" />
    ) : null;

    return (
        <InterestDetailsLayout
            entityId={id}
            title={name}
            approvalStatus={approval_status}
            imageSrc={coverImage}
            subtitle={`By ${publisher}`}
            description={description}
            detailItems={detailItems}
            headerExtras={explicitChip}
            icon={FaPodcast}
            tags={tags}
        />
    );
};

export default PodcastDetails;
