import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContext } from 'baseline-ui/modal';
import useProcessComplete from 'common/hooks/useProcessComplete';
import {
    POST_BUSINESS_FORM_PROCESS,
    SET_BUSINESS_FORM_DATA_START
} from 'stores/redux/actions/BusinessFormActions';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import {useDispatch, useSelector} from 'react-redux';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import { BUSINESS_APPROVAL_STATUS_DRAFT } from 'common/consts/consts';

const ModalUnsavedChangesSection = ({
    onRequestClose,
    onSaveCallback,
    onCompleteCallback,
    ...props
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { hideModal } = useContext(ModalContext);

    const isComplete = useProcessComplete({
        processNames: [POST_BUSINESS_FORM_PROCESS],
    });
    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
    });

    const { approval_status: approvalStatus } = useSelector(businessFormSelector);

    useEffect(() => {
        if (isComplete) {
            hideModal();
            onCompleteCallback();
        }
    }, [isComplete]);

    const submitButtonLabelByApprovalStatus = useMemo(() => {
        if (approvalStatus === BUSINESS_APPROVAL_STATUS_DRAFT) {
            return {
                label: intl.formatMessage({
                    id: 'addBusiness.modal.unsavedChangesSection.footerAction.save.button.label',
                }),
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.modal.unsavedChangesSection.footerAction.saving.button.label',
                }),
            };
        }
        return {
            label: intl.formatMessage({
                id: 'addBusiness.modal.unsavedChangesSection.footerAction.update.button.label',
            }),
            loadingMessage: intl.formatMessage({
                id: 'addBusiness.modal.unsavedChangesSection.footerAction.updating.button.label',
            }),
        };
    }, [approvalStatus]);

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.unsavedChangesSection.title' })}
            handleOnDismiss={() => onRequestClose()}
            size="sm"
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.unsavedChangesSection.footerAction.leave.button.label',
                    }),
                    skin: 'muted',
                    btnType: 'link',
                    onClick: () => {
                        onRequestClose();
                        onCompleteCallback();
                        dispatch({
                            type: SET_BUSINESS_FORM_DATA_START,
                            payload: {
                                hasUnsavedChanges: false
                            }
                        });
                    },
                },
                {
                    ...submitButtonLabelByApprovalStatus,
                    skin: 'secondary',
                    onClick: () => {
                        onSaveCallback();
                    },
                    loading: isLoading,
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: 'addBusiness.modal.unsavedChangesSection.content.md',
                })}
            />
        </Modal>
    );
};

ModalUnsavedChangesSection.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onCompleteCallback: PropTypes.func.isRequired,
    onSaveCallback: PropTypes.func.isRequired,
};

export default ModalUnsavedChangesSection;
