import React, { useEffect } from 'react';
import { ModalProvider } from 'baseline-ui/modal';
import { Outlet, useNavigate } from 'react-router-dom';
import theme from 'styles/theme';
import SiteIntlProvider from 'components/SiteIntlProvider';
import { BaselineThemeProvider } from 'baseline-ui/theme-provider';
import { ProcessOverlayProvider } from 'baseline-ui/processOverlay';
import { FeedbackProvider } from 'baseline-ui/feedback';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SESSION_START } from 'stores/redux/actions/AuthenticationActions';
import { OverlayProvider } from 'baseline-ui/overlay';
import muiTheme from 'styles/muiTheme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const RouteElementWrapper = () => {
    const dispatch = useDispatch();
    const user = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const navigate = useNavigate();
    useEffect(() => {
        if (!user) {
            dispatch({ type: GET_SESSION_START, payload: { navigate } });
        }
    }, [user, navigate]);

    return (
        <SiteIntlProvider>
            <BaselineThemeProvider theme={theme}>
                <MuiThemeProvider theme={muiTheme}>
                    <ProcessOverlayProvider>
                        <FeedbackProvider>
                            <ModalProvider>
                                <OverlayProvider>
                                    <Outlet />
                                </OverlayProvider>
                            </ModalProvider>
                        </FeedbackProvider>
                    </ProcessOverlayProvider>
                </MuiThemeProvider>
            </BaselineThemeProvider>
        </SiteIntlProvider>
    );
};

export default RouteElementWrapper;
