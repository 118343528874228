import { call, put, select, takeEvery } from 'redux-saga/effects';
import { allSectionsSelector } from 'stores/redux/selectors/sectionSelectors';
import {
    GetBusinessSectionItems,
    GetBusinessSections,
    PutBusinessSections,
    getSectionEntities,
} from 'api';
import {
    RESET_PRODUCT_TO_EDIT_START,
    SET_PRODUCT_TO_EDIT_SUCCESS,
    SET_SHOW_ACTIVE_PRODUCTS_ONLY_PROCESS,
    SET_SHOW_ACTIVE_PRODUCTS_ONLY_START,
    SET_SHOW_ACTIVE_PRODUCTS_ONLY_SUCCESS,
} from '../actions/EditProductActions';
import {
    ADD_SINGLE_SECTION_PROCESS,
    ADD_SINGLE_SECTION_START,
    ADD_SINGLE_SECTION_SUCCESS,
    SAVE_SINGLE_SECTION_START,
    SET_SELECTED_SECTION_START,
    UPDATE_SECTION_PROCESS,
    UPDATE_SECTION_START,
    UPDATE_SECTION_SUCCESS,
    GET_SECTIONS_PROCESS,
    GET_SECTIONS_START,
    GET_SECTIONS_SUCCESS,
    GET_SECTION_PRODUCTS_PROCESS,
    GET_SECTION_PRODUCTS_START,
    GET_SECTION_PRODUCTS_SUCCESS,
    PUT_SECTIONS_PROCESS,
    PUT_SECTIONS_START,
} from '../actions/SectionActions';

import genericSagaHandler from './CommonSaga';

export function* getSectionsSaga({ payload }) {
    yield genericSagaHandler(GET_SECTIONS_PROCESS, function* () {
        const { entityId } = payload;
        const res = yield GetBusinessSections(entityId);

        yield put({
            type: GET_SECTIONS_SUCCESS,
            payload: res,
        });
    });
}

function* putSectionsSaga({ payload }) {
    yield genericSagaHandler(PUT_SECTIONS_PROCESS, function* () {
        const { entityId, sections } = payload;
        const formData = new FormData();
        formData.append('entityId', entityId);
        formData.append('sections', JSON.stringify(sections));
        yield PutBusinessSections({ entityId, sections });
        yield call(getSectionsSaga, { payload: { entityId } });
    });
}

export function* getSectionProductsSaga({ payload }) {
    yield genericSagaHandler(GET_SECTION_PRODUCTS_PROCESS, function* () {
        const { businessId, selectedSection, productToEditId } = payload;
        let { setOriginalSections = true } = payload;
        const { showActiveOnly } = yield select(({ SectionReducer }) => SectionReducer);
        const pageNum = 0;
        const maxCount = 1000;
        // const products = yield GetBusinessSectionItems({
        //     businessId,
        //     maxCount,
        //     pageNum,
        //     selectedSection,
        //     active: showActiveOnly,
        // });
        let products = yield getSectionEntities({
            entityId: businessId,
            maxCount,
            pageNum,
            active: showActiveOnly,
            sectionName: selectedSection,
        });

        const { deletedProducts } = yield select(({ SectionReducer }) => SectionReducer);

        if (productToEditId) {
            products = products.filter((p) => {
                const isDeleted = deletedProducts?.find((dp) => dp === p._id);

                return !isDeleted;
            });
            setOriginalSections = false;
        }

        yield put({
            type: GET_SECTION_PRODUCTS_SUCCESS,
            payload: { selectedSection, products, setOriginalSections },
        });

        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        if (productToEdit && productToEdit._id) {
            const updatedProductToEdit = products.find((p) => p._id === productToEdit._id);
            if (updatedProductToEdit) {
                yield put({
                    type: SET_PRODUCT_TO_EDIT_SUCCESS,
                    payload: updatedProductToEdit,
                });
            }
        }
    });
}

function* setActiveProductsOnlySaga() {
    yield genericSagaHandler(SET_SHOW_ACTIVE_PRODUCTS_ONLY_PROCESS, function* () {
        yield put({
            type: RESET_PRODUCT_TO_EDIT_START,
        });
        const { selectedSection } = yield select(({ SectionReducer }) => SectionReducer);
        const { _id } = yield select(({ BusinessFormReducer }) => BusinessFormReducer);
        yield call(getSectionProductsSaga, {
            payload: { selectedSection, businessId: _id },
        });
    });
}

function* updateSectionSaga({ payload }) {
    yield genericSagaHandler(UPDATE_SECTION_PROCESS, function* () {
        yield put({
            type: UPDATE_SECTION_SUCCESS,
            payload,
        });
    });
}

function* addSingleSectionSaga({ payload }) {
    yield genericSagaHandler(ADD_SINGLE_SECTION_PROCESS, function* () {
        const originalSections = yield select(
            ({ SectionReducer }) => SectionReducer.originalSections,
        );
        originalSections.push(payload);
        const businessId = yield select(({ BusinessFormReducer }) => BusinessFormReducer._id);
        const response = yield PutBusinessSections({
            entityId: businessId,
            sections: originalSections,
        });

        yield put({
            type: ADD_SINGLE_SECTION_SUCCESS,
            payload: { originalSections: response.payload },
        });
        yield put({
            type: SET_SELECTED_SECTION_START,
            payload: payload.name,
        });
    });
}

export default function* SectionSagas() {
    yield takeEvery(GET_SECTIONS_START, getSectionsSaga);
    yield takeEvery(PUT_SECTIONS_START, putSectionsSaga);
    yield takeEvery(GET_SECTION_PRODUCTS_START, getSectionProductsSaga);
    yield takeEvery(SET_SHOW_ACTIVE_PRODUCTS_ONLY_START, setActiveProductsOnlySaga);

    yield takeEvery(UPDATE_SECTION_START, updateSectionSaga);

    yield takeEvery(ADD_SINGLE_SECTION_START, addSingleSectionSaga);
}
