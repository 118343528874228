import { useEffect, useRef } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FieldContainer, FormInput, FormMaskedInput, FormRadio } from 'baseline-ui/form';
import { SET_BUSINESS_FORM_DATA_START } from 'stores/redux/actions/BusinessFormActions';
import useGoogleMapsAutocomplete from 'common/hooks/useGoogleMapsAutocomplete';
import { Col, Row } from 'baseline-ui/layout';
import AuthFieldHeaderContainer from 'baseline-ui/auth/src/components/AuthFieldHeaderContainer';
import { H3, P } from 'baseline-ui/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { MOBILE_PHONE_NUMBER_MASK } from 'common/consts/consts';
import useIsFormDirtyFeedback from 'common/hooks/useIsFormDirtyFeedback';
import { GaIcon } from 'baseline-ui/icon';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import BusinessAddressProblemFindingAddress from 'components/BusinessAddressProblem';
import Label from 'components/Label';

const PersonRequiredInformationForm = () => {
    const { values, dirty, setFieldValue, errors } = useFormikContext();
    const { type } = useSelector(businessFormSelector);

    const dispatch = useDispatch();
    const ref = useRef();
    const intl = useIntl();

    useGoogleMapsAutocomplete(ref);

    useEffect(() => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: values,
        });
    }, [values]);

    useIsFormDirtyFeedback({
        isDirty: dirty,
        dependencies: [values],
        processName: SET_BUSINESS_FORM_DATA_START,
    });

    return (
        <Form noValidate>
            <Row>
                <Col xxs={30}>
                    <AuthFieldHeaderContainer>
                        <H3 color="secondary">
                            <FormattedMessage
                                id={`${type}.page.sections.requiredInformation.form.aboutYourBusiness.label`}
                            />
                        </H3>
                        <P>
                            <FormattedMessage id="addBusiness.page.sections.requiredInformation.form.aboutYourBusiness.subtitle" />
                        </P>
                    </AuthFieldHeaderContainer>
                </Col>
                <Col xxs={30}>
                    <Row>
                        <Col xxs={30}>
                            <Row>
                                <Col xxs={30} xl={15}>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: `${type}.form.ownerFirstName.label`,
                                            })}
                                            name="ownerFirstName"
                                            id="ownerFirstName"
                                            type="text"
                                            required
                                            autoComplete="off"
                                        />
                                    </FieldContainer>
                                </Col>
                                <Col xxs={30} xl={15}>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: `${type}.form.ownerLastName.label`,
                                            })}
                                            name="ownerLastName"
                                            id="ownerLastName"
                                            type="text"
                                            required
                                            autoComplete="off"
                                        />
                                    </FieldContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxs={30}>
                            <Row>
                                <Col xxs={30} xl={19}>
                                    <FieldContainer>
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: `${type}.form.position.label`,
                                            })}
                                            name="position"
                                            id="position"
                                            type="text"
                                            required
                                            autoComplete="off"
                                        />
                                    </FieldContainer>
                                </Col>
                                <Col xxs={30} xl={11}>
                                    <FieldContainer>
                                        <Field
                                            component={FormMaskedInput}
                                            label={intl.formatMessage({
                                                id: `${type}.form.phone.label`,
                                            })}
                                            name="phone"
                                            id="phone"
                                            mask={MOBILE_PHONE_NUMBER_MASK}
                                            required
                                            autoComplete="off"
                                            onAccept={(value, mask) => {
                                                mask.updateValue();
                                                setFieldValue('phone', value);
                                            }}
                                            customError={
                                                errors.phone?.includes('validation.mixed.required')
                                                    ? ''
                                                    : errors.phone
                                            }
                                        />
                                    </FieldContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxs={30}>
                            <FieldContainer>
                                <Row>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Col xxs={30} sm={15}>
                                            <FieldContainer>
                                                <Field
                                                    component={FormInput}
                                                    label={intl.formatMessage({
                                                        id: `${type}.form.searchBusinessAddress.label`,
                                                    })}
                                                    name="formattedAddress"
                                                    id="formattedAddress"
                                                    type="text"
                                                    innerRef={ref}
                                                    placeholder=""
                                                    required={false}
                                                    prependAddon={
                                                        <GaIcon icon="search" size={14} />
                                                    }
                                                    autoComplete="off"
                                                    onBlur={() =>
                                                        setFieldValue('searchBusinessAddress', '')
                                                    }
                                                />
                                            </FieldContainer>
                                        </Col>
                                        <BusinessAddressProblemFindingAddress />
                                    </div>
                                    {values.formattedAddress && (
                                        <Col xxs={30}>
                                            <FieldContainer paddingBottom={8}>
                                                <Field
                                                    component={FormRadio}
                                                    name="showAddress"
                                                    id="showFullAddress"
                                                    value="true"
                                                >
                                                    <FormattedMessage
                                                        id={`${type}.form.showAddress.label`}
                                                        values={{
                                                            bold: (text) => (
                                                                <Label fontWeight={700}>
                                                                    {text}
                                                                </Label>
                                                            ),
                                                        }}
                                                    />
                                                </Field>
                                            </FieldContainer>
                                            <FieldContainer>
                                                <Field
                                                    component={FormRadio}
                                                    name="showAddress"
                                                    id="showCity"
                                                    value="false"
                                                >
                                                    <FormattedMessage
                                                        id={`${type}.form.showCity.label`}
                                                        values={{
                                                            bold: (text) => (
                                                                <Label fontWeight={700}>
                                                                    {text}
                                                                </Label>
                                                            ),
                                                        }}
                                                    />
                                                </Field>
                                            </FieldContainer>
                                        </Col>
                                    )}
                                    <Col xxs={30}></Col>
                                </Row>
                            </FieldContainer>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xxs={30}>
                    <AuthFieldHeaderContainer>
                        <H3 color="secondary">
                            <FormattedMessage
                                id={`${type}.page.sections.requiredInformation.form.aboutYou.label`}
                            />
                        </H3>
                        <P>
                            <FormattedMessage id="addBusiness.page.sections.requiredInformation.form.aboutYou.subtitle" />
                        </P>
                    </AuthFieldHeaderContainer>
                </Col>

                <Col xxs={30}>
                    <Row>
                        <Col xxs={30} xl={15}>
                            <FieldContainer>
                                <Field
                                    component={FormMaskedInput}
                                    label={intl.formatMessage({
                                        id: `${type}.form.privatephone.label`,
                                    })}
                                    name="privatephone"
                                    id="privatephone"
                                    mask={MOBILE_PHONE_NUMBER_MASK}
                                    autoComplete="off"
                                    onAccept={(value, mask) => {
                                        mask.updateValue();
                                        setFieldValue('privatephone', value);
                                    }}
                                    customError={
                                        errors.privatephone?.includes('validation.mixed.required')
                                            ? ''
                                            : errors.privatephone
                                    }
                                    required
                                />
                            </FieldContainer>
                        </Col>
                        <Col xxs={30} xl={15}>
                            <FieldContainer>
                                <Field
                                    component={FormInput}
                                    label={intl.formatMessage({
                                        id: `${type}.form.email.label`,
                                    })}
                                    name="email"
                                    id="email"
                                    type="email"
                                    required
                                    autoComplete="off"
                                />
                            </FieldContainer>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default PersonRequiredInformationForm;
