import { Box, Button, Checkbox, Chip, ListItemText, MenuItem, IconButton } from '@mui/material';
import { H3 } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import CategoriesSelect from 'components/Categories/CategoriesSelect';
import categoryAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';
import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import './CategoriesFields.css'; // Import the CSS file for styling

const CategoriesFields = ({
    subcategoryFilters,
    handleCategoryChange,
    handleSubcategoryChange,
    categories,
    subcategories,
    selectedCategories,
    sectionSelectedFilters,
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [selectedFilters, setSelectedFilters] = useState(sectionSelectedFilters);

    useEffect(() => {
        const initialSelectedFilters =
            categoryAndFiltersUtils.initializeSelectedFilters(selectedCategories);
        setSelectedFilters(initialSelectedFilters);
    }, [selectedCategories]);

    const isChecked = (headerId, filterId) => {
        return selectedFilters[headerId]?.[filterId] || false;
    };

    const handleCheckboxToggle = (headerId, filterId) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [headerId]: {
                ...(prevFilters[headerId] || {}),
                [filterId]: !(prevFilters[headerId]?.[filterId] || false),
            },
        }));

        const newValue = `${headerId},${filterId}`;
        const existingFilterGroup = values.filter.find(
            (filterGroup) =>
                Array.isArray(filterGroup) &&
                filterGroup.some((value) => value.startsWith(headerId)),
        );

        let newFilters;
        if (existingFilterGroup) {
            newFilters = values.filter
                .map((filterGroup) => {
                    if (
                        Array.isArray(filterGroup) &&
                        filterGroup.some((value) => value.startsWith(headerId))
                    ) {
                        if (filterGroup.includes(newValue)) {
                            return filterGroup.filter((value) => value !== newValue);
                        } else {
                            return [...filterGroup, newValue];
                        }
                    }
                    return filterGroup;
                })
                .filter((group) => group.length > 0);
        } else {
            // If header group doesn't exist, create new one
            newFilters = [...values.filter, [newValue]];
        }

        setFieldValue('filter', newFilters);
    };

    const handleRemoveFilter = (headerId, filterId) => {
        setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (updatedFilters[headerId]) {
                delete updatedFilters[headerId][filterId];
                if (Object.keys(updatedFilters[headerId]).length === 0) {
                    delete updatedFilters[headerId];
                }
            }
            return updatedFilters;
        });

        const newFilters = values.filter
            .map((filterGroup) => {
                if (!Array.isArray(filterGroup)) return [];
                return filterGroup.filter((item) => item !== `${headerId},${filterId}`);
            })
            .filter((group) => group.length > 0);

        setFieldValue('filter', newFilters);
    };

    return (
        <>
            <Box mt={2} mb={3}>
                <Box className="categories-row">
                    <Box className="category-select">
                        <H3 color="secondary">
                            <FormattedMessage id="addBusiness.page.sections.categories.list.category.label" />
                        </H3>
                        <CategoriesSelect
                            name="category"
                            fullWidth
                            margin={'8px 0'}
                            displayEmpty
                            value={values.category}
                            onChange={(e) => handleCategoryChange(e, setFieldValue)}
                        >
                            <MenuItem value="">
                                <em>Select a category</em>
                            </MenuItem>
                            {categories?.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </CategoriesSelect>
                    </Box>
                    {subcategories && subcategories.length > 0 && (
                        <Box className="subcategory-select">
                            <H3 color="secondary">
                                <FormattedMessage id="addBusiness.page.sections.categories.subcategory.label" />
                            </H3>
                            <CategoriesSelect
                                name="subcategory"
                                fullWidth
                                displayEmpty
                                value={values.subcategory}
                                onChange={(e) => handleSubcategoryChange(e, setFieldValue)}
                            >
                                <MenuItem value="">
                                    <em>Select a subcategory</em>
                                </MenuItem>
                                {subcategories.map((subcategory) => (
                                    <MenuItem key={subcategory._id} value={subcategory._id}>
                                        {subcategory.name}
                                    </MenuItem>
                                ))}
                            </CategoriesSelect>
                        </Box>
                    )}
                    {subcategoryFilters && subcategoryFilters.length > 0 && (
                        <Box className="filters-select">
                            <H3 color="secondary">
                                <FormattedMessage id="addBusiness.page.sections.categories.filters.label" />
                            </H3>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                {subcategoryFilters.map((header, index) => {
                                    const selectedValue =
                                        categoryAndFiltersUtils.processFilterValueByHeader(
                                            values,
                                            header,
                                            selectedFilters,
                                        );
                                    const availableOptions =
                                        categoryAndFiltersUtils.getAvailableOptions(header);
                                    return (
                                        header.filters.length > 0 && (
                                            <Box
                                                gridColumn={{ xs: 'span 12', md: 'span 6' }}
                                                key={header._id}
                                            >
                                                <CategoriesSelect
                                                    name={`filter[${index}]`}
                                                    fullWidth
                                                    displayEmpty
                                                    value={selectedValue}
                                                    multiple
                                                    renderValue={(selected) => (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column', // Display items vertically
                                                                gap: 0.5,
                                                            }}
                                                        >
                                                            {selected.length === 0 && (
                                                                <span>
                                                                    <em>{header.name}</em>
                                                                </span>
                                                            )}

                                                            {Array.isArray(selected)
                                                                ? selected.map((value) => {
                                                                      const selectedItem =
                                                                          availableOptions.find(
                                                                              (option) =>
                                                                                  option.value ===
                                                                                  value,
                                                                          );
                                                                      return (
                                                                          selectedItem && (
                                                                              <Chip
                                                                                  key={value}
                                                                                  label={
                                                                                      selectedItem.label
                                                                                  }
                                                                                  onMouseDown={(
                                                                                      event,
                                                                                  ) => {
                                                                                      event.stopPropagation();
                                                                                  }}
                                                                                  onDelete={(
                                                                                      event,
                                                                                  ) => {
                                                                                      event.preventDefault();
                                                                                      event.stopPropagation();
                                                                                      handleRemoveFilter(
                                                                                          header._id,
                                                                                          value.split(
                                                                                              ',',
                                                                                          )[1],
                                                                                          event,
                                                                                      );
                                                                                  }}
                                                                                  deleteIcon={
                                                                                      <CloseIcon />
                                                                                  }
                                                                              />
                                                                          )
                                                                      );
                                                                  })
                                                                : null}
                                                        </Box>
                                                    )}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select {header.name}</em>
                                                    </MenuItem>
                                                    {header.filters.map((filter) => {
                                                        return (
                                                            <MenuItem
                                                                key={filter._id}
                                                                onClick={() =>
                                                                    handleCheckboxToggle(
                                                                        header._id,
                                                                        filter._id,
                                                                    )
                                                                }
                                                            >
                                                                <Checkbox
                                                                    checked={isChecked(
                                                                        header._id,
                                                                        filter._id,
                                                                    )}
                                                                />
                                                                <ListItemText
                                                                    primary={filter.name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </CategoriesSelect>
                                            </Box>
                                        )
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default CategoriesFields;
