import React, { useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_SYSTEM_NOTIFICATIONS_START,
  RESET_SYSTEM_NOTIFICATIONS_START,
  SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START,
} from "stores/redux/actions/PushActions";

const SystemPushNotificationsHistory = () => {
  const dispatch = useDispatch();
  const { systemNotifications, hasNext } = useSelector(
    ({ SystemNotificationsReducer }) => SystemNotificationsReducer,
  );

  useEffect(() => {
    dispatch({
      type: RESET_SYSTEM_NOTIFICATIONS_START,
    });
  }, []);

  useEffect(() => {
    if (!systemNotifications) {
      dispatch({
        type: GET_SYSTEM_NOTIFICATIONS_START,
      });
    }
  }, [systemNotifications]);

  return (
    <>
      <List dense={false}>
        {systemNotifications?.map((systemNotification) => {
          const {
            id,
            title,
            body,
            created,
            notifier: { name: notifierName },
          } = systemNotification;

          const createdDate = new Date(created);

          return (
            <ListItem key={`system_push_item_${id}`}>
              <ListItemText
                primary={
                  <Typography variant="h6">{title ?? "Empty title"}</Typography>
                }
                secondary={
                  <div>
                    <Typography variant="subtitle1">
                      {body ?? "Empty body"}
                    </Typography>
                    <Typography variant="body">
                      Sent by <b>{notifierName ?? "unknown user"}</b> on{" "}
                      <b>{createdDate.toISOString()}</b>
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          );
        })}
        {hasNext && (
          <ListItem>
            <Button
              onClick={() => {
                dispatch({
                  type: SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START,
                });
              }}
            >
              Load more
            </Button>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default SystemPushNotificationsHistory;
