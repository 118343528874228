import { select, takeEvery } from 'redux-saga/effects';
import { PostSupport } from 'api';
import { POST_SUPPORT_PROCESS, POST_SUPPORT_START } from '../actions/SupportActions';
import genericSagaHandler from './CommonSaga';

function* requestSupport({ payload }) {
    yield genericSagaHandler(POST_SUPPORT_PROCESS, function* () {
        const { subject, body } = payload;
        const { _id } = yield select(({ BusinessFormReducer }) => BusinessFormReducer || {});
        const response = yield PostSupport({
            businessid: _id,
            subject,
            body,
        });
        console.log(response);
    });
}

export default function* SupportSagas() {
    yield takeEvery(POST_SUPPORT_START, requestSupport);
}
