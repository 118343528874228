import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'baseline-ui/layout';
import { isAdminSelector } from 'stores/redux/selectors/authenticationSelectors';
import { useSelector } from 'react-redux';
import { H6 } from 'baseline-ui/typography';
import { DefaultAlert } from 'baseline-ui/alert';
import { useIntl } from 'react-intl';

const AdminBusinessStatusOverview = ({ adminOnly }) => {
    const intl = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    const renderFollowupStatus = adminOnly?.followupStatus;
    const renderPrivateCell = adminOnly?.privateCell;
    const renderAdvertisementPriority = adminOnly?.advertisementPriority;
    const advertise = adminOnly?.advertise;
    const renderAdvertise = advertise && (advertise.appAnchor || advertise.categoriesAndFilters?.some(item => item?.categoryEnabled)) ? advertise : false;

    if (!isAdmin) {
        return null;
    }

    if (
        (!renderFollowupStatus || renderFollowupStatus === 'undefined') &&
        !renderPrivateCell &&
        !renderAdvertise
    ) {
        return null;
    }

    return (
        <Col xxs={30}>
            <br />
            <DefaultAlert inProp alertType="primaryMuted" appear={false}>
                {renderFollowupStatus && (
                    <H6>
                        Follow-up Status:{' '}
                        <b>
                            {intl.formatMessage({
                                id: `admin.followupStatus.${renderFollowupStatus}.label`,
                            })}
                        </b>
                    </H6>
                )}
                {renderPrivateCell && (
                    <H6>
                        Private Cell: <b>{renderPrivateCell}</b>
                    </H6>
                )}
                {renderAdvertise && (
                    <>
                        <H6>
                            Advertise <b>{renderAdvertise ? 'Enabled' : 'Not Enabled'}</b>
                        </H6>
                        <H6>
                            Advertisement Priority: <b>{renderAdvertisementPriority}</b>
                        </H6>
                    </>
                )}
            </DefaultAlert>
        </Col>
    );
};

AdminBusinessStatusOverview.propTypes = {
    adminOnly: PropTypes.shape({
        followupStatus: PropTypes.string,
        privateCell: PropTypes.string,
        advertise: PropTypes.bool,
        advertisementPriority: PropTypes.number,
    }),
};

AdminBusinessStatusOverview.defaultProps = {
    adminOnly: undefined,
};

export default AdminBusinessStatusOverview;
