import React, {useContext, useEffect, useState} from "react";
import { GetAllTags } from "api";
import TagComponent from "./TagComponent";
import PageHeader from "../pageLayout/PageHeader";
import AdminDefaultTemplate from "../pageLayout/templates/AdminDefaultTemplate";
import CommonWrapper from "../../screens/CommonWrapper";
import {ThemeContext} from "styled-components";
import {useIntl} from "react-intl";

const sortTags = (tags) => {
  const data = tags.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  data.forEach(item => {
    for (const key in item) {
      if (Array.isArray(item[key])) {
        item[key] = item[key].sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }

          if (a.name < b.name) {
            return -1;
          }

          return 0;
        });

        sortTags(item[key]);
      }
    }
  });

  return data;
};

function TagManager(props) {
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    GetAllTags().then((tags) => {
      setGroups(sortTags(tags));
    });
  }, []);

  return (
    <CommonWrapper>
      <AdminDefaultTemplate
        bgEnd={theme.site.admin.bgEnd}
        bgStart={theme.site.admin.bgStart}
        banner={
          <PageHeader title={intl.formatMessage({ id: 'tagManager.page.title' })} />
        }
        main={
          <div>
            {
              groups.map((group) => (
                <TagComponent key={group.id} {...group} currentLevel="groups" parentId={null}/>
              ))
            }
          </div>
        }
      />
    </CommonWrapper>
  );
}

export default TagManager;
