import React from 'react';
import { useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import {
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import BaseCategories from '../../../../../screens/BusinessForm/BaseCategories';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';

const AdminBusinessAdvertisingCategories = ({ selectedCategories, onSubmit}) => {
    const { type } = useSelector(businessFormSelector);

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.categories.title" />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.categories.subtitle`} />
            </P>
            <div>
                <BaseCategories
                    selectedCategories={selectedCategories}
                    onRemoveFilter={({filterId}) => {
                        const val = categoriesAndFiltersUtils.removeFilterFromSelected(
                            selectedCategories,
                            filterId,
                        );

                        onSubmit(val);
                    }}
                    onRemoveSubcategory={({subcategoryId}) => {
                        const val = categoriesAndFiltersUtils.removeSubcategoryFromSelected(
                            selectedCategories,
                            subcategoryId,
                        );

                        onSubmit(val);
                    }}
                    onRemoveCategory={({categoryId}) => {
                        const val = categoriesAndFiltersUtils.removeCategoryFromSelected(
                            selectedCategories,
                            categoryId,
                        );

                        onSubmit(val);
                    }}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
};

export default AdminBusinessAdvertisingCategories;
