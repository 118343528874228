import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET_BUSINESS_FORM_START } from 'stores/redux/actions/BusinessFormActions';
import { entityTypeSelector } from 'stores/redux/selectors/businessFormSelectors';
import { ENTITY_BOOK, ENTITY_MOVIE, ENTITY_SHOW, ENTITY_PODCAST, ENTITY_TYPE_RECIPES } from 'common/consts/consts';
import BookDetails from 'screens/Interests/Books/BookDetails';
import MovieDetails from 'screens/Interests/Movies/MovieDetails';
import TVShowDetails from 'screens/Interests/Shows/TVShowDetails';
import PodcastDetails from 'screens/Interests/Podcasts/PodcastDetails';
import EditBusiness from 'screens/EditBusiness';
import RecipeDetails from 'screens/Interests/Recipes/RecipeDetails';

const ENTITY_COMPONENTS = {
    [ENTITY_BOOK.toLowerCase()]: BookDetails,
    [ENTITY_MOVIE.toLowerCase()]: MovieDetails,
    [ENTITY_SHOW.toLowerCase()]: TVShowDetails,
    [ENTITY_PODCAST.toLowerCase()]: PodcastDetails,
    [ENTITY_TYPE_RECIPES.toLowerCase()]: RecipeDetails,
};

const EntityRouter = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const type = useSelector(entityTypeSelector);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasDispatchedInitial, setHasDispatchedInitial] = useState(false);

    useEffect(() => {
        if (id && !hasDispatchedInitial) {
            dispatch({ type: GET_BUSINESS_FORM_START, payload: id });
            setHasDispatchedInitial(true);
        }
    }, [id, hasDispatchedInitial]);

    useEffect(() => {
        if (type && !isInitialized) {
            setIsInitialized(true);
        }
    }, [type]);

    if (!isInitialized) {
        return <></>;
    }

    const EntityComponent = type ? ENTITY_COMPONENTS[type.toLowerCase()] : null;

    if (EntityComponent) {
        return <EntityComponent />;
    }

    return <EditBusiness />;
};

export default React.memo(EntityRouter);
