import { put, select, takeEvery } from 'redux-saga/effects';
import { isNumber } from 'lodash';
import { requestVerificationCode, resetPassword, validateVerificationCode } from 'api';
import { POST_LOGIN_START } from 'stores/redux/actions/AuthenticationActions';
import {
    POST_REQUEST_PASSWORD_RESET_CODE_PROCESS,
    POST_REQUEST_PASSWORD_RESET_CODE_START,
    POST_RESET_PASSWORD_PROCESS,
    POST_RESET_PASSWORD_START,
    SET_RESET_PASSWORD_CODE_START,
    SET_RESET_PASSWORD_CODE_SUCCESS,
} from '../actions/ResetPasswordActions';
import genericSagaHandler from './CommonSaga';

function* resetPasswordSaga({ payload }) {
    yield genericSagaHandler(POST_RESET_PASSWORD_PROCESS, function* handleResetPassword() {
        const { identifier, code, newPassword, navigate } = payload;
        yield resetPassword({
            identifier,
            code,
            newpassword: newPassword,
        });
        yield put({
            type: POST_LOGIN_START,
            payload: {
                email: identifier,
                password: newPassword,
                navigate,
            },
        });
    });
}

function* postRequestPasswordResetCodeSaga({ payload }) {
    yield genericSagaHandler(
        POST_REQUEST_PASSWORD_RESET_CODE_PROCESS,
        function* handlePostRequestPasswordResetCode() {
            const { identifier } = payload;
            if (isNumber(identifier)) {
                yield requestVerificationCode({ phone: identifier });
            } else {
                yield requestVerificationCode({ email: identifier });
            }
        },
    );
}

function* setResetPasswordCodeSaga({ payload }) {
    yield genericSagaHandler(SET_RESET_PASSWORD_CODE_START, function* handleResetPasswordCode() {
        const { identifier } = yield select(({ ResetPasswordReducer }) => ResetPasswordReducer);
        let validatePayload = {
            email: identifier?.toLowerCase(),
            code: payload,
        };
        if (isNumber(identifier)) {
            validatePayload = {
                phone: identifier,
                code: payload,
            };
        }
        const { data } = yield validateVerificationCode(validatePayload);
        yield put({
            type: SET_RESET_PASSWORD_CODE_SUCCESS,
            payload: {
                code: payload,
                statusCodeVerified: data.status,
            },
        });
    });
}

export default function* ResetPasswordSagas() {
    yield takeEvery(POST_RESET_PASSWORD_START, resetPasswordSaga);
    yield takeEvery(POST_REQUEST_PASSWORD_RESET_CODE_START, postRequestPasswordResetCodeSaga);
    yield takeEvery(SET_RESET_PASSWORD_CODE_START, setResetPasswordCodeSaga);
}
