import { rem } from 'polished';
import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import {
    PREVIEW_HEIGHT,
    PREVIEW_MAX_HEIGHT,
    PREVIEW_MAX_HEIGHT_SM,
    PREVIEW_MAX_WIDTH,
    PREVIEW_MODAL_HEADER_HEIGHT,
} from 'common/consts/previewConstants';

const FavePagePreviewWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -${rem(16)};
    margin-left: auto;
    margin-right: auto;
    max-height: ${PREVIEW_MAX_HEIGHT}px;
    max-width: ${PREVIEW_MAX_WIDTH}px;
    min-height: calc(${PREVIEW_HEIGHT} - ${PREVIEW_MODAL_HEADER_HEIGHT}px);
    padding: 4px;
    position: relative;
    width: 100%;

    ${media.sm`
        max-height: ${PREVIEW_MAX_HEIGHT_SM}px;
    `}
`;

export default FavePagePreviewWrapper;
