import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import businessIcon from "assets/images/businessIcon.png";
import { useDispatch, useSelector } from "react-redux";

import useWindowSize from "common/hooks/useWindowSize";
import ResponsiveImage from "../../components/ResponsiveImage";
import { Fragment, useState } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import {
  POST_BUSINESS_FORM_PROCESS,
  POST_BUSINESS_FORM_START,
  SAVE_ADDITIONA_INFORMATION_START,
  SAVE_CATEGORIES_START,
  SAVE_DATA_TO_LOCAL_STORAGE_START,
  SAVE_LINKS_START,
  SAVE_PHOTOS_START,
  SAVE_REQUIRED_INFORMATION_START,
} from "stores/redux/actions/BusinessFormActions";
import { useNavigate } from "react-router-dom";
import MuiAlertErrorComponent from "../../components/MuiAlertErrorComponent";
import { COLORS } from "common/consts/consts";
import ProductRequiredInformationForm from "./ProductRequiredInformationForm";
import ProductCategoriesForm from "./ProductCategoriesForm";
import ProductAdditionalInformationForm from "./ProductAdditionalInformationForm";
import ProductPhotosForm from "./ProductPhotosForm";
import ProductSectionForm from "./ProductSectionForm";
import {
  ADD_PRODUCT_START,
  POST_SAVE_PRODUCT_START,
} from "stores/redux/actions/EditProductActions";

const ProductForm = ({ open, onClose, targetProductToEdit }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { productToEdit } = useSelector(
      ({ EditProductReducer }) => EditProductReducer
  );

  const SECTIONS = [
    {
      id: "REQUIRED_INFORMATION",
      label: "Required information",
      component: <ProductRequiredInformationForm />,
      saveActionName: SAVE_REQUIRED_INFORMATION_START,
    },
    {
      id: "CATEGORIES_FILTERS",
      label: "Categories/Filters",
      component: <ProductCategoriesForm />,
      saveActionName: SAVE_CATEGORIES_START,
    },
    {
      id: "PRODUCT_SECTION",
      label: "Product Section",
      component: <ProductSectionForm />,
      saveActionName: SAVE_ADDITIONA_INFORMATION_START,
    },
    {
      id: "PHOTOS",
      label: "Photos",
      component: <ProductPhotosForm />,
      saveActionName: SAVE_PHOTOS_START,
    },
  ];

  const [selectedSection, setSelectedSection] = useState(SECTIONS[0].id);
  const selectedSectionIndex = SECTIONS.findIndex(
      (s) => s.id === selectedSection
  );

  const sectionComponent = SECTIONS[selectedSectionIndex].component;
  return (
      <Grid container item>
        <Grid md={1} xs={0} item />
        <Grid md={10} xs={12} item container sx={{ paddingTop: 0, marginTop: 0 }}>
          <Grid
              item
              container
              xs={10}
              sx={{
                width: width,
                display: { xs: "flex", md: "none" },
              }}
          >
            <Tabs
                value={selectedSectionIndex}
                onChange={(e, newValue) => {
                  setSelectedSection(SECTIONS[newValue].id);
                }}
                variant="scrollable"
                allowScrollButtonsMobile
            >
              {SECTIONS.map((section) => (
                  <Tab label={section.label} key={`section_tabs_${section.id}`} />
              ))}
            </Tabs>
          </Grid>
          <Grid
              md={4}
              alignItems={"center"}
              // justifyContent={"flex-start"}
              item
              container
              sx={{
                display: { xs: "none", md: "flex" },
              }}
          >
            <Grid item xs={12}>
              <Grid
                  xs={12}
                  item
                  container
                  justifyContent={"center"}
                  marginBottom={"36px"}
              >
                <ResponsiveImage image={businessIcon} />
              </Grid>
              {SECTIONS.map((section) => (
                  <Fragment key={`sidebar_buttons_${section.id}`}>
                    <PrimaryButton
                        onClick={() => setSelectedSection(section.id)}
                        caption={section.label}
                        variant={
                          selectedSection === section.id ? "contained" : "disabled"
                        }
                    />
                    <Box height={"10px"} />
                  </Fragment>
              ))}
            </Grid>

            <Grid xs={12} item container marginBottom={"22px"}>
              <PrimaryButton
                  caption="Save & submit"
                  onClick={() => {
                    // if (
                    //   !productToEdit?.mapping?.categories ||
                    //   productToEdit?.mapping?.categories?.length === 0
                    // ) {
                    //   const newErrors = [...errors];
                    //   if (!newErrors.find((e) => e.type === "categories")) {
                    //     newErrors.push({
                    //       type: "categories",
                    //       message:
                    //         "Please select at least one category for your product",
                    //     });
                    //     setErrors(newErrors);
                    //   }

                    //   return;
                    // }
                    // setErrors([]);
                    if (productToEdit._id) {
                      dispatch({ type: POST_SAVE_PRODUCT_START });
                    } else {
                      dispatch({ type: ADD_PRODUCT_START });
                    }
                    navigate(-1);
                  }}
              />
            </Grid>
          </Grid>
          <Grid
              xs={12}
              md={8}
              item
              paddingLeft={"26px"}
              paddingRight={"26px"}
              paddingBottom={{
                xs: "250px",
                md: "80px",
              }}
          >
            <MuiAlertErrorComponent processName={POST_BUSINESS_FORM_PROCESS} />

            {sectionComponent}
          </Grid>
          <Grid
              xs={12}
              sx={{ zIndex: "drawer" }}
              item
              container
              backgroundColor={COLORS.MAIN_LIGHT_BG}
              minHeight={"90px"}
              position={"fixed"}
              bottom={"80px"}
              left={0}
              alignItems={"center"}
              justifyContent={"space-between"}
              paddingRight={"10px"}
              paddingLeft={"10px"}
              rowSpacing={1}
          >
            <Grid md={1} xs={12} item>
              <PrimaryButton
                  variant={
                    selectedSection === SECTIONS[0].id ? "disabled" : "outlined"
                  }
                  caption={"Back"}
                  onClick={() => {
                    const currentlySelectedSectionIndex = SECTIONS.findIndex(
                        (s) => s.id === selectedSection
                    );
                    if (currentlySelectedSectionIndex > 0) {
                      setSelectedSection(
                          SECTIONS[currentlySelectedSectionIndex - 1].id
                      );
                    }
                  }}
              />
            </Grid>

            <Grid
                xs={12}
                md={6}
                item
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                columnSpacing={1}
                rowSpacing={1}
            >
              <Grid item display={{ xs: "none", md: "flex" }} md={5}>
                <Typography variant="beta2">
                  Save and come back at any time
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <PrimaryButton
                    variant="contained"
                    caption={"Save"}
                    onClick={() => {
                      const currentlySelectedSectionIndex = SECTIONS.findIndex(
                          (s) => s.id === selectedSection
                      );
                      dispatch({
                        type: SAVE_DATA_TO_LOCAL_STORAGE_START,
                      });
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} paddingBottom={"5px"}>
                <PrimaryButton
                    variant={
                      selectedSection === SECTIONS[SECTIONS.length - 1].id
                          ? "disabled"
                          : "outlined"
                    }
                    caption={"Next"}
                    onClick={() => {
                      const currentlySelectedSectionIndex = SECTIONS.findIndex(
                          (s) => s.id === selectedSection
                      );
                      if (currentlySelectedSectionIndex < SECTIONS.length - 1) {
                        setSelectedSection(
                            SECTIONS[currentlySelectedSectionIndex + 1].id
                        );
                      }
                    }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={1} xs={0} item />
      </Grid>
  );
};

export default ProductForm;
