import React, {useEffect, useRef, useState} from 'react';
import {Image} from 'baseline-ui/helpers';
import {LinkButton, RenderButton} from 'baseline-ui/button';
import MobileMenuWrapper from './MobileMenuWrapper';
import MenuIcon from './MenuIcon';
import MenuOverlay from './MenuOverlay';
import MenuItems from './MenuItems';
import MenuItem from './MenuItem';
import ItemLabel from './ItemLabel';
import PrivacyTermsWrapper from 'baseline-ui/footer/src/components/DefaultFooter/PrivacyTermsWrapper';
import {FormattedMessage} from 'react-intl';
import CopyrightContainer from 'baseline-ui/footer/src/components/DefaultFooter/CopyrightContainer';
import {TERMS_AND_CONDITIONS_PATH} from 'common/consts/pathnames';
import PropTypes from 'prop-types';

const MobileMenu = ({ items, icon, activeIcon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && !anchorRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <MobileMenuWrapper>
            <MenuIcon ref={anchorRef} isOpen={isOpen} onClick={handleToggle}>
                {isOpen ? (
                    <Image src={activeIcon} width={30} height={30} imageFluid={false} />
                ) : (
                    <Image src={icon} width={30} height={30} imageFluid={false} />
                )}
            </MenuIcon>

            <MenuOverlay ref={menuRef} isOpen={isOpen} skin='light'>
                <MenuItems>
                    {items.map((item, index) => (
                        <MenuItem key={index}>
                            <RenderButton
                                path={item.path}
                                btnType={item.btnType}
                                skin={item.skin}
                                icon={item.icon}
                                iconPostLabel={item.iconPostLabel}
                                size='xl'
                                onClick={(e) => {
                                    setIsOpen(false)
                                    if(item.onClick){
                                        item.onClick(e);
                                    }
                                }}
                            >
                                <ItemLabel>{item.label}</ItemLabel>
                            </RenderButton>
                        </MenuItem>
                    ))}
                </MenuItems>
                <CopyrightContainer justifyContent='center'>
                    <PrivacyTermsWrapper>
                        <LinkButton to={TERMS_AND_CONDITIONS_PATH} btnType='link' skin='muted' size='sm'>
                            <FormattedMessage id="footer.terms.label" />
                        </LinkButton>
                        <LinkButton to={"/"} btnType='link' skin='muted' size='sm'>
                            <FormattedMessage id="footer.privacy.label" />
                        </LinkButton>
                    </PrivacyTermsWrapper>
                </CopyrightContainer>
            </MenuOverlay>
        </MobileMenuWrapper>
    );
};
MobileMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            btnType: PropTypes.string.isRequired,
            skin: PropTypes.string,
            icon: PropTypes.string,
            iconPostLabel: PropTypes.string,
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
        })
    ).isRequired,
    icon: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
};
export default MobileMenu;
