import { PHOTO_PREVIEW_CAROUSEL_HEIGHT } from 'common/consts/consts';
import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewPhotoPlaceholderContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    flex-direction: column;
    height: ${rem(PHOTO_PREVIEW_CAROUSEL_HEIGHT)};
    justify-content: center;
`;

export default FavePagePreviewPhotoPlaceholderContainer;
