import React from 'react';
import { Collapsable } from 'baseline-ui/collapsable';
import { Col, Row } from 'baseline-ui/layout';
import { Field, FieldArray, Formik } from 'formik';
import { FormCheckbox } from 'baseline-ui/form';
import { Button } from 'baseline-ui/button';
import AdminBusinessAdvertisingRowContainer from './AdminBusinessAdvertisingRowContainer';
import AdminBusinessAdvertisingOrder from './AdminBusinessAdvertisingOrder';
import AdminBusinessAdvertisingAddCity from './AdminBusinessAdvertisingAddCity';
import AdminBusinessAdvertisingRotate from './AdminBusinessAdvertisingRotate';
import AdminBusinessAdvertisingFormContainer from './AdminBusinessAdvertisingFormContainer';
import AdminBusinessAdvertisingAddItemRow from './AdminBusinessAdvertisingAddItemRow';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { POST_BUSINESS_FORM_PROCESS } from 'stores/redux/actions/BusinessFormActions';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const AdminBusinessAdvertising = ({ onSubmit }) => {
    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
    });
    const { adminOnly } = useSelector(businessFormSelector);
    const advertise = adminOnly?.advertise;
    const initialCategoriesAndFilters = {
        categoryEnabled: false,
        categoryOrder: '',
        categoryOrderEnabled: false,
        categorySearchCity: '',
        categoryCityList: [],
        categoryTags: [],
        categoryRotate: false,
    };

    const initialValues = {
        appAnchor: false,
        appAnchorSearchCity: '',
        appAnchorCityList: [],
        appAnchorRotate: false,
        appAnchorOrder: '',
        appAnchorOrderEnabled: false,
        categoriesAndFilters: [initialCategoriesAndFilters],
        ...advertise,
    };

    return (
        <Collapsable title="Advertising" triggerColor="#333" iconColor="#333">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleSubmit }) => {
                    return (
                        <AdminBusinessAdvertisingFormContainer>
                            <AdminBusinessAdvertisingRowContainer>
                                <Row alignItems="center">
                                    <Col xxs={30} lg={8}>
                                        <Field
                                            component={FormCheckbox}
                                            skin="dark"
                                            id="appAnchor"
                                            name="appAnchor"
                                        >
                                            <FormattedMessage id="addBusiness.page.sections.advertising.appAnchor" />
                                        </Field>
                                    </Col>
                                    <Col xxs={30} lg={8}>
                                        <AdminBusinessAdvertisingAddCity id="appAnchor" />
                                    </Col>
                                    <Col xxs={30} lg={7}>
                                        <AdminBusinessAdvertisingOrder id="appAnchor" />
                                    </Col>
                                    <Col xxs={30} lg={6}>
                                        <AdminBusinessAdvertisingRotate id="appAnchor" />
                                    </Col>
                                </Row>
                            </AdminBusinessAdvertisingRowContainer>

                            <FieldArray
                                name="categoriesAndFilters"
                                render={(arrayHelpers) => (
                                    <>
                                        <AdminBusinessAdvertisingAddItemRow
                                            arrayHelpers={arrayHelpers}
                                        />
                                        <Button
                                            block
                                            icon="plus"
                                            size="sm"
                                            btnType="outlined"
                                            onClick={() =>
                                                arrayHelpers.push(initialCategoriesAndFilters)
                                            }
                                        >
                                            <FormattedMessage id="addBusiness.page.sections.advertising.categoryAndFilters.addRowButton.label" />
                                        </Button>
                                        <Button
                                            loading={isLoading}
                                            block
                                            size="sm"
                                            onClick={handleSubmit}
                                        >
                                            <FormattedMessage id="addBusiness.page.sections.advertising.saveButton.label" />
                                        </Button>
                                    </>
                                )}
                            />
                        </AdminBusinessAdvertisingFormContainer>
                    );
                }}
            </Formik>
        </Collapsable>
    );
};

export default AdminBusinessAdvertising;
