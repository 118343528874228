import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUSINESS_APPROVAL_STATUSES } from 'common/consts/consts';
import StatusTagContainer from './StatusTagContainer';
import { useSelector, useDispatch } from 'react-redux';
import { isAdminSelector } from 'stores/redux/selectors/authenticationSelectors';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import { UPDATE_ENTITY_APPROVAL_STATUS_PROCESS } from 'stores/redux/actions/BusinessFormActions';

const StatusTag = ({ status, onStatusChange, editable = false }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(isAdminSelector);
    const { _id: entityId } = useSelector(businessFormSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(status);

    if (!status) {
        return null;
    }

    const approvalOptions = [
        {
            value: 'DRAFT',
            label: intl.formatMessage({ id: 'admin.approvalStatus.DRAFT.label' }),
        },
        {
            value: 'PENDING',
            label: intl.formatMessage({ id: 'admin.approvalStatus.PENDING.label' }),
        },
        {
            value: 'APPROVED',
            label: intl.formatMessage({ id: 'admin.approvalStatus.APPROVED.label' }),
        },
        {
            value: 'DENIED',
            label: intl.formatMessage({ id: 'admin.approvalStatus.DENIED.label' }),
        },
    ];

    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        setSelectedStatus(newStatus);

        try {
            setIsLoading(true);

            dispatch({
                type: UPDATE_ENTITY_APPROVAL_STATUS_PROCESS,
                payload: {
                    entityId,
                    approvalStatus: newStatus,
                    onSuccess: () => {
                        setIsLoading(false);
                        if (onStatusChange) {
                            onStatusChange(newStatus);
                        }
                    },
                    onError: () => {
                        setIsLoading(false);
                        setSelectedStatus(status);
                    },
                },
            });
        } catch (error) {
            setIsLoading(false);
            setSelectedStatus(status);
        }
    };

    if (isSuperAdmin && entityId && editable) {
        return (
            <div style={{ position: 'relative' }}>
                <Select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    size="small"
                    sx={{
                        '& .MuiSelect-select': {
                            py: 0.5,
                            px: 1,
                        },
                        width: '160px',
                        height: '24px',
                        ...(isLoading && {
                            '& .MuiSelect-iconOutlined': {
                                display: 'none',
                            },
                        }),
                    }}
                    disabled={isLoading}
                >
                    {approvalOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {isLoading && (
                    <CircularProgress
                        size={16}
                        sx={{
                            color: 'primary.main',
                            position: 'absolute',
                            right: '11px',
                            top: '8px',
                            zIndex: 1,
                        }}
                    />
                )}
            </div>
        );
    }

    return (
        <StatusTagContainer status={status}>
            <FormattedMessage id={`status.${status}.label`} />
        </StatusTagContainer>
    );
};

StatusTag.propTypes = {
    status: PropTypes.oneOf(BUSINESS_APPROVAL_STATUSES),
    onStatusChange: PropTypes.func,
    editable: PropTypes.bool,
};

StatusTag.defaultProps = {
    status: undefined,
    onStatusChange: undefined,
    editable: false,
};

export default StatusTag;
