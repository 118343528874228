import React from "react";
import { useState } from "react";
import { EditTag, CreateTag } from "api";
import "./TagComponent.css";

const levelMapping = {
  groups: {
    label: "Group",
    apiLevelEnum: "group",
    childLevel: "categories",
  },
  categories: {
    label: "Category",
    apiLevelEnum: "category",
    childLevel: "headers",
  },
  headers: {
    label: "Header",
    apiLevelEnum: "header",
    childLevel: "subcategories",
  },
  subcategories: {
    label: "Subcategory",
    apiLevelEnum: "subcategory",
  },
};

function TagManager(props) {
  function getChildren(vals) {
    return levelMapping[props.currentLevel].childLevel;
  }
  // Get all tags on effect
  // super nested structure
  // groups
  // cats
  // headers
  const [newTagName, setNewTagName] = useState("");
  const [currentTagName, setCurrentTagName] = useState(props.name);
  const [showNewTagTemplate, setShowNewTagTemplate] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const childLevel = getChildren(props);
  let childrenTemp = childLevel && props[childLevel] ? props[childLevel] : [];
  const [children, setChildren] = useState(childrenTemp);

  const childVals = children.map((child) => (
    <TagManager key={child.id} {...child} parentId={props.id} currentLevel={childLevel} />
  ));
  // subs
  // with edit and create on each level

  const currentLevelObj = levelMapping[props.currentLevel];
  const childLevelObj = childLevel ? levelMapping[childLevel] : {};
  const currentLevelText = currentLevelObj.label;
  const childLevelText = childLevel ? levelMapping[childLevel].label : null;
  const handleNewChange = (e) => {
    setNewTagName(e.target.value);
  };
  const handleEditChange = (e) => {
    setCurrentTagName(e.target.value);
  };
  const saveCurrentName = async () => {
    if (currentTagName) {
      await EditTag({ id: props.id, name: currentTagName });
      setEditClicked(false);
    }
  };
  const saveNewName = async () => {
    if (newTagName) {
      setShowNewTagTemplate(false);
      setNewTagName("");
      const { payload } = await CreateTag({
        parent_id: props.id,
        name: newTagName,
        level: childLevelObj.apiLevelEnum,
      });
      const deserializedVal = { name: payload.name, id: payload._id };
      deserializedVal[childLevel] = [];
      setChildren((oldChildren) => [...oldChildren, deserializedVal]);
    }
  };
  const toggleExpand = () => {
    setExpanded((oldVal) => !oldVal);
  };
  const createNewTagClicked = () => {
    setShowNewTagTemplate(true);
  };
  const openEdit = () => {
    setEditClicked(true);
  };

  return (
    <div className="TagComponent">
      <div className="header">
        {childVals.length > 0 ? (
          <div className="expand-setting clickable" onClick={toggleExpand}>
            ↕
          </div>
        ) : (
          <div className="expand-setting"></div>
        )}
        <div className="level">{currentLevelText}</div>

        {!editClicked ? (
          <div className="title clickable" onClick={openEdit}>
            {props.name}
          </div>
        ) : (
          <div>
            <input
              className="edit-name"
              type="text"
              value={currentTagName}
              onChange={handleEditChange}
            />
            <label className="save clickable" onClick={saveCurrentName}>
              Save
            </label>
          </div>
        )}
        {childLevelText ? (
          <div className="create clickable" onClick={createNewTagClicked}>
            Create New {childLevelText}
          </div>
        ) : (
          <div className="create"></div>
        )}
      </div>

      {showNewTagTemplate ? (
        <div className="header create-header">
          <div className="expand-setting"></div>
          <div className="level">{childLevelText}</div>
          <input
            className="create-name"
            type="text"
            value={newTagName}
            onChange={handleNewChange}
          />
          <label className="save-new-name clickable" onClick={saveNewName}>
            Save
          </label>
        </div>
      ) : null}
      <div className={`children ${expanded ? "" : "closed"}`}>{childVals}</div>
    </div>
  );
}
export default TagManager;
