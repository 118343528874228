import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { H1, P } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import { Button } from 'baseline-ui/button';
import ProductsAndServicesItemList from 'components/productsAndServices/ProductsAndServicesItemList';
import { useDispatch, useSelector } from 'react-redux';
import { allSectionsActiveProductsSelector } from 'stores/redux/selectors/sectionSelectors';
import { OverlayContext } from 'baseline-ui/overlay';
import {
    SAVE_PRODUCTS_BULK_PROCESS,
    SET_EMPTY_PRODUCT_START,
} from 'stores/redux/actions/EditProductActions';
import ModalAddProduct from 'components/productsAndServices/modalProduct/ModalAddProduct';
import useProcessLoader from 'common/hooks/useProcessLoader';
import ProductsAndServicesIntroActionContainer from 'components/productsAndServices/ProductsAndServicesIntroActionContainer';
import ProductsAndServicesIntroActionsContainer from 'components/productsAndServices/ProductsAndServicesIntroActionsContainer';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';

const ProductsAndServices = () => {
    const dispatch = useDispatch();
    const { showOverlay } = useContext(OverlayContext);
    const allSectionsActiveProducts = useSelector(allSectionsActiveProductsSelector);
    const { type } = useSelector(businessFormSelector);

    useProcessLoader({
        processNames: [SAVE_PRODUCTS_BULK_PROCESS],
        withOverlay: true,
    });

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.productsAndServices.title" />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.productsAndServices.subtitle`} />
            </P>
            <Button 
                onClick={() => {
                    dispatch({ type: SET_EMPTY_PRODUCT_START });
                    showOverlay(ModalAddProduct);
                }}
            >
                <FormattedMessage id="addBusiness.page.sections.productsAndServices.addProductsAndServices" />
            </Button>
            <DndProvider backend={HTML5Backend}>
                <Row justifyContent="center" style={{ width: '100%' }}>
                    <Col  flex={1}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '100%',  // Constrain to the parent's width
                            overflow: 'hidden',  // Prevent overflowing content
                        }}>
                        <ProductsAndServicesItemList
                            sectionContainers={allSectionsActiveProducts}
                        />
                    </Col>
                </Row>
            </DndProvider>
        </>
    );
};

export default ProductsAndServices;
