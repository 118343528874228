import { useEffect, useRef } from 'react';
import { useFormikContext, Field } from 'formik';
import {FormattedMessage, useIntl} from 'react-intl';
import { Col, Row } from 'baseline-ui/layout';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { usePrevious } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import {BUSINESS_LINKS_HTTPS_PROTOCOL, BUSINESS_LINKS_REGEX_PROTOCOL} from 'common/consts/consts';
import {isEmpty} from 'lodash';

const LinksForm = ({
  urlLabel,
  captionLabel,
  urlFieldName = 'url',
  captionFieldName = 'caption',
  linkTypeDefaults,
  disableSubmitByCondition,
  links,
  onAddLink,
}) => {
    const intl = useIntl();
    const { values, setFieldValue } = useFormikContext();
    const prevLinks = usePrevious(links);
    const inputUrlRef = useRef();
    const { type, url } = values;
    useEffect(() => {
        if (type) {
            setFieldValue(
                captionFieldName,
                linkTypeDefaults.find((linkTypeUrl) => linkTypeUrl.value === type)?.caption || ''
            );
            inputUrlRef.current?.focus();
        }
    }, [type, inputUrlRef]);

    useEffect(() => {
        if (!isEmpty(url)) {
            setFieldValue(urlFieldName, url.replace(BUSINESS_LINKS_REGEX_PROTOCOL, ''));
        }
    }, [values]);

    useEffect(() => {
        if (prevLinks?.length > links?.length) {
            inputUrlRef.current?.focus();
        }
    }, [inputUrlRef, links, prevLinks]);

    return (
        <>
            <Row>
                <Col xxs={30} lg={30} xl={15}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({ id: urlLabel })}
                            name={urlFieldName}
                            type="url"
                            innerRef={inputUrlRef}
                            prependAddon={BUSINESS_LINKS_HTTPS_PROTOCOL}
                            disabled={disableSubmitByCondition}
                        />
                    </FieldContainer>
                </Col>
                <Col xxs={30} lg={30} xl={15}>
                    <FieldContainer>
                        <Field
                            component={FormInput}
                            label={intl.formatMessage({ id: captionLabel })}
                            name={captionFieldName}
                            id={captionFieldName}
                            type="text"
                            disabled={disableSubmitByCondition}
                        />
                    </FieldContainer>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        disabled={disableSubmitByCondition}
                        onClick={onAddLink}
                    >
                        <FormattedMessage id="addBusiness.page.sections.links.form.addNewLink.label" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default LinksForm;