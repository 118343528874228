import {
    CalendarToday as CalendarIcon,
    HighlightOff as DeleteIcon,
    Edit as EditIcon,
    CalendarToday as EventIcon,
    AccessTime as TimeIcon,
} from '@mui/icons-material';

import {
    Avatar,
    Box,
    Container,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';

import { deleteEvent } from 'api';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { H1, P } from 'baseline-ui/typography';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { GET_EVENT_LIST_START, SET_EVENT_FILTER_START } from 'stores/redux/actions/EventActions';
import {
    combineDateAndTime,
    getRelativeTime,
    formatDateRange,
    formatTimeRange,
} from 'common/utils/date/dateUtils';
import ModalEventConfirmDelete from './ModalEventConfirmDelete';

const getAdjustedEndTime = (startDate, startTime, endDate, endTime) => {
    let eventEnd = combineDateAndTime(endDate, endTime);
    const eventStart = combineDateAndTime(startDate, startTime);

    if (eventEnd.isBefore(eventStart)) {
        eventEnd = eventEnd.add(1, 'day');
    }
    return eventEnd;
};

const categorizeEvents = (events) => {
    const currentDateTime = dayjs();

    const filterAndSort = (filterFn, sortFn) => [...events].filter(filterFn).sort(sortFn);

    const getEventStart = (event) => combineDateAndTime(event.startDate, event.startTime);
    const getEventEnd = (event) =>
        getAdjustedEndTime(event.startDate, event.startTime, event.endDate, event.endTime);

    const isOngoing = (event) => {
        const start = getEventStart(event);
        const end = getEventEnd(event);
        return start.isBefore(currentDateTime) && end.isAfter(currentDateTime);
    };

    const isUpcoming = (event) => getEventStart(event).isAfter(currentDateTime);

    const isPast = (event) => getEventEnd(event).isBefore(currentDateTime);

    const ongoingEvents = filterAndSort(isOngoing, (a, b) =>
        getEventStart(b).diff(getEventStart(a)),
    );

    const upcomingEvents = filterAndSort(isUpcoming, (a, b) =>
        getEventStart(a).diff(getEventStart(b)),
    );

    const pastEvents = filterAndSort(isPast, (a, b) => getEventEnd(b).diff(getEventEnd(a)));

    return { ongoingEvents, upcomingEvents, pastEvents };
};

const EventList = ({ onFetchEventDetails, onAddEvent }) => {
    const { showModal } = useContext(ModalContext);
    const [tabIndex, setTabIndex] = useState(0);

    const businessId = useSelector((state) => state.BusinessFormReducer._id);
    const { eventList } = useSelector((state) => state.EventReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (businessId) {
            dispatch({ type: SET_EVENT_FILTER_START });
            dispatch({ type: GET_EVENT_LIST_START });
        }
    }, [businessId]);

    const { upcomingEvents, ongoingEvents, pastEvents } = categorizeEvents(eventList || []);

    const handleDelete = async (eventId) => {
        showModal(ModalEventConfirmDelete, {
            confirmDeleteCallback: async () => {
                try {
                    const encodedId = encodeURIComponent(eventId);
                    await deleteEvent(encodedId);
                    dispatch({ type: GET_EVENT_LIST_START });
                } catch (error) {
                    console.error('Error deleting event:', error.message);
                }
            },
        });
    };

    const renderEventList = (events) => (
        <>
            {events.length > 0 ? (
                <List>
                    {events.map((event) => {
                        let photos = [];
                        try {
                            photos =
                                typeof event.photos === 'string'
                                    ? JSON.parse(event.photos)
                                    : event.photos;
                        } catch (error) {
                            console.error('Failed to parse photos:', error);
                        }

                        return (
                            <React.Fragment key={event._id}>
                                <ListItem
                                    sx={{ pl: 0 }}
                                    secondaryAction={
                                        <Box display="flex" gap={1}>
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => onFetchEventDetails(event)}
                                                color="success"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDelete(event.entity_id)}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    }
                                >
                                    <Avatar
                                        alt={event.eventName}
                                        src={photos?.length > 0 ? photos[0].url : null}
                                        variant="rounded"
                                        sx={{
                                            borderRadius: 2,
                                            width: 88,
                                            height: 88,
                                            marginRight: 2,
                                            backgroundColor: !photos?.length && '#f0f0f0',
                                        }}
                                    >
                                        {!photos?.length && (
                                            <EventIcon sx={{ color: '#9e9e9e', fontSize: 48 }} />
                                        )}
                                    </Avatar>
                                    <ListItemText
                                        primary={event.eventName}
                                        secondary={
                                            <>
                                                <Box
                                                    display="flex"
                                                    sx={{ my: 1 }}
                                                    alignItems="center"
                                                >
                                                    <CalendarIcon
                                                        sx={{
                                                            fontSize: 16,
                                                            marginRight: 1,
                                                            color: 'text.secondary',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        {formatDateRange(
                                                            event.startDate,
                                                            event.endDate,
                                                        )}{' '}
                                                        |{' '}
                                                        {formatTimeRange(
                                                            event.startTime,
                                                            event.endTime,
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" mt={0.5}>
                                                    <TimeIcon
                                                        sx={{
                                                            fontSize: 16,
                                                            marginRight: 1,
                                                            color: 'text.primary',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        {getRelativeTime(
                                                            event.startDate,
                                                            event.startTime,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            ) : (
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2, textAlign: 'center' }}
                >
                    <FormattedMessage id="addBusiness.page.sections.events.noEvents" />
                </Typography>
            )}
        </>
    );

    return (
        <Container>
            <Box sx={{ p: 2 }}>
                <H1 color="primary">
                    <FormattedMessage id="addBusiness.page.sections.events.title" />
                </H1>
                <P>
                    <FormattedMessage id="addBusiness.page.sections.events.subtitle" />
                </P>
                <Button onClick={onAddEvent}>
                    <FormattedMessage id="addBusiness.page.sections.events.createNew" />
                </Button>
                <Tabs
                    value={tabIndex}
                    onChange={(_, value) => setTabIndex(value)}
                    TabIndicatorProps={{ sx: { height: '3px', bgcolor: 'primary.main' } }}
                    sx={{
                        mt: 3,
                        '& .MuiTab-root': {
                            fontSize: '1.1rem',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    <Tab
                        label={<FormattedMessage id="addBusiness.page.sections.events.ongoing" />}
                    />
                    <Tab
                        label={<FormattedMessage id="addBusiness.page.sections.events.upcoming" />}
                    />
                    <Tab label={<FormattedMessage id="addBusiness.page.sections.events.past" />} />
                </Tabs>
                <Box sx={{ mt: 3 }}>
                    {tabIndex === 0 && renderEventList(ongoingEvents)}
                    {tabIndex === 1 && renderEventList(upcomingEvents)}
                    {tabIndex === 2 && renderEventList(pastEvents)}
                </Box>
            </Box>
        </Container>
    );
};

export default EventList;
