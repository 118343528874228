import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import { SET_BUSINESS_FORM_DATA_START } from 'stores/redux/actions/BusinessFormActions';
import BaseCategories from './BaseCategories';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';

const BusinessCategories = () => {
    const dispatch = useDispatch();
    const { type } = useSelector(businessFormSelector);
    const selectedCategories = useSelector(businessCategoriesSelector);

    const onSubmit = (data) => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                selectedCategories: data,
                hasUnsavedChanges: true,
            },
        });
    };

    const onRemoveFilter = ({ selectedCategories, filterId }) => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                selectedCategories: categoriesAndFiltersUtils.removeFilterFromSelected(
                    selectedCategories,
                    filterId,
                ),
                hasUnsavedChanges: true,
            },
        });
    };

    const onRemoveCategory = ({ selectedCategories, categoryId }) => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                selectedCategories: categoriesAndFiltersUtils.removeCategoryFromSelected(
                    selectedCategories,
                    categoryId,
                ),
                hasUnsavedChanges: true,
            },
        });
    };

    const onRemoveSubcategory = ({ selectedCategories, subcategoryId }) => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                selectedCategories: categoriesAndFiltersUtils.removeSubcategoryFromSelected(
                    selectedCategories,
                    subcategoryId,
                ),
                hasUnsavedChanges: true,
            },
        });
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.categories.title" />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.categories.subtitle`} />
            </P>
            <div>
                <BaseCategories
                    selectedCategories={selectedCategories}
                    onSubmit={onSubmit}
                    onRemoveFilter={onRemoveFilter}
                    onRemoveCategory={onRemoveCategory}
                    onRemoveSubcategory={onRemoveSubcategory}
                />
            </div>
        </>
    );
};

export default BusinessCategories;
