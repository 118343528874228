import React, { useEffect } from 'react';
import { Col, Row } from 'baseline-ui/layout';
import { DefaultAlert } from 'baseline-ui/alert';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import {
    GET_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    SET_BUSINESS_FORM_DATA_START,
} from 'stores/redux/actions/BusinessFormActions';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { useNavigate } from 'react-router-dom';
import { isAdminSelector } from 'stores/redux/selectors/authenticationSelectors';
import { GET_BUSINESS_CATEGORIES_START } from 'stores/redux/actions/CategoriesActions';
import AdminBusinessManagerStatusContainer from './AdminBusinessStatusContainer';
import AdminBusinessStatusForm from './AdminBusinessStatusForm';
import AdminBusinessAdvertising from './AdminBusinessAdvertising';

const AdminBusinessStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { type } = useSelector(businessFormSelector);
    const isAdmin = useSelector(isAdminSelector);
    const businessData = useSelector(businessFormSelector);
    const isLoading = useProcessLoader({
        processNames: [GET_BUSINESS_FORM_PROCESS],
    });

    useEffect(() => {
        /** DEV: temp used by advertising to get business categories */
        if (type) {
            dispatch({
                type: GET_BUSINESS_CATEGORIES_START,
            });
        }
    }, [type]);

    const onAdvertisingSubmit = (values) => {
        const newBusinessData = {
            ...businessData,
            adminOnly: {
                ...businessData.adminOnly,
                advertise: {
                    ...values,
                },
            },
        };

        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: newBusinessData,
        });
        dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: { ...newBusinessData, navigate },
        });
    };

    if (!isAdmin) {
        return null;
    }

    if (isLoading) {
        return null;
    }

    const initialValues = {
        approval_status: businessData.approval_status,
        adminOnly: {
            /** Some businesses are currently returning a follow-up status of a string 'undefined' */
            followupStatus:
                !businessData.adminOnly?.followupStatus ||
                businessData.adminOnly?.followupStatus === 'undefined'
                    ? ''
                    : businessData.adminOnly?.followupStatus,
            privateCell: businessData.adminOnly?.privateCell || '',
            advertise: businessData.adminOnly?.advertise ?? false,
            advertisementPriority: businessData.adminOnly?.advertisementPriority || '',
            maxNotificationsPerWeek:
                businessData.adminOnly?.maxNotificationsPerWeek?.toString() || 0,
        },
    };

    return (
        <Row justifyContent="center">
            <Col xxs={30}>
                <AdminBusinessManagerStatusContainer>
                    <DefaultAlert inProp alertType="primaryMuted" appear={false}>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={(values) => {
                                dispatch({
                                    type: POST_BUSINESS_FORM_START,
                                    payload: { ...values, navigate },
                                });
                            }}
                        >
                            {() => <AdminBusinessStatusForm />}
                        </Formik>
                        <AdminBusinessAdvertising onSubmit={onAdvertisingSubmit} />
                    </DefaultAlert>
                </AdminBusinessManagerStatusContainer>
            </Col>
        </Row>
    );
};

export default AdminBusinessStatus;
