import './init';
import * as yup from 'yup';
import { RESET_PASSWORD_CODE_LENGHT } from 'common/consts/consts';
import validationMessages from './validationMessages';
import { MOBILE_PHONE_NUMBER_MATCH_REGEX } from '../constants/validationConstants';

const fieldSchema = {
    variantId: yup.string().emptyToNull(),
    checkbox: yup.boolean().isTrue(),
    email: yup.string().emptyToNull().email().max(320),
    password: yup.string().emptyToNull().min(6),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password'), null], validationMessages.confirmPassword.sameAs),
    passwordCode: yup
        .string()
        .emptyToNull()
        .length(RESET_PASSWORD_CODE_LENGHT, validationMessages.passwordCode.length)
        .matches(/^\d+$/, {
            message: validationMessages.passwordCode.matches,
            excludeEmptyString: true,
        }),
    username: yup
        .string()
        .emptyToNull()
        .min(1)
        .max(320)
        .matches(/^[A-Za-z0-9_\-.]+$/, {
            message: validationMessages.username.matches,
            excludeEmptyString: true,
        }),
    nickname: yup
        .string()
        .emptyToNull()
        .min(2)
        .max(20)
        .matches(/^[A-Za-z0-9_\-.]+$/, {
            message: validationMessages.nickname.matches,
            excludeEmptyString: true,
        }),
    fullName: yup
        .string()
        .emptyToNull()
        .min(2)
        .max(30)
        .matches(/^[a-zA-Z\s]+$/, {
            message: validationMessages.fullName.matches,
            excludeEmptyString: true,
        }),
    firstName: yup.string().emptyToNull().min(2).max(30),
    middleName: yup.string().emptyToNull().min(2).max(30),
    lastName: yup.string().emptyToNull().min(2).max(30),
    address: yup
        .string()
        .emptyToNull()
        .min(5)
        .max(50)
        .matches(/^[a-zA-Z0-9](([a-zA-Z0-9\s])(?!\s{2}))+$/, {
            message: validationMessages.address.matches,
            excludeEmptyString: true,
        }),
    countryISOCode: yup.string().emptyToNull(),
    state: yup.string().emptyToNull(),
    city: yup
        .string()
        .emptyToNull()
        .min(3)
        .max(20)
        .matches(/^[a-zA-Z]((([a-zA-Z\s])(?!\s{2}))?)+$/, {
            message: validationMessages.city.matches,
            excludeEmptyString: true,
        }),
    postalCode: (length) =>
        yup
            .string()
            .emptyToNull()
            .min(length)
            .max(length)
            .matches(/^[0-9]+$/, {
                message: validationMessages.postalCode.matches,
                excludeEmptyString: true,
            }),
    phoneNumber: yup.string().emptyToNull().matches(MOBILE_PHONE_NUMBER_MATCH_REGEX, {
        message: validationMessages.phoneNumber.matches,
        excludeEmptyString: true,
    }),
    citizenship: yup.string().emptyToNull(),
    language: yup.string().emptyToNull(),
    currency: yup.string().emptyToNull(),
    gender: yup.string().emptyToNull(),
    securityAnswer: yup.string().emptyToNull().min(2).max(20),
    day: yup.number().min(1, validationMessages.day.min).max(31, validationMessages.day.max),
    month: yup.number().min(1, validationMessages.month.min).max(12, validationMessages.month.max),
    year: yup
        .number()
        .min(1900, validationMessages.year.min)
        .max(2100, validationMessages.year.max),
    url: yup.string().url(validationMessages.url.matches),
    date: yup.date(),
    zipCode: yup.string(),
    role: yup.string(),
};

export default fieldSchema;
