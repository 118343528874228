import React from 'react';
import PropTypes from 'prop-types';
import { FieldContainer, FormInput, FormMaskedInput, FormTextarea } from 'baseline-ui/form';
import { Form, Field, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import { Modal } from 'baseline-ui/modal';
import { MOBILE_PHONE_NUMBER_MASK } from 'common/consts/consts';
import { useSelector } from 'react-redux';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';

const BusinessAddressProblemModalForm = ({
    onRequestClose,
    name,
    formattedAddress,
    phone,
    ...props
}) => {
    const intl = useIntl();
    const { handleSubmit, setFieldValue } = useFormikContext();
    const { type } = useSelector(businessFormSelector);

    return (
        <Modal
            title={intl.formatMessage({ id: 'addBusiness.modal.businessAddressProblem.title' })}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.businessAddressProblem.footerAction.notNow.button.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => onRequestClose(),
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.businessAddressProblem.footerAction.sendRequest.button.label',
                    }),
                    onClick: () => handleSubmit(),
                    type: 'submit',
                },
            ]}
            {...props}
        >
            <ContentRenderer
                source={intl.formatMessage({
                    id: `${type}.modal.businessAddressProblem.content.md`,
                })}
            />
            <Form noValidate>
                <FieldContainer>
                    <Field
                        component={FormInput}
                        label={intl.formatMessage({
                            id: `${type}.form.name.label`,
                        })}
                        name="name"
                        id="name"
                        type="text"
                    />
                </FieldContainer>
                <FieldContainer>
                    <Field
                        component={FormMaskedInput}
                        label={intl.formatMessage({
                            id: `${type}.form.phone.label`,
                        })}
                        name="phone"
                        id="phone"
                        mask={MOBILE_PHONE_NUMBER_MASK}
                        onAccept={(value, mask) => {
                            mask.updateValue();
                            setFieldValue('phone', value);
                        }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <Field
                        component={FormInput}
                        label={intl.formatMessage({
                            id: `${type}.form.formattedAddress.label`,
                        })}
                        name="formattedAddress"
                        id="formattedAddress"
                        type="text"
                        placeholder=""
                    />
                </FieldContainer>
                <FieldContainer>
                    <Field
                        component={FormTextarea}
                        label={intl.formatMessage({
                            id: 'addBusiness.modal.businessAddressProblem.form.message.label',
                        })}
                        name="message"
                        id="message"
                    />
                </FieldContainer>
            </Form>
        </Modal>
    );
};

BusinessAddressProblemModalForm.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    name: PropTypes.string,
    phone: PropTypes.string,
    formattedAddress: PropTypes.string,
};

BusinessAddressProblemModalForm.defaultProps = {
    name: undefined,
    phone: undefined,
    formattedAddress: undefined,
};

export default BusinessAddressProblemModalForm;
