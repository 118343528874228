import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useIntl } from 'react-intl';
import { has, isEmpty } from 'lodash';
import { FeedbackContext } from 'baseline-ui/feedback';
import { PROCESS_RESET } from 'stores/redux/actions/CommonActions';

const useProcessError = ({ processNames, withFeedback }) => {
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const dispatch = useDispatch();
    const intl = useIntl();

    const selectProcessesSelector = (state) => state.ErrorReducer;

    const selectErrorProcesses = createSelector(selectProcessesSelector, (processes) => {
        const errorProcesses = [];
        processNames.forEach((processName) => {
            if (has(processes[processName], 'code')) {
                errorProcesses.push({
                    [processName]: processes[processName].code?.errorCode,
                });
            }
        });

        return errorProcesses;
    });

    const errorProcesses = useSelector(selectErrorProcesses);
    const isError = !isEmpty(errorProcesses);

    useEffect(() => {
        if (withFeedback && isError) {
            errorProcesses.forEach((errorProcess) => {
                const processName = Object.keys(errorProcess).toString();
                const processErrorCode = Object.values(errorProcess).toString();

                const translationIdExists =
                    !!intl.messages[
                        `process.error.${processName}.${processErrorCode}.feedback.label`
                    ];

                setFeedbackOnStage({
                    label: translationIdExists
                        ? intl.formatMessage({
                              id: `process.error.${processName}.${processErrorCode}.feedback.label`,
                          })
                        : intl.formatMessage({
                              id: 'process.error.default.feedback.label',
                          }),
                    type: 'danger',
                    show: true,
                    timeout: 3200,
                });
                dispatch({
                    type: `${processName}_${PROCESS_RESET}`,
                });
            });
        }
    }, [isError, withFeedback]);

    return isError;
};

export default useProcessError;
