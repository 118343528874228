import { useEffect } from 'react';
import loadScript from '../utils/loadScript';

/** DEV
 * Discuss if this can be considered to replace the current opinionated useGoogleMapsAutocompleted
 */
const useGoogleMapsAutocompletePlaces = ({ ref, types = 'address', queryCallback }) => {
    const handleGoogleMapsAutocomplete = () => {
        if (typeof google === 'object') {
            const autoComplete = new window.google.maps.places.Autocomplete(ref.current, {
                types: [...types],
                componentRestrictions: { country: 'us' },
            });
            autoComplete.setFields(['address_components', 'formatted_address']);
            autoComplete.addListener('place_changed', async () => {
                const addressObject = await autoComplete.getPlace();
                console.log('addressObject', addressObject);
                const cityName = addressObject?.address_components.find((res) =>
                    res.types.includes('locality'),
                );

                const stateName = addressObject?.address_components.find((res) =>
                    res.types.includes('administrative_area_level_1'),
                );

                const country = addressObject?.address_components.find((res) =>
                    res.types.includes('country'),
                );
                const query = `${cityName?.long_name}, ${stateName?.short_name}, ${country?.short_name}`;
                queryCallback(query);
            });
        }
    };

    useEffect(() => {
        loadScript({
            url: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRUvvw2xf7i1p7s7focKGh46eAFdOLxrQ&libraries=places&callback=Function.prototype`,
            callback: handleGoogleMapsAutocomplete,
            id: 'googleMaps',
        });
    }, []);
};

export default useGoogleMapsAutocompletePlaces;
