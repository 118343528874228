/* eslint-disable react/prop-types */
import React from 'react';
import { H3, H5, H6, RouterA } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import { GaIcon } from 'baseline-ui/icon';
import businessUtils from 'common/utils/businessUtils';
import StatusTag from 'components/Tags/StatusTag';
import { EDIT_FAVEPAGE_PATH } from 'common/consts/pathnames';
import EntityTypeTag from 'components/EntityTypeTag';
import MyBusinessesListItemContainer from './MyBusinessesListItemContainer';
import MyBusinessesListItemImage from './MyBusinessesListItemImage';
import BusinessListItemLabelContainer from './BusinessListItemLabelContainer';
import BusinessListItemLabelIconContainer from './BusinessListItemLabelIconContainer';
import BusinessListItemFormattedAddress from './BusinessListItemFormattedAddress';
import AdminBusinessStatusOverview from './AdminBusinessStatusOverview';
import BusinessListItemStatusTagContainer from './BusinessListItemStatusTagContainer';

const MyBusinessesListItem = ({ business }) => {
    const {
        _id,
        name,
        ownerFirstName,
        ownerLastName,
        formattedAddress,
        phone,
        email,
        website,
        photos,
        approval_status: approvalStatus,
        adminOnly,
        type,
        position,
    } = business;
    let photo = photos?.length > 0 ? photos[0].url : undefined;
    
    const typeAsText = type?.toLocaleLowerCase();
    if (typeAsText === 'movie' || typeAsText === 'show') {
        photo = business?.image;
    }

    if (typeAsText == 'podcast') {
        photo = business?.images[0]?.url;
    }

    if (typeAsText == 'book') {
        photo = business?.book_cover;
    }

    let details = [];

    if (!website || website == 'null' || website == 'undefined') {
        details = [
            { item: phone, icon: 'phone' },
            { item: email, icon: 'mail' },
        ];
    } else {
        details = [
            { item: phone, icon: 'phone' },
            { item: email, icon: 'mail' },
            { item: website, icon: 'globe' },
        ];
    }

    return (
        <RouterA to={`${EDIT_FAVEPAGE_PATH}/${encodeURIComponent(_id)}`}>
            <MyBusinessesListItemContainer>
                <Row>
                    <Col xxs={30} sm={10} xl={8}>
                        <MyBusinessesListItemImage image={photo} type={type} />
                    </Col>
                    <Col xxs={30} sm={20} xl={22}>
                        <Row isFullHeight flexWrap="wrap">
                            <Col xxs={30}>
                                <Row flexWrap="wrap">
                                    <Col xxs={30}>
                                        <Row
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            flexWrap="nowrap"
                                        >
                                            <Col>
                                                <EntityTypeTag type={type} />
                                                <H3 color="secondary">{name}</H3>
                                                {businessUtils.positionByEntityType({
                                                    position,
                                                    entityType: type,
                                                }) && <H6 color="muted">{position}</H6>}
                                            </Col>
                                            <Col>
                                                <BusinessListItemStatusTagContainer>
                                                    <StatusTag status={approvalStatus} />
                                                </BusinessListItemStatusTagContainer>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xxs={30}>
                                        <H5>
                                            {businessUtils.fullName(ownerFirstName, ownerLastName)}
                                        </H5>
                                    </Col>
                                    <Col xxs={30}>
                                        <Row flexWrap="wrap" justifyContent="space-between">
                                            <Col xxs={30} sm={15} md={14}>
                                                <BusinessListItemFormattedAddress
                                                    formattedAddress={formattedAddress}
                                                />
                                            </Col>
                                            <Col xxs={30} sm={15} md={14}>
                                                {details.map((detailsItem) => {
                                                    const { item, icon } = detailsItem;
                                                    if (!item) {
                                                        return null;
                                                    }
                                                    return (
                                                        <H6 key={item}>
                                                            <BusinessListItemLabelContainer>
                                                                <BusinessListItemLabelIconContainer>
                                                                    <GaIcon icon={icon} size={14} />
                                                                </BusinessListItemLabelIconContainer>
                                                                {item}
                                                            </BusinessListItemLabelContainer>
                                                        </H6>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <AdminBusinessStatusOverview adminOnly={adminOnly} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </MyBusinessesListItemContainer>
        </RouterA>
    );
};

export default MyBusinessesListItem;
