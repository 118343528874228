import { media } from 'baseline-ui/layout';
import {
    PREVIEW_HEADER_HEIGHT,
    PREVIEW_HEADER_HEIGHT_SM,
    PREVIEW_MAX_HEIGHT,
    PREVIEW_MAX_HEIGHT_SM,
} from 'common/consts/previewConstants';
import styled from 'styled-components';

const FavePagePreviewContentWrapper = styled.div`
    background-color: #fff;
    height: calc(${PREVIEW_MAX_HEIGHT}px - ${PREVIEW_HEADER_HEIGHT}px);
    padding-top: ${PREVIEW_HEADER_HEIGHT}px;

    ${media.sm`
        height: calc(${PREVIEW_MAX_HEIGHT_SM}px - ${PREVIEW_HEADER_HEIGHT_SM}px);
        padding-top: ${PREVIEW_HEADER_HEIGHT_SM}px;
    `}
`;

export default FavePagePreviewContentWrapper;
