import { Typography, Box, Chip } from '@mui/material';

const AwardsTabContent = ({ awards }) => {
    if (!awards || !awards.length) return null;

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Awards & Nominations
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {awards.map((award, index) => (
                    <Box
                        key={`award-${index}`}
                        sx={{
                            p: 1.5,
                            borderRadius: 1,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
                        }}
                    >
                        <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                            {award.name}
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Chip
                                label={award.type || 'Award'}
                                size="small"
                                color={award.type === 'Winner' ? 'primary' : 'default'}
                                variant="outlined"
                                sx={{ mr: 1 }}
                            />
                            <Typography variant="caption" color="text.secondary">
                                {award.year}
                            </Typography>
                        </Box>

                        {award.category && (
                            <Typography variant="body2" color="text.secondary">
                                {award.category}
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default AwardsTabContent; 