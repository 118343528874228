import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RenderButton } from 'baseline-ui/button';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import Container from './Container';
import ContainerActions from './ContainerActions';
import Action from './Action';
import overlayPropTypes from '../../../prop-types/overlayPropTypes';

const OverlayFooter = ({
    footerPre,
    footerPost,
    footerActions,
    handleOnDismiss,
    customBackgroundColor,
}) => {
    const { themeMode } = useContext(ThemeModeContext);

    return (
        <Container themeMode={themeMode} customBackgroundColor={customBackgroundColor}>
            {footerPre && footerPre}
            {footerActions && (
                <ContainerActions >
                    {footerActions.map((item, index) => {
                        const {
                            onClick,
                            isExternal,
                            path,
                            label,
                            skin,
                            icon,
                            btnType,
                            size,
                            onClickDismiss,
                            disabled,
                            ...rest
                        } = item;
                        return (
                            <Action key={index } >
                                <RenderButton
                                    onClick={onClick}
                                    isExternal={isExternal}
                                    path={path}
                                    skin={skin}
                                    icon={icon}
                                    btnType={btnType}
                                    size={size}
                                    label={label}
                                    onClickLinkCallback={() => {
                                        if (onClickDismiss) {
                                            setTimeout(() => {
                                                handleOnDismiss();
                                            }, 100);
                                        }
                                    }}
                                    disabled={disabled}
                                    {...rest}
                                />
                            </Action>
                        );
                    })}
                </ContainerActions>
            )}
            {footerPost && footerPost}
        </Container>
    );
};

OverlayFooter.propTypes = {
    footerPre: PropTypes.node,
    footerPost: PropTypes.node,
    footerActions: overlayPropTypes.overlayFooterActions,
    handleOnDismiss: PropTypes.func.isRequired,
    customBackgroundColor: PropTypes.string,
};

OverlayFooter.defaultProps = {
    footerActions: null,
    footerPre: null,
    footerPost: null,
    customBackgroundColor: undefined,
};

export default OverlayFooter;
