import {
    GET_BETA_SGNUPS_SUCCESS,
    RESET_NEXT_PAGE_START,
    SET_NEXT_PAGE_START,
    SET_PREV_PAGE_START,
} from 'stores/redux/actions/BetaSignupActions';

const BetaSignupReducer = (state = { page: 0, hasNext: true, signups: [] }, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PREV_PAGE_START: {
            return {
                ...state,
                page: state.page - 1,
            };
        }
        case SET_NEXT_PAGE_START: {
            return {
                ...state,
                page: state.page + 1,
            };
        }
        case RESET_NEXT_PAGE_START: {
            return {
                ...state,
                page: 0,
                signups: [],
            };
        }
        case GET_BETA_SGNUPS_SUCCESS: {
            const newSignups = [...state.signups];
            payload.forEach((signup) => {
                const index = newSignups.findIndex((s) => s._id === signup._id);
                if (index !== -1) {
                    newSignups[index] = signup;
                } else {
                    newSignups.push(signup);
                }
            });
            return {
                ...state,
                signups: newSignups,
                hasNext: payload.length > 0,
            };
        }
        default: {
            return { ...state };
        }
    }
};
export default BetaSignupReducer;
