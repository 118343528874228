import { call, cancel, put, takeEvery } from 'redux-saga/effects';
import ModalAccessRightAddUser from 'screens/BusinessForm/ModalAccessRightAddUser';
import { PROCESS_ERROR } from 'stores/redux/actions/CommonActions';
import {
    GetBusinessPermissions,
    PostAddBussinessPermission,
    PostRemoveBussinessPermission,
    GetUserBusinessRights
} from 'api';
import {
    ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS_ERROR,
    ADD_BUSINESS_ACCESS_RIGHTS_USER_START,
    GET_BUSINESS_ACCESS_RIGHTS_PROCESS,
    GET_BUSINESS_ACCESS_RIGHTS_START,
    GET_BUSINESS_ACCESS_RIGHTS_SUCCESS,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START,
    GET_PAGE_ACCESS_RIGHTS_USER_PROCESS,
    GET_PAGE_ACCESS_RIGHTS_USER_START,
    GET_PAGE_ACCESS_RIGHTS_USER_SUCCESS
} from '../actions/BusinessAccessRightsActions';
import genericSagaHandler from './CommonSaga';

function* getBusinessAccessRightsSaga({ payload }) {
    yield genericSagaHandler(
        GET_BUSINESS_ACCESS_RIGHTS_PROCESS,
        function* getBusinessAccessRights() {
            const accessRights = yield GetBusinessPermissions(payload);
            yield put({
                type: GET_BUSINESS_ACCESS_RIGHTS_SUCCESS,
                payload: accessRights,
            });
        },
    );
}

function* addBusinessAccessRightsSaga({ payload }) {
    yield genericSagaHandler(
        ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
        function* addBusinessAccessRights() {
            try {
                yield PostAddBussinessPermission(payload);
                yield call(getBusinessAccessRightsSaga, { payload: payload.entityid });
            } catch (error) {
                /**
                 * Error code USER_NOT_FOUND
                 * User does not exist, show modal with information
                 */
                if (error.code === 'USER_NOT_FOUND') {
                    yield put({
                        type: ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS_ERROR,
                        payload: null,
                    });
                    payload.showModal(ModalAccessRightAddUser, {
                        processName: ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
                        code: error.code,
                        errorCode: error.errorCode,
                        emailAddress: payload.email,
                    });
                } else {
                    yield put({
                        type: ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS_ERROR,
                        payload: { code: error },
                    });
                }

                yield put({
                    type: ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
                    payload: PROCESS_ERROR,
                });
                yield cancel();
            }
        },
    );
}

function* removeBusinessAccessRightsSaga({ payload }) {
    yield genericSagaHandler(
        REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
        function* removeBusinessAccessRights() {
            yield PostRemoveBussinessPermission(payload);
            yield call(getBusinessAccessRightsSaga, { payload: payload.entityid });
        },
    );
}

function* getBusinessUserAccessRightsSaga({ payload }) {
    yield genericSagaHandler(
        GET_PAGE_ACCESS_RIGHTS_USER_PROCESS,
        function* getBusinessAccessRights() {
            const accessRights = yield GetUserBusinessRights(payload);
            yield put({
                type: GET_PAGE_ACCESS_RIGHTS_USER_SUCCESS,
                payload: accessRights,
            });
        },
    );
}

export default function* businessAccessRightsSagas() {
    yield takeEvery(GET_BUSINESS_ACCESS_RIGHTS_START, getBusinessAccessRightsSaga);
    yield takeEvery(ADD_BUSINESS_ACCESS_RIGHTS_USER_START, addBusinessAccessRightsSaga);
    yield takeEvery(REMOVE_BUSINESS_ACCESS_RIGHTS_USER_START, removeBusinessAccessRightsSaga);
    yield takeEvery(GET_PAGE_ACCESS_RIGHTS_USER_START, getBusinessUserAccessRightsSaga);
}
