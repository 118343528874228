import React from 'react';
import { useSelector } from 'react-redux';
import { H1, P } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { businessFormSelector } from 'stores/redux/selectors/businessFormSelectors';
import AddBusinessAdditionalInformationForm from './AddBusinessAdditionalInformationForm';

const AdditionalInformation = () => {
    const { description, type } = useSelector(businessFormSelector);

    const initialValues = {
        description,
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.additionalInformation.title" />
            </H1>
            <P>
                <FormattedMessage id={`${type}.page.sections.additionalInformation.subtitle`} />
            </P>
            <Formik initialValues={initialValues}>
                {() => <AddBusinessAdditionalInformationForm />}
            </Formik>
        </>
    );
};

export default AdditionalInformation;
