/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import {
    TierControlsArea,
    TierControlDiscardButton,
    TierControlSaveButton,
    TierControlEditButton,
    TierControlAddButton,
    TierControlsAreaSeparator,
    TierEditControl,
    TierLabel,
    TierExpandButtonComponent,
    TierAddControls,
    TierControlButton,
    TierControlKeywordsButton,
} from './components';
import { isEmpty } from 'lodash';

export const TierControls = ({
    show,
    disabled,
    edit,
    add,
    save,
    discard,
    onEdit,
    onAdd,
    onSave,
    onDiscard,
    hidden,
    onHide,
    onUnhide,
    onShowKeywords,
}) => {
    const showSeparator = !!((discard || save) && (add || edit));
    return (
        <TierControlsArea show={!!show}>
            {discard && (
                <TierControlDiscardButton disabled={disabled} onClick={onDiscard}>
                    Discard
                </TierControlDiscardButton>
            )}
            {save && (
                <TierControlSaveButton disabled={disabled} onClick={onSave}>
                    Save
                </TierControlSaveButton>
            )}
            {showSeparator && <TierControlsAreaSeparator />}
            {edit && (
                <TierControlEditButton disabled={disabled} onClick={onEdit}>
                    Edit
                </TierControlEditButton>
            )}
            {add && (
                <TierControlAddButton disabled={disabled} onClick={onAdd}>
                    Add
                </TierControlAddButton>
            )}

            {hidden && <TierControlButton onClick={() => onUnhide()}>UnHide</TierControlButton>}

            {!hidden && <TierControlButton onClick={() => onHide()}>Hide</TierControlButton>}

            {edit && (
                <TierControlKeywordsButton onClick={onShowKeywords}>
                    Keywords
                </TierControlKeywordsButton>
            )}
        </TierControlsArea>
    );
};

export const TierEditableName = ({ tier, expandable, editing, htmlFor, withTiers, onClick }) => {
    if (editing) {
        const onChange = (evt) => {
            const { _id } = tier;
            withTiers.update(_id, {
                working: {
                    name: evt.target.value,
                },
            });
        };
        return (
            <TierLabel>
                Name:
                <TierEdit
                    initialValue={tier.name}
                    onChange={onChange}
                    onBlur={console.log('blur name', tier.name)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            const { working, ...rest } = tier;
                            const item = {
                                ...rest,
                                ...working,
                            };
                            withTiers.saveItemAsync(item);
                        }
                    }}
                />
            </TierLabel>
        );
    }

    if (!isEmpty(tier.label)) {
        return null;
    }
    return (
        <TierLabel htmlFor={htmlFor} onClick={onClick}>
            {tier.name}
        </TierLabel>
    );
};

export const TierEditableLabel = ({ tier, expandable, editing, htmlFor, withTiers, onClick }) => {
    if (editing) {
        const onChange = (evt) => {
            const { _id } = tier;
            withTiers.update(_id, {
                working: {
                    label: evt.target.value,
                },
            });
        };
        return (
            <TierLabel>
                Label:
                <TierEdit
                    focusOnRender={false}
                    initialValue={tier.label}
                    onChange={onChange}
                    onBlur={console.log('blur label', tier.label)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            const { working, ...rest } = tier;
                            const item = {
                                ...rest,
                                ...working,
                            };
                            withTiers.saveItemAsync(item);
                        }
                    }}
                />
            </TierLabel>
        );
    }
    return (
        <TierLabel htmlFor={htmlFor} onClick={onClick}>
            {tier.label}
        </TierLabel>
    );
};

export const TierEdit = ({ initialValue, onChange, onKeyDown, onBlur, focusOnRender = true }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (focusOnRender) {
            inputRef.current.focus();
        }
    }, [initialValue]);
    return (
        <TierEditControl
            defaultValue={initialValue}
            ref={inputRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
        />
    );
};

export const TierExpandButton = ({ id, expanded, expandable, onExpand }) => {
    let icon;
    if (!expandable) {
        icon = 'filter';
    } else if (expanded) {
        icon = 'minus';
    } else {
        icon = 'plus';
    }
    const onClick = !expandable ? undefined : onExpand;
    return <TierExpandButtonComponent id={id} icon={icon} onClick={onClick} />;
};

export const TierAddButtons = ({ tier, validChildTypes, onAdd }) => {
    // headers and subcategories cannot be to the child at the same level
    // this is not a technical limitation, this is based on the ui on mobile app and logical split
    // filters are children of headers, and filter is the final child type, there is nothing beyond filter, allowing subcats along with headers would not follow this rule.
    // because of that we need to check if there are any headers in the children, if there are, we should not allow subcats to be added and vice versa.
    const hasHeaders = tier.resources?.some((r) => r.type === 'header');
    const hasSubcats = tier.resources?.some((r) => r.type === 'subcategory');
    if (hasHeaders) {
        validChildTypes = validChildTypes.filter((t) => t !== 'subcategory');
    }
    if (hasSubcats) {
        validChildTypes = validChildTypes.filter((t) => t !== 'header');
    }

    return (
        <TierAddControls>
            {validChildTypes.map((type) => {
                const onClick = (evt) => {
                    onAdd(evt, type);
                };
                const key = `${tier._id}_${type}`;
                const name = type.slice(0, 1).toUpperCase() + type.slice(1);
                return (
                    <TierControlAddButton key={key} onClick={onClick}>
                        Add {name}
                    </TierControlAddButton>
                );
            })}
        </TierAddControls>
    );
};
