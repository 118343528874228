import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { originalProductSelector, productToEditSelector } from 'stores/redux/selectors/productSelectors';
import {
    POST_SAVE_PRODUCT_PROCESS,
    POST_SAVE_PRODUCT_START,
} from 'stores/redux/actions/EditProductActions';
import useProcessComplete from 'common/hooks/useProcessComplete';
import editProductSchema from 'schema/editProductSchema';
import ModalEditProductForm from './ModalEditProductForm';
import ModalEditProductHeader from './ModalEditProductHeader';

const ModalEditProduct = ({ ...props }) => {
    const dispatch = useDispatch();
    const product = useSelector(productToEditSelector);
    const originalProduct = useSelector(originalProductSelector);
    const intl = useIntl();

    const { name, price, description, totalFaves } = product;
    const { name: originalName } = originalProduct;

    const initialValues = {
        name,
        price,
        description,
    };

    useProcessComplete({
        processNames: [POST_SAVE_PRODUCT_PROCESS],
        withFeedback: true,
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={editProductSchema}
            onSubmit={() => {
                dispatch({ type: POST_SAVE_PRODUCT_START });
            }}
        >
            {() => (
                <ModalEditProductForm
                    customHeader={
                        <ModalEditProductHeader
                            title={intl.formatMessage(
                                {
                                    id: 'addBusiness.modal.editProduct.title',
                                },
                                { productName: originalName },
                            )}
                            totalFaves={totalFaves}
                        />
                    }
                    {...props}
                />
            )}
        </Formik>
    );
};

export default ModalEditProduct;
