import React from 'react';
import { P, Small, H2, H1 } from 'baseline-ui/typography';
import { isEmpty, reject } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_BUSINESS_FORM_DATA_START } from 'stores/redux/actions/BusinessFormActions';
import { Col, Row } from 'baseline-ui/layout';
import { BUSINESS_LINKS_MAX_COUNT } from 'common/consts/consts';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { useMessageBox } from 'components/MessageBox';
import AddBusinessLinksListContainer from './AddBusinessLinksListContainer';
import AddBusinessLinksListItem from './AddBusinessLinksListItem';
import { Button } from 'baseline-ui/button';
import { LINK_OTHER, LINK_WEBSITE } from 'common/consts/linksConstants';

const AddBusinessLinksList = () => {
    const dispatch = useDispatch();
    const { links } = useSelector(({ BusinessFormReducer }) => BusinessFormReducer);
    const intl = useIntl();
    const messageBox = useMessageBox({
        messageId: 'addBusiness.modal.links.message',
        show: {
            yes: true,
            no: true,
        },
        onResponse(answer, data) {
            if (answer === 'yes') {
                removeLink(data);
            }
            return true;
        },
    });

    const removeLink = (data) => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: reject(links, data),
                hasUnsavedChanges: true,
            },
        });
    };

    const handleSave = (updatedUrl, updatedCaption, originalLink) => {
        const updatedLinks = links.map((link) =>
            link.url === originalLink.url && link.caption === originalLink.caption
                ? { ...link, url: updatedUrl, caption: updatedCaption }
                : link,
        );
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: updatedLinks,
                hasUnsavedChanges: true,
            },
        });
    };

    const onSortEnd = (oldIndex, newIndex) => {
        const sortedLinks = arrayMove(links, oldIndex, newIndex);
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: sortedLinks,
                hasUnsavedChanges: true,
            },
        });
    };


    const websiteLinks = links.filter((link) => link.type === LINK_WEBSITE);

    if (websiteLinks.length === 0) {
        const newLink = { url: '', caption: '', type: LINK_WEBSITE };
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: [...links, newLink],
                hasUnsavedChanges: false,
            },
        });
    }
    const otherLinks = links.filter((link) => link.type === LINK_OTHER);

    const allowDrag = otherLinks.length > 1;

    const addNewLink = () => {
        const newLink = { url: '', caption: '', type: LINK_OTHER };
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                links: [...links, newLink],
                hasUnsavedChanges: true,
            },
        });
    };

    return (
        <AddBusinessLinksListContainer>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.links.title" />
            </H1>

            <P lead>
                <strong>
                    <FormattedMessage id="businessScreen.links.list.websiteAddress" />
                </strong>
            </P>
            {websiteLinks.length > 0 && (
                <>
                    <SortableList
                        style={{
                            marginBottom: '16px',
                            borderBottom: '1px solid rgb(229, 229, 229)',
                        }}
                        allowDrag={false}
                        onSortEnd={onSortEnd}
                    >
                        {websiteLinks.map((link, index) => (
                            <SortableItem key={`${link.url}-${index}`}>
                                <AddBusinessLinksListItem
                                    isWebsiteAddress={link.type === LINK_WEBSITE}
                                    isFirst={index === 0}
                                    isLast={index === websiteLinks.length - 1}
                                    url={link.url}
                                    caption={link.caption}
                                    onClick={messageBox.handleShow({ url: link.url })}
                                    onSave={(updatedUrl, updatedCaption) =>
                                        handleSave(updatedUrl, updatedCaption, link)
                                    }
                                    allowDrag={false}
                                    actionMode="edit"
                                    hideCaption={true}
                                />
                            </SortableItem>
                        ))}
                    </SortableList>
                </>
            )}

            <P lead>
                <strong>
                    <FormattedMessage id="formInquiry.form.typeOfInquire.options.OTHER.label" />
                </strong>
            </P>

            {otherLinks.length > 0 && (
                <>
                    <SortableList allowDrag={allowDrag} onSortEnd={onSortEnd}>
                        {otherLinks.map((link, index) => (
                            <SortableItem key={`${link.url}-${index}`}>
                                <AddBusinessLinksListItem
                                    isWebsiteAddress={link.type === LINK_WEBSITE}
                                    isFirst={index === 0}
                                    isLast={index === otherLinks.length - 1}
                                    url={link.url}
                                    caption={link.caption}
                                    onClick={messageBox.handleShow({ url: link.url })}
                                    onSave={(updatedUrl, updatedCaption) =>
                                        handleSave(updatedUrl, updatedCaption, link)
                                    }
                                    allowDrag={allowDrag && otherLinks.length > 1}
                                    cancelable={true}
                                    actionMode={!link.url && !link.caption ? 'new' : 'view'}
                                    links={links}
                                    onCancel={(url) => {
                                        if (!url) {
                                            removeLink({ url });
                                        }
                                    }}
                                />
                            </SortableItem>
                        ))}
                    </SortableList>
                </>
            )}

            <div style={{ display: 'flex', alignItems: 'center', gap: 14, marginTop: '1rem' }}>
                <Button
                    type="button"
                    onClick={addNewLink}
                    disabled={links.length >= BUSINESS_LINKS_MAX_COUNT}
                >
                    <FormattedMessage id="addBusiness.page.sections.links.form.addOtherLink.label" />
                </Button>

                <Small>
                    <FormattedMessage
                        id="addBusiness.page.sections.links.list.count.label"
                        values={{ count: links.length, maxCount: BUSINESS_LINKS_MAX_COUNT }}
                    />
                </Small>
            </div>
        </AddBusinessLinksListContainer>
    );
};

export default AddBusinessLinksList;
