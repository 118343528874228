import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    GetPromotionNotifications,
    GetUserNotifications,
    PostPromotionPush,
    PostSystemPush,
} from 'api';
import {
    GET_PROMO_NOTIFICATIONS_PROCESS,
    GET_PROMO_NOTIFICATIONS_START,
    GET_PROMO_NOTIFICATIONS_SUCCESS,
    GET_SYSTEM_NOTIFICATIONS_PROCESS,
    GET_SYSTEM_NOTIFICATIONS_START,
    GET_SYSTEM_NOTIFICATIONS_SUCCESS,
    POST_PROMOTION_NOTIFICATION_PROCESS,
    POST_PROMOTION_NOTIFICATION_START,
    POST_SYSTEM_NOTIFICATION_PROCESS,
    POST_SYSTEM_NOTIFICATION_START,
    RESET_PROMO_NOTIFICATIONS_START,
    SET_PROMO_NOTIFICATIONS_NEXT_PAGE_PROCESS,
    SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START,
    SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
    SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_PROCESS,
    SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START,
    SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
} from '../actions/PushActions';
import genericSagaHandler from './CommonSaga';

function* postSystemNotificationSaga({ payload }) {
    yield genericSagaHandler(POST_SYSTEM_NOTIFICATION_PROCESS, function* () {
        const { pushMessageTitle: title, pushMessage: body } = payload;
        yield PostSystemPush({
            title,
            body,
        });
    });
}

function* postPromotionNotificationSaga({ payload }) {
    yield genericSagaHandler(POST_PROMOTION_NOTIFICATION_PROCESS, function* () {
        const {
            sendingEntityId,
            pushMessageTitle: title,
            pushMessage: body,
            entityId,
            audienceEntityId,
            imageUrl,
            sendToSaves,
        } = payload;

        yield PostPromotionPush({
            entityId,
            sendingEntityId,
            title,
            body,
            imgUrl: imageUrl,
            audienceEntityId,
            sendToSaves,
        });

        yield put({
            type: RESET_PROMO_NOTIFICATIONS_START,
        });

        yield call(getPromoNotificationsSaga, { payload: audienceEntityId });
    });
}

function* getSystemNotificationsSaga() {
    yield genericSagaHandler(GET_SYSTEM_NOTIFICATIONS_PROCESS, function* () {
        const { page, systemNotifications } = yield select(
            ({ SystemNotificationsReducer }) => SystemNotificationsReducer,
        );
        const type = 'SYSTEM';
        const notifications = yield GetUserNotifications(page, type);
        const newNotifications = [...(systemNotifications ?? [])];
        notifications.forEach((notification) => {
            const isExisting = newNotifications.find(
                (newNotification) => newNotification?.id === notification?.id,
            );
            if (!isExisting) {
                newNotifications.push(notification);
            }
        });

        const hasNext = notifications?.length > 0 ? true : false;
        yield put({
            type: GET_SYSTEM_NOTIFICATIONS_SUCCESS,
            payload: { systemNotifications: newNotifications, hasNext },
        });
    });
}

function* setSystemNotificationsPageSaga() {
    yield genericSagaHandler(SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_PROCESS, function* () {
        const { page } = yield select(
            ({ SystemNotificationsReducer }) => SystemNotificationsReducer,
        );
        yield put({
            type: SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
            payload: { page: page + 1 },
        });
        yield call(getSystemNotificationsSaga);
    });
}

function* getPromoNotificationsSaga({ payload }) {
    yield genericSagaHandler(GET_PROMO_NOTIFICATIONS_PROCESS, function* () {
        const { notifications, page } = yield select(
            ({ PromotionNotificationsReducer }) => PromotionNotificationsReducer,
        );

        const data = yield GetPromotionNotifications(payload, page, 10);

        const newNotifications = [...(notifications ?? [])];
        data.forEach((notification) => {
            const isExisting = newNotifications.find(
                (newNotification) => newNotification?.id === notification?.id,
            );
            if (!isExisting) {
                newNotifications.push(notification);
            }
        });

        const hasNext = data?.length > 0 ? true : false;

        yield put({
            type: GET_PROMO_NOTIFICATIONS_SUCCESS,
            payload: { notifications: newNotifications, hasNext },
        });
    });
}

function* setPromoNotificationsPageSaga({ payload }) {
    yield genericSagaHandler(SET_PROMO_NOTIFICATIONS_NEXT_PAGE_PROCESS, function* () {
        const { page } = yield select(
            ({ PromotionNotificationsReducer }) => PromotionNotificationsReducer,
        );
        yield put({
            type: SET_PROMO_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
            payload: { page: page + 1 },
        });
        yield call(getPromoNotificationsSaga, { payload });
    });
}

export default function* PushSagas() {
    yield takeEvery(POST_SYSTEM_NOTIFICATION_START, postSystemNotificationSaga);
    yield takeEvery(POST_PROMOTION_NOTIFICATION_START, postPromotionNotificationSaga);
    yield takeEvery(GET_SYSTEM_NOTIFICATIONS_START, getSystemNotificationsSaga);
    yield takeEvery(SET_SYSTEM_NOTIFICATIONS_NEXT_PAGE_START, setSystemNotificationsPageSaga);
    yield takeEvery(GET_PROMO_NOTIFICATIONS_START, getPromoNotificationsSaga);
    yield takeEvery(SET_PROMO_NOTIFICATIONS_NEXT_PAGE_START, setPromoNotificationsPageSaga);
}
