import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import { ENTITY_TYPE_ICONS } from 'common/consts/consts';
import MyBusinessesListItemImageContainer from './MyBusinessesListItemImageContainer';

const MyBusinessesListItemImage = ({ image, type }) => {
    const theme = useContext(ThemeContext);

    return (
        <MyBusinessesListItemImageContainer>
            {image ? (
                <ProgressiveBg image={image} bgColor={theme.base.colors.light} />
            ) : (
                <GaIcon icon={ENTITY_TYPE_ICONS[type]} size={48} color={theme.base.colors.muted} />
            )}
        </MyBusinessesListItemImageContainer>
    );
};

MyBusinessesListItemImage.propTypes = {
    image: PropTypes.string,
    type: PropTypes.string.isRequired,
};

MyBusinessesListItemImage.defaultProps = {
    image: undefined,
};

export default MyBusinessesListItemImage;
