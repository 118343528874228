import { Mobile } from 'baseline-ui/layout';
import { BACKGROUND_MODES, VIDEO_IDS } from 'common/consts/consts';
import Background from 'baseline-ui/background';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import YoutubeVideo from 'components/YoutubeVideo';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import siteBaseTheme from 'styles/siteBaseTheme';

export default function MobileView() {
    return (
        <Mobile>
            <FlexWrapper
                background={siteBaseTheme.base.colors.transparent}
                xsGap={0}
                mdGap={0}
                margin={{
                    top: 0,
                    bottom: 0,
                }}
                padding={{
                    top: 0,
                    bottom: 0,
                }}
            >
                <FormattedMessage
                    id="businessScreen.videoPart.help.title"
                    values={{
                        text: (text) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={50}
                                fontSize={42}
                                fontWeight={800}
                                margin={{
                                    bottom: 23,
                                }}
                            >
                                {text}
                            </Label>
                        ),
                        orange: (content) => <Label skin="primary">{content}</Label>,
                    }}
                />

                <FormattedMessage
                    id="businessScreen.videoPart.help.subtitle"
                    values={{
                        text: (text) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={24}
                                fontSize={21}
                                fontWeight={600}
                                margin={{
                                    bottom: 29,
                                    left: 25,
                                    right: 25,
                                }}
                            >
                                {text}
                            </Label>
                        ),
                        orange: (content) => <Label skin="primary">{content}</Label>,
                        bold: (content) => <Label fontWeight={700}>{content}</Label>,
                        br: () => <br />,
                    }}
                />

                <Background mode={BACKGROUND_MODES.TRIANGLE_BOTTOM_CENTER}>
                    <FlexWrapper
                        background={siteBaseTheme.base.colors.transparent}
                        xsGap={0}
                        mdGap={0}
                        margin={{
                            top: 0,
                            right: 'auto',
                            left: 'auto',
                            bottom: 62,
                        }}
                        padding={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}
                        maxWidth={870}
                    >
                        <YoutubeVideo
                            videoId={VIDEO_IDS.BUILT_TO_HELP_BUSINESS_THRIVE}
                            showControls={true}
                            disableKeyboard={true}
                            fullScreenButton={false}
                            loop={false}
                        />
                    </FlexWrapper>
                </Background>
            </FlexWrapper>
        </Mobile>
    );
}
