import { Typography } from "@mui/material";
import React from "react";
import logo from "assets/images/logo.png";
import googlePlayStoreLogo from "assets/images/google-play-badge.png";
import appStoreLogo from "assets/images/apple-app-store-badge.svg";

import "./FavePage.css";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "common/consts/consts";
const FavePage = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 2,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#492656",
            color: "#FFF",
            fontFamily: "Roboto",
            padding: 50,
          }}
        >
          <div
            style={{
              flex: 0.7,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img width={"10%"} src={logo} alt="FavePage" style={{ flex: 1 }} />
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              flex: 1,
            }}
          >
            {!children && (
              <>
                <Typography variant="h5">
                  Looks like you haven't installed the FavePage App yet!
                </Typography>

                <Typography style={{ marginTop: 40 }} variant="h4">
                  Get the FavePage App now!
                </Typography>
              </>
            )}
            {children}
          </div>
        </div>

        <div
          style={{
            flex: 1,
            backgroundColor: "#F7F1EA",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a href={ANDROID_APP_LINK} target="_blank">
              <img src={googlePlayStoreLogo} style={{ height: 60 }} />
            </a>
            <a href={IOS_APP_LINK} target="_blank">
              <img src={appStoreLogo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavePage;
