import { Col, Container, Main, MainWrapper, Row } from 'baseline-ui/layout';
import { P } from 'baseline-ui/typography';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_BETA_SIGNUPS_START,
    PUT_BETA_SIGNUPS_START,
    SET_NEXT_PAGE_START,
    SET_PREV_PAGE_START,
} from 'stores/redux/actions/BetaSignupActions';
import CommonWrapper from '../../screens/CommonWrapper';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from 'baseline-ui/button';
import { Modal, ModalContext } from 'baseline-ui/modal';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { Form, Field, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

const BetaListItemContainer = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${rem(24)};

    padding: ${rem(24)};
`;

const BetaSignupReasonModal = ({ onRequestClose, onSubmit, ...props }) => {
    const [isReady, setIsReady] = React.useState(false);
    const intl = useIntl();

    return (
        <Modal
            title={intl.formatMessage({
                id: 'betasignups.modal.title',
            })}
            {...props}
            handleOnDismiss={() => onRequestClose()}
            onEntered={() => setIsReady(true)}
            onExited={() => setIsReady(false)}
        >
            <Formik
                initialValues={{ rejectreason: '' }}
                validationSchema={yup.object().shape({
                    rejectreason: yup.string(),
                })}
                validateOnChange
                validateOnBlur
                onSubmit={(values) => {
                    onSubmit(values.rejectreason);
                    onRequestClose();
                }}
            >
                {() => {
                    return (
                        <Form noValidate>
                            <FieldContainer>
                                <Field
                                    component={FormInput}
                                    label={intl.formatMessage({
                                        id: 'betasignups.modal.title',
                                    })}
                                    multiline
                                    name="rejectreason"
                                    id="rejectreason"
                                    type="text"
                                    disabled={!isReady}
                                    required
                                    showIsRequired={false}
                                />
                            </FieldContainer>
                            <Button
                                block
                                type="submit"
                                onClick={onSubmit}
                                // onClick={() => {
                                //     onSubmit(values.rejectreason);
                                // }}
                            >
                                <FormattedMessage id="betasignups.modal.button.label" />
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

const BetaSignups = ({ isMobile }) => {
    const dispatch = useDispatch();
    const { signups, hasNext, page } = useSelector(({ BetaSignupReducer }) => {
        return BetaSignupReducer;
    });
    const { showModal } = useContext(ModalContext);

    useEffect(() => {
        if (signups?.length === 0) {
            dispatch({
                type: GET_BETA_SIGNUPS_START,
            });
        }
    }, [signups]);

    return (
        <CommonWrapper>
            <Container>
                <Row flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                    <MainWrapper>
                        {signups?.map((signup) => {
                            return (
                                <BetaListItemContainer key={signup._id}>
                                    <Row>
                                        <Col>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.state.label" />
                                                    :{' '}
                                                </b>
                                                {signup.state}{' '}
                                                {signup.state === 'REJECTED' && signup.rejectreason
                                                    ? `(${signup.rejectreason})`
                                                    : ''}
                                            </P>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.fullName.label" />
                                                    :{' '}
                                                </b>
                                                {signup.fullName}
                                            </P>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.email.label" />
                                                    :{' '}
                                                </b>
                                                {signup.email}
                                            </P>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.phone.label" />
                                                    :{' '}
                                                </b>
                                                {signup.phone}
                                            </P>
                                        </Col>

                                        <Col>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.dob.label" />:{' '}
                                                </b>
                                                {signup.dob}
                                            </P>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.businessRef.label" />
                                                    :{' '}
                                                </b>
                                                {signup.referralBusiness || 'N/A'}
                                            </P>
                                            <P>
                                                <b>
                                                    <FormattedMessage id="betasignups.personRef.label" />
                                                    :{' '}
                                                </b>
                                                {signup.referralPerson || 'N/A'}
                                            </P>
                                        </Col>
                                    </Row>
                                    {signup.state === 'SUBMITTED' && (
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={() => {
                                                        dispatch({
                                                            type: PUT_BETA_SIGNUPS_START,
                                                            payload: {
                                                                state: 'APPROVED',
                                                                rejectreason: 'No more room sorry',
                                                                betalaunchid: signup._id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="betasignups.button.approve.label" />
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={() => {
                                                        showModal(BetaSignupReasonModal, {
                                                            onSubmit: (rejectreason) => {
                                                                dispatch({
                                                                    type: PUT_BETA_SIGNUPS_START,
                                                                    payload: {
                                                                        state: 'REJECTED',
                                                                        betalaunchid: signup._id,
                                                                        rejectreason,
                                                                    },
                                                                });
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <FormattedMessage id="betasignups.button.reject.label" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </BetaListItemContainer>
                            );
                        })}
                        {page > 0 && (
                            <Button onClick={() => dispatch({ type: SET_PREV_PAGE_START })}>
                                <FormattedMessage id="betasignups.button.prevPage" />
                            </Button>
                        )}
                        {hasNext && (
                            <Button onClick={() => dispatch({ type: SET_NEXT_PAGE_START })}>
                                <FormattedMessage id="betasignups.button.nextPage" />
                            </Button>
                        )}
                    </MainWrapper>
                </Row>
            </Container>
        </CommonWrapper>
    );
};

export default BetaSignups;
