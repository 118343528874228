import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Carousel } from 'baseline-ui/carousel';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { PHOTO_PREVIEW_CAROUSEL_HEIGHT } from 'common/consts/consts';
import FavePagePreviewPhotoPlaceholder from './FavePagePreviewPhotoPlaceholder';
import FavePagePreviewPhotoContainer from './FavePagePreviewPhotoContainer';

const FavePagePreviewPhotos = ({ photos, addToSection }) => {
    const settings = {
        arrows: false,
        dotsPosition: 'inside bottom',
        slidesToScroll: 1,
        slidesToShow: 1,
    };

    if (isEmpty(photos)) {
        return <FavePagePreviewPhotoPlaceholder addToSection={addToSection} />;
    }

    return (
        <Carousel {...settings}>
            {photos.map((photo, index) => {
                return (
                    <FavePagePreviewPhotoContainer key={`${photo?.url || photo?.preview}-${index}`}>
                        <ProgressiveBg
                            image={photo?.url || photo?.preview}
                            style={{
                                height: PHOTO_PREVIEW_CAROUSEL_HEIGHT,
                            }}
                        />
                    </FavePagePreviewPhotoContainer>
                );
            })}
        </Carousel>
    );
};

FavePagePreviewPhotos.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            order: PropTypes.number,
            originalFilename: PropTypes.string,
            state: PropTypes.string,
            url: PropTypes.string,
        }),
    ),
    addToSection: PropTypes.func,
};

FavePagePreviewPhotos.defaultProps = {
    photos: undefined,
    addToSection: () => {},
};

export default FavePagePreviewPhotos;
