import React from 'react';
import { Stepper, Step, StepButton, Box } from '@mui/material';
import { buttonTheme } from 'baseline-ui/button';
import useFavePageStepper from 'common/hooks/useFavePageStepper';

const FavePageStepper = ({ currentStep, onStepClick }) => {
    const { steps } = useFavePageStepper(currentStep);

    const stepStyles = (index, completed) => ({
        '& .MuiStepIcon-root': {
            color: (theme) =>
                index === currentStep
                    ? buttonTheme.button.secondary.background
                    : theme.palette.grey[400],
        },
        '& .MuiStepIcon-root.Mui-active': {
            color: buttonTheme.button.secondary.background,
        },
        '& .Mui-completed': {
            color: (theme) =>
                completed ? buttonTheme.button.secondary.background : theme.palette.grey[400],
        },
    });

    const stepButtonStyles = (isActive) => ({
        textDecoration: isActive ? 'underline' : 'none',
        textDecorationThickness: '2px',
        textUnderlineOffset: '4px',
        cursor: 'pointer',
    });

    return (
        <Box sx={{ width: '100%', mt: 3, mb: -1 }}>
            <Stepper activeStep={currentStep} alternativeLabel nonLinear>
                {steps.map((step, index) => (
                    <Step
                        key={step.id}
                        completed={step.completed}
                        sx={stepStyles(index, step.completed)}
                    >
                        <StepButton
                            sx={stepButtonStyles(index === currentStep)}
                            onClick={() => onStepClick(step.id, index)}
                        >
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default FavePageStepper;
