import SlideWrapper from './style-components/SlideWrapper';
import TitleWrapper from './style-components/TitleWrapper';
import {H3, H5} from 'baseline-ui/typography';
import FlexWrapper from '../../../../components/FlexWrapper';
import IconWrapper from './style-components/IconWrapper';
import {Image} from 'baseline-ui/helpers';
import {CONGRATS_IMG} from 'assets/images';
import {FormattedMessage} from 'react-intl';
import Label from 'components/Label';
import {Button} from 'baseline-ui/button';
import {SIGNUP_PATH} from 'common/consts/pathnames';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import ButtonsWrapper from './style-components/ButtonsWrapper';
import {
    RESET_DOWNLOAD_APP_FORM_DATA_START
} from 'stores/redux/actions/DownloadFavePageAppFormActions';
import {useDispatch} from 'react-redux';
import {PROCESS_RESET} from 'stores/redux/actions/CommonActions';

const CongratsCard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const completeProcess = 'POST_DOWNLOAD_APP_FORM_DATA';
    const handleHomeClick = () => {
        dispatch({
            type: RESET_DOWNLOAD_APP_FORM_DATA_START,
        });
        dispatch({
            type: `${completeProcess}_${PROCESS_RESET}`,
        });
    };
    const handleCreateClick = () => {
        dispatch({
            type: RESET_DOWNLOAD_APP_FORM_DATA_START,
        });
        dispatch({
            type: `${completeProcess}_${PROCESS_RESET}`,
        });
        navigate(SIGNUP_PATH);
    };
    return(
        <SlideWrapper>
            <TitleWrapper>
                <H3 fontWeight={700} color='secondary'>CONGRATULATIONS</H3>
            </TitleWrapper>
            <FlexWrapper width={80}>
                <IconWrapper>
                    <Image src={CONGRATS_IMG} width={140} height={100} imageFluid={false} />
                </IconWrapper>
                <TitleWrapper width={100}>
                    <H5 fontWeight={500} textAlign='center'>
                        <FormattedMessage
                            id="formTestApp.form.congrats.text"
                            values={{
                                orange: (text) => <Label skin='primary'>{text}</Label>,
                            }}
                        />
                    </H5>
                </TitleWrapper>
            </FlexWrapper>
            <ButtonsWrapper width={500}>
                <Button
                    block
                    btnType='outline'
                    onClick = {handleHomeClick}
                >
                    <FormattedMessage id='formTestApp.form.congrats.button.home.text' />
                </Button>
                <Button
                    block
                    onClick = {handleCreateClick}
                >
                    <FormattedMessage id='formTestApp.form.congrats.button.create.favepage.text' />
                </Button>
            </ButtonsWrapper>
        </SlideWrapper>
    );
};

export default CongratsCard;