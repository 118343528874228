import React from 'react';
import MobileView from './MobileView';
import TabletDesktopView from './TabletDesktopView';
import discountCheckIcon from 'assets/images/discount-check.svg';
import { useIntl } from 'react-intl';

const PreferSection = () => {
    const intl = useIntl();

    const tags = [
        {
            icon: discountCheckIcon,
            title: intl.formatMessage({
                id: 'businessScreen.videoPart.preferred.tags.launchPartiesAtYourBusiness',
            }),
        },
        {
            icon: discountCheckIcon,
            title: intl.formatMessage({
                id: 'businessScreen.videoPart.preferred.tags.freeMarketingDesigns',
            }),
        },
        {
            icon: discountCheckIcon,
            title: intl.formatMessage({ id: 'businessScreen.videoPart.preferred.tags.premiumLocation' }),
        },
        {
            icon: discountCheckIcon,
            title: intl.formatMessage({
                id: 'businessScreen.videoPart.preferred.tags.promotionsInTheApp',
            }),
        },
        {
            icon: discountCheckIcon,
            title: intl.formatMessage({ id: 'businessScreen.videoPart.preferred.tags.realtimeAnalytics' }),
        },
    ];

    return (
        <>
            <MobileView tags={tags} />
            <TabletDesktopView tags={tags} />
        </>
    );
};

export default PreferSection;
