import { useEffect, useState, useCallback } from 'react';
import { getEntityTopCategory, GetTier } from 'api';

const useEntityFilters = (entityId, preselectedTagIds = [], onFilterChange) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterHeaders, setFilterHeaders] = useState([]);
    const [subFilters, setSubFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        if (dataFetched || !entityId) {
            return;
        }

        const fetchData = async () => {
            try {
                setLoading(true);

                const entityType = entityId.split('/')[0];
                const [mappingConfig] = await getEntityTopCategory();

                if (!mappingConfig) {
                    throw new Error('Failed to fetch category mapping config');
                }

                const tagId = mappingConfig[entityType];
                if (!tagId) {
                    throw new Error(`No tag mapping found for entity type: ${entityType}`);
                }

                const [entityTypeTags] = await GetTier({
                    startingTagId: tagId,
                    direction: 'down',
                    depth: 0,
                    showHidden: false,
                });

                if (!entityTypeTags || !Array.isArray(entityTypeTags)) {
                    throw new Error('Failed to fetch entity type tags');
                }

                const headers = entityTypeTags.filter(
                    (filter) => filter.type === 'header' && filter.name,
                );
                setFilterHeaders(headers);

                const filtersByParent = entityTypeTags.filter(
                    (filter) => filter.type === 'filter' && filter.parent && filter.name,
                );

                const subFiltersObj = {};
                filtersByParent.forEach((filter) => {
                    const parentId = filter.parent._id;
                    if (!subFiltersObj[parentId]) {
                        subFiltersObj[parentId] = [];
                    }
                    subFiltersObj[parentId].push(filter);
                });

                setSubFilters(subFiltersObj);

                const preselectedTagIdMap = {};
                if (Array.isArray(preselectedTagIds)) {
                    preselectedTagIds.forEach((tagId) => {
                        preselectedTagIdMap[tagId] = true;
                    });
                }

                const initialSelectedFilters = {};
                Object.keys(subFiltersObj).forEach((headerId) => {
                    subFiltersObj[headerId].forEach((filter) => {
                        const isPreselected = preselectedTagIdMap[filter._id] || false;

                        initialSelectedFilters[filter._id] = {
                            id: filter._id,
                            name: filter.name,
                            selected: isPreselected,
                        };
                    });
                });

                setSelectedFilters(initialSelectedFilters);

                const selectedFilterIds = Object.values(initialSelectedFilters)
                    .filter((filter) => filter.selected)
                    .map((filter) => filter.id);

                if (onFilterChange && selectedFilterIds.length > 0) {
                    onFilterChange(selectedFilterIds);
                }

                setDataFetched(true);
            } catch (err) {
                setError(err.message || 'Failed to fetch filters');
            } finally {
                setLoading(false);
            }
        };

        if (entityId) {
            fetchData();
        }
    }, [entityId, preselectedTagIds, onFilterChange, dataFetched]);

    useEffect(() => {
        if (dataFetched && preselectedTagIds) {
            const preselectedTagIdMap = {};
            if (Array.isArray(preselectedTagIds)) {
                preselectedTagIds.forEach((tagId) => {
                    preselectedTagIdMap[tagId] = true;
                });
            }

            setSelectedFilters((prev) => {
                const updated = { ...prev };

                Object.keys(updated).forEach((filterId) => {
                    updated[filterId] = {
                        ...updated[filterId],
                        selected: preselectedTagIdMap[filterId] || false,
                    };
                });

                const selectedFilterIds = Object.values(updated)
                    .filter((filter) => filter.selected)
                    .map((filter) => filter.id);

                if (onFilterChange) {
                    onFilterChange(selectedFilterIds);
                }

                return updated;
            });
        }
    }, [preselectedTagIds, dataFetched, onFilterChange]);

    const handleFilterChange = useCallback(
        (filterId, checked) => {
            setSelectedFilters((prev) => {
                const updated = {
                    ...prev,
                    [filterId]: {
                        ...prev[filterId],
                        selected: checked,
                    },
                };

                if (onFilterChange) {
                    const selectedFilterIds = Object.values(updated)
                        .filter((filter) => filter.selected)
                        .map((filter) => filter.id);
                    onFilterChange(selectedFilterIds);
                }

                return updated;
            });
        },
        [onFilterChange],
    );

    return {
        loading,
        error,
        filterHeaders,
        subFilters,
        selectedFilters,
        handleFilterChange,
        getSelectedFilterItems: () =>
            Object.values(selectedFilters).filter((filter) => filter.selected),
    };
};

export default useEntityFilters;
