import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { ThemeContext } from 'styled-components';
import { GaIcon } from 'baseline-ui/icon';
import { ENTITY_TYPE_ICONS } from 'common/consts/consts';
import { EntityPickerImageContainer } from './components';

export const EntityPickerImage = ({ image, type }) => {
    const theme = useContext(ThemeContext);
    return (
        <EntityPickerImageContainer>
            {image ? (
                <ProgressiveBg image={image} bgColor={theme.base.colors.light} style={{ width: '100%', height: '100%' }}/>
            ) : (
                <GaIcon icon={ENTITY_TYPE_ICONS[type]} size={48} color={theme.base.colors.muted} />
            )}
        </EntityPickerImageContainer>
    );
};
EntityPickerImage.propTypes = {
    image: PropTypes.string,
    type: PropTypes.string.isRequired,
};
EntityPickerImage.defaultProps = {
    image: undefined,
};

