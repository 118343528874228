import { Box, Typography } from '@mui/material';
import { H3 } from 'baseline-ui/typography';
import {
    ENTITY_LINK_STATUS_APPROVED,
    ENTITY_LINK_STATUS_DENIED,
    ENTITY_LINK_STATUS_PENDING,
} from 'common/consts/entityLinkStatus';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
    DELETE_ENTITY_LINK_CALL,
    UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
} from 'stores/redux/actions/EntityLinkActions';
import EntityLinkRequestReceivedItem from './EntityLinkRequestReceivedItem';

const EntityLinkRequestReceivedList = ({ items, entityType }) => {
    const dispatch = useDispatch();

    const { pending, processed } = useMemo(() => {
        const pending = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_PENDING);
        const processed = items.filter(
            (item) => item.approvalstatus === ENTITY_LINK_STATUS_APPROVED,
        );
        return { pending, processed };
    }, [items]);

    const approveRequest = (id) => {
        dispatch({
            type: UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                entityRequestedType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_APPROVED,
            },
        });
    };

    const denyRequest = (id) => {
        dispatch({
            type: UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                entityRequestedType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_DENIED,
            },
        });
    };

    const handleDelete = (item) => {
        dispatch({
            type: DELETE_ENTITY_LINK_CALL,
            payload: {
                id: item._id,
                entityIdRequested: item.entityIdRequested,
                entityType,
            },
        });
    };

    return (
        <Box sx={{ p: 2, mt: 2 }}>
            <Box>
                <H3>
                    <FormattedMessage id="addBusiness.page.sections.alltypes.pending.title" />
                </H3>

                {pending.length > 0 &&
                    pending.map((item) => (
                        <EntityLinkRequestReceivedItem
                            key={item._id}
                            data={item}
                            onApprove={() => approveRequest(item._id)}
                            onDeny={() => denyRequest(item._id)}
                        />
                    ))}

                {pending.length === 0 && (
                    <Typography>
                        <FormattedMessage id="addBusiness.page.sections.alltypes.pending.empty" />
                    </Typography>
                )}
            </Box>
            <Box sx={{ mt: 4 }}>
                <H3>
                    <FormattedMessage id="addBusiness.page.sections.alltypes.processed.title" />
                </H3>

                {processed.length > 0 &&
                    processed.map((item) => (
                        <EntityLinkRequestReceivedItem
                            key={item._id}
                            data={item}
                            onDelete={() => handleDelete(item)}
                        />
                    ))}

                {processed.length === 0 && (
                    <Typography>
                        <FormattedMessage id="addBusiness.page.sections.alltypes.processed.empty" />
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default EntityLinkRequestReceivedList;
