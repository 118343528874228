export const POST_LOGIN_PROCESS = 'POST_LOGIN_PROCESS';
export const POST_LOGIN_START = 'POST_LOGIN_START';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';
export const TOKEN_LOGIN_PROCESS = 'TOKEN_LOGIN_PROCESS';

export const LOGOUT_PROCESS = 'LOGOUT_PROCESS';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_SESSION_PROCESS = 'GET_SESSION_PROCESS';
export const GET_SESSION_START = 'GET_SESSION_START';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';

export const POST_SIGNUP_PROCESS = 'POST_SIGNUP_PROCESS';
export const POST_SIGNUP_START = 'POST_SIGNUP_START';
export const POST_SIGNUP_SUCCESS = 'POST_SIGNUP_SUCCESS';

export const POST_BETA_SIGNUP_PROCESS = 'POST_BETA_SIGNUP_PROCESS';
export const POST_BETA_SIGNUP_PROCESS_RESET = 'POST_BETA_SIGNUP_PROCESS_RESET';
export const POST_BETA_SIGNUP_START = 'POST_BETA_SIGNUP_START';
export const POST_BETA_SIGNUP_SUCCESS = 'POST_BETA_SIGNUP_SUCCESS';
