import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/**
 * Combines a date and time into a single Day.js object.
 * @param {string} date - The date string in ISO format.
 * @param {string} time - The time string in HH:mm format.
 * @returns {Dayjs} - A Day.js object combining the date and time.
 */
export const combineDateAndTime = (date, time) => {
    const datePart = date.split('T')[0];
    return dayjs(`${datePart} ${time}`, 'YYYY-MM-DD HH:mm');
};

/**
 * Calculates the relative time from now to a specified date and time.
 * @param {string} startDate - The start date string in ISO format.
 * @param {string} startTime - The start time string in HH:mm format.
 * @returns {string} - A relative time string (e.g., "in 2 days", "3 hours ago").
 */
export const getRelativeTime = (startDate, startTime) => {
    const eventStart = combineDateAndTime(startDate, startTime);
    return dayjs().to(eventStart);
};

/**
 * Formats a date range into a readable string.
 * @param {string} startDate - The start date in ISO format.
 * @param {string} endDate - The end date in ISO format.
 * @returns {string} - A formatted date range (e.g., "Jan 10, 2025 - Jan 12, 2025").
 */
export const formatDateRange = (startDate, endDate) => 
    `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')}`;

/**
 * Formats a time range into a readable string.
 * @param {string} startTime - The start time in HH:mm format.
 * @param {string} endTime - The end time in HH:mm format.
 * @returns {string} - A formatted time range (e.g., "08:00 to 10:00").
 */
export const formatTimeRange = (startTime, endTime) => `${startTime} to ${endTime}`;
