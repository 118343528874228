import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    businessCategoriesSelector,
    businessFormSelector,
} from 'stores/redux/selectors/businessFormSelectors';
import InterestDetailsLayout from 'screens/Interests/_Layout/InterestDetailsLayout';
import { RiMovie2Line } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import {
    FeaturedCastSection,
    GenresSection,
    CastCrewTabContent,
    AwardsTabContent,
    MediaGalleryContent,
} from './components';
import { useMemo } from 'react';

const MovieDetails = () => {
    const { id } = useParams();

    const {
        name,
        approval_status,
        runtime,
        year,
        status,
        country,
        lang,
        image,
        description,
        score,
        characters,
        trailers,
        awards,
        genres,
    } = useSelector(businessFormSelector);

    const tags = useSelector(businessCategoriesSelector);

    const detailItems = [
        {
            label: 'Release Status',
            value: status?.name,
        },
        {
            label: 'Country',
            value: country?.toUpperCase(),
        },
        {
            label: 'Language',
            value: lang?.toUpperCase(),
        },
        {
            label: 'Release Year',
            value: year,
        },
        {
            label: 'Runtime',
            value: runtime ? `${runtime} minutes` : '',
            gridProps: { xs: 12 },
        },
    ];

    const scoreChip = (
        <Chip label={`Score: ${score?.toLocaleString()}`} color="primary" variant="outlined" />
    );

    const tabSections = useMemo(() => {
        const sections = [];

        if (trailers?.length > 0) {
            sections.push({
                label: 'Trailers',
                content: <MediaGalleryContent trailers={trailers} />,
            });
        }

        if (characters?.length > 0) {
            sections.push({
                label: 'Cast & Crew',
                content: <CastCrewTabContent characters={characters} />,
            });
        }

        if (awards?.length > 0) {
            sections.push({
                label: 'Awards',
                content: <AwardsTabContent awards={awards} />,
            });
        }

        return sections;
    }, [characters, trailers, awards]);

    const customSections = {
        aboveDescription: genres?.length > 0 ? <GenresSection genres={genres} /> : null,
        belowDetails:
            characters?.length > 0 ? <FeaturedCastSection characters={characters} /> : null,
    };

    return (
        <InterestDetailsLayout
            entityId={id}
            title={name}
            approvalStatus={approval_status}
            imageSrc={image}
            subtitle={`${year} • ${runtime} minutes`}
            description={description}
            detailItems={detailItems}
            headerExtras={scoreChip}
            icon={RiMovie2Line}
            tags={tags}
            customSections={customSections}
            tabSections={tabSections}
        />
    );
};

export default MovieDetails;
