import { LinkButton } from 'baseline-ui/button';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';
import { TabletDesktop } from 'baseline-ui/layout';
import { H2 } from 'baseline-ui/typography';
import { CONTACT_PATH } from 'common/consts/pathnames';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import YoutubeVideo from 'components/YoutubeVideo';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VIDEO_IDS } from 'common/consts/consts';
import BadgeList from 'components/BadgList';
import siteBaseTheme from 'styles/siteBaseTheme';

const TabletDesktopView = ({ tags = [] }) => {
    return (
        <TabletDesktop>
            <FlexWrapper
                background={siteBaseTheme.base.colors.transparent}
                margin={{
                    bottom: 127,
                    top: 0,
                }}
            >
                <FlexWrapper
                    xsGap={0}
                    mdGap={0}
                    borderRadius={40}
                    maxWidth={1225}
                    margin={{
                        bottom: 127,
                        top: 0,
                    }}
                    padding={{
                        bottom: 55,
                        top: 0,
                    }}
                >
                    <FormattedMessage
                        id="businessScreen.videoPart.preferred.title"
                        values={{
                            h2: (content) => <H2 textAlign="center">{content}</H2>,
                            text: (content) => (
                                <Label
                                    display="block"
                                    textAlign="center"
                                    skin="secondary"
                                    lineHeight={50}
                                    fontSize={42}
                                    fontWeight={700}
                                    margin={{
                                        bottom: 25,
                                    }}
                                >
                                    {content}
                                </Label>
                            ),
                            orange: (content) => <Label skin="primary">{content}</Label>,
                        }}
                    />

                    <FormattedMessage
                        id="businessScreen.videoPart.preferred.subtitle"
                        values={{
                            text: (content) => (
                                <Label
                                    display="block"
                                    textAlign="center"
                                    lineHeight={24}
                                    fontSize={20}
                                    fontWeight={600}
                                    margin={{
                                        bottom: 40,
                                    }}
                                >
                                    {content}
                                </Label>
                            ),
                            orange: (content) => <Label skin="primary">{content}</Label>,
                            br: () => <br />,
                        }}
                    />

                    <BadgeList
                        items={tags}
                        margin={{
                            bottom: 40,
                            left: 'auto',
                            right: 'auto',
                        }}
                        textAlign="center"
                        maxWidth={700}
                        badgeStyle={{
                            iconTitleGap: 5,
                            bgColor: siteBaseTheme.base.grays.grayFaint,
                            margin: {
                                bottom: 14,
                                left: 15,
                            },
                            padding: {
                                top: 12,
                                right: 17,
                                bottom: 12,
                                left: 17,
                            },
                            borderRadius: {
                                topRight: '100',
                                topLeft: '100',
                                bottomRight: '100',
                                bottomLeft: '100',
                            },
                            fontSize: 14,
                            lineHeight: 17,
                            fontWeight: 600,
                        }}
                    />

                    <FlexWrapper
                        background={siteBaseTheme.base.colors.transparent}
                        margin={{
                            top: 0,
                            right: 'auto',
                            left: 'auto',
                            bottom: 46,
                        }}
                        padding={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}
                        maxWidth={870}
                    >
                        <YoutubeVideo
                            videoId={VIDEO_IDS.BECOME_A_PREFERRED_FAVE_PAGE}
                            showControls={true}
                            disableKeyboard={true}
                            fullScreenButton={false}
                            loop={false}
                        />
                    </FlexWrapper>

                    <ButtonWrapper width={240}>
                        <LinkButton block size="lg" to={CONTACT_PATH}>
                            <FormattedMessage id="businessScreen.button.contactUS.label" />
                        </LinkButton>
                    </ButtonWrapper>
                </FlexWrapper>
            </FlexWrapper>
        </TabletDesktop>
    );
};

export default TabletDesktopView;
