import { useSelector } from "react-redux";
import { PROCESS_START } from "stores/redux/actions/CommonActions";

export const useLoading = (processName) => {
  const processState = useSelector(
    ({ ProcessReducer }) => ProcessReducer[processName],
  );

  if (processState === PROCESS_START) {
    return true;
  }
  return false;
};
