import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { businessCategoriesSelector } from 'stores/redux/selectors/businessFormSelectors';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';
import { Small } from 'baseline-ui/typography';
import { isEmpty } from 'lodash';
import { Button } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_CATEGORIES_FILTERS } from 'common/consts/addBusinessViewIds';
import FavePagePreviewCategoriesContainer from './FavePagePreviewCategoriesContainer';

const FavePagePreviewCategories = ({ addToSection }) => {
    const selectedCategories = useSelector(businessCategoriesSelector);
    const { hideModal } = useContext(ModalContext);

    const renderContent = useMemo(() => {
        if (isEmpty(selectedCategories)) {
            return (
                <Button
                    btnType="link"
                    size="sm"
                    icon="plus"
                    onClick={() => {
                        hideModal();
                        addToSection(ADD_BUSINESS_CATEGORIES_FILTERS);
                    }}
                >
                    Add Categories
                </Button>
            );
        }

        return (
            <Small textAlign="left" color="muted">
                {categoriesAndFiltersUtils.getCategoryNames(selectedCategories).join(' - ')}
            </Small>
        );
    }, [selectedCategories]);

    return <FavePagePreviewCategoriesContainer>{renderContent}</FavePagePreviewCategoriesContainer>;
};

FavePagePreviewCategories.propTypes = {
    addToSection: PropTypes.func,
};

FavePagePreviewCategories.defaultProps = {
    addToSection: () => {},
};

export default FavePagePreviewCategories;
