import React from 'react';
import formEventSchema from 'schema/formEventSchema';
import {
    EVENT_HOST_LABEL,
    EVENT_OTHER_LABEL,
    EVENT_PERFORMER_LABEL,
    EVENT_SPONSOR_LABEL
} from 'common/consts/consts';

const addEventUtils = {
    addEventHasAllRequiredFields: (requiredFields) => {
        return addEventUtils.requiredInformationIsValid(requiredFields);
    },
    addEventMissingRequiredFields: (requiredFields, intl) => {
        const validationResults = addEventUtils.requiredInformationValidate(requiredFields);

        const renderMissingFields = [];
        validationResults.forEach((listItem) => {
            renderMissingFields.push(
                intl.formatMessage({ id: `EVENT.form.validation.${listItem}.label` }),
            );
        });

        return renderMissingFields;
    },
    requiredInformationIsValid: (information) => {
        return formEventSchema.isValidSync(information);
    },

    requiredInformationValidate: (information) => {
        const errorsFieldList = [];
        try {
            formEventSchema.validateSync(information, {
                abortEarly: false,
            });
        } catch (error) {
            error.inner.forEach((innerItem) => {
                errorsFieldList.push(innerItem.path);
            });
        }

        return errorsFieldList;
    },
    resetEventForm: (setFieldValue) => {
        setFieldValue('entityId', '');
        setFieldValue('eventName', '');
        setFieldValue('startDate', null);
        setFieldValue('endDate', null);
        setFieldValue('startTime', null);
        setFieldValue('endTime', null);
        setFieldValue('description', '');
        setFieldValue('selectedEntities', []);
        setFieldValue('selectedCategories', [])
        setFieldValue('additionalPeople', []);
        setFieldValue('headlinePerson', []);
        setFieldValue('eventLinks', []);
        setFieldValue('url', '');
        setFieldValue('caption', '');
        setFieldValue('locationBusiness_selected', '');
        setFieldValue('locationType', 'CREATOR');
        setFieldValue('locationName', '');
        setFieldValue('address', '');
        setFieldValue('city', '');
        setFieldValue('state', '');
        setFieldValue('zipCode', '');
        setFieldValue('eventType', '');
        setFieldValue('customEventType', '');
        setFieldValue('category', '');
        setFieldValue('subcategory', '');
        setFieldValue('filter', {});
        setFieldValue('photos', []);
    },
    convertFormDataToObject: (formData) => {
        const object = {};
        formData.forEach((value, key) => {
            object[key] = value;
        });
        return object;
    },

    eventFormData: (eventData) => {
        const event = eventData.event;
        const tags = eventData.mapping.tags || [];
        const hosts = event.hosts.map((host) => host);
        const guests = event.guests.map((guest) => guest);
        const photos = event.photos.map((photo) => photo);
        const endTime = (event?.endTime || !isNaN(event.endTime) ) ? event.endTime : null;
        const formData = new FormData();
        if (event._id) {
            formData.append('entity_id', event._id);
        }
        formData.append('eventName', event.name);
        formData.append('startDate', event.startDate);
        formData.append('endDate', event?.endDate || null);
        formData.append('startTime', event.startTime);
        formData.append('endTime', endTime);
        formData.append('description', event.description);
        formData.append('locationType', event.locationType);
        formData.append('locationBusiness_selected', event.businessId);
        formData.append('formattedAddress', event.location.formattedAddress);
        formData.append('eventLinks',JSON.stringify(event.eventLinks));
        formData.append('headlinePerson', JSON.stringify(hosts));
        formData.append('additionalPeople', JSON.stringify(guests));
        formData.append('selectedCategories', JSON.stringify(tags));
        const eventTypes = [EVENT_PERFORMER_LABEL, EVENT_HOST_LABEL, EVENT_SPONSOR_LABEL];
        if(event.label === '' || event.label === undefined){
            formData.append('eventType', '');
        }
        else if (!eventTypes.includes(event.label)) {
            formData.append('eventType', EVENT_OTHER_LABEL);
            formData.append('customEventType', event.label);
        } else {
            formData.append('eventType', event.label);
            formData.append('customEventType', event.customEventType || '');
        }
        if(event.locationType === 'OTHER'){
            const fullAddress = event.location.formattedAddress.split(',').map(part => part.trim());
            const [address, city, state, zipcode] = fullAddress;
            formData.append('locationName', event.location.businessName);
            formData.append('address', address);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('zipCode', zipcode);
        }
        formData.append('photos', JSON.stringify(photos));

        return addEventUtils.convertFormDataToObject(formData);
    }
};

export default addEventUtils;
