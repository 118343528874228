import { PREVIEW_MODAL_HEADER_HEIGHT } from 'common/consts/previewConstants';
import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewModalHeaderContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.base.colors.dark};
    border-radius: ${rem(16)};
    display: flex;
    flex-direction: row;
    height: ${PREVIEW_MODAL_HEADER_HEIGHT}px;
    padding-left: ${rem(16)};
    padding-right: ${rem(8)};
    position: relative;
    width: 100%;
`;

export default FavePagePreviewModalHeaderContainer;
