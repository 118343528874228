import {useSelector} from 'react-redux';
import {eventFormRequiredFieldSelector} from 'stores/redux/selectors/eventSelectors';
import addEventUtils from '../utils/addEventUtils';

const useAddEventValid = () => {
    const eventFormRequiredFields = useSelector(eventFormRequiredFieldSelector);

    return addEventUtils.addEventHasAllRequiredFields(
        eventFormRequiredFields,
    );
};

export default useAddEventValid;
